import { combineReducers } from 'redux';
import * as actionTypes from '../actions/actionTypes';

/**
 * The state is formed by two parts, a "byId" part and a "allIds" part,
 * the first one is an object with the issue id as key and the issue itself
 * as value, and the allIds part just is an array with all the id of the issues
 * sorted by the order of entrance to the state, this will be taken into account
 * for prioritizing issues when not enough space
 */

/**
 * The issues reducer will manage all the state related to issues in the whole application,
 * except for derived data such as image blobs, issue history and comments that will be
 * handled by separate reducers, this different reducers will never reference or contain the
 * issue itself, they will hold only its id.
 *
 * The rule of thumb for if this reducer must be encharged of some state related to issues or
 * not is if that data is inside the issue object or its not, for instance, a browser-created
 * image blob is not in the issue object, so it should be handled separately, but a category is
 * actually inside the issue, so its this reducer task.
 *
 * The design is to update the state optimistically by reproducing what the server would do
 * in every START of an async action and later, update the state with the real one hold by
 * the server in case of success or revert the old state in case of failure
 *
 * This reducer also has a maximum issues functionality that removes issues from the
 * state according to a threshold in case of being above the issue limit
 */

export interface IssuesState {
  builderIssueId: string | null;
}

export const initialState: IssuesState = {
  builderIssueId: null,
};

const builderIssueId = (
  // biome-ignore lint/style/useDefaultParameterLast: <explanation>
  state = initialState.builderIssueId,
  action: any
): IssuesState['builderIssueId'] => {
  switch (action.type) {
    case actionTypes.BUILDER_ISSUE:
      return action.payload.issueId;
    default:
      return state;
  }
};

export const issuesReducer = combineReducers({
  builderIssueId,
});
