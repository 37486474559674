import { Listbox as HeadlessUIListBox, Popover as HeadlessUIPopover, Listbox, Transition } from '@headlessui/react';
import React, { Fragment, type PropsWithChildren, useEffect, useState } from 'react';
import { usePopper } from 'react-popper';

import { useMediaQuery } from '@shape-construction/hooks';
import { twMerge } from 'tailwind-merge';
import { breakpoints } from '../utils/breakpoints';
import { DefaultButton } from './components/ListBoxButton';
import { ListBoxGroup } from './components/ListBoxGroup';
import { ListBoxOption } from './components/ListBoxOption';

export type DefaultParams = {
  name: string;
  description?: string;
};

export interface ListBoxProps<T> {
  selected?: T;
  className?: string;
  onChange: (option: T) => void;
  renderButton?: (props: { selected?: T }) => JSX.Element;
  myRef?: React.Ref<HTMLDivElement>;
  by?: (keyof T & string) | ((a: T, z: T) => boolean);
}

function ListBox<T extends DefaultParams>({
  children,
  className,
  selected,
  onChange,
  renderButton = DefaultButton,
  myRef,
  by,
}: PropsWithChildren<ListBoxProps<T>>) {
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));
  const [buttonElement, setButtonElement] = useState<HTMLButtonElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(buttonElement, popperElement, {
    strategy: 'absolute',
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  useEffect(() => {
    if (popperElement && !isLargeScreen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'unset';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isLargeScreen, popperElement]);

  const content = (
    <HeadlessUIListBox.Options
      className={twMerge(
        'max-h-[25rem] w-full min-w-max overflow-auto rounded-t-md bg-white text-base shadow-sm ring-1 focus:outline-none sm:text-sm md:rounded-md',
        className
      )}
    >
      {children}
    </HeadlessUIListBox.Options>
  );

  return (
    <HeadlessUIPopover ref={myRef}>
      <HeadlessUIListBox by={by} value={selected} onChange={onChange}>
        <Listbox.Button ref={setButtonElement} as="div">
          {renderButton({ selected })}
        </Listbox.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-50"
          enterFrom="opacity-0 md:translate-y-1"
          enterTo="opacity-100 md:translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 md:translate-y-0"
          leaveTo="opacity-0 md:translate-y-1"
        >
          <HeadlessUIPopover.Panel className="fixed z-popover md:absolute">
            {/* Small screen */}
            <div role="presentation" aria-label="popover-panel-sm" className="block md:hidden">
              <div className="overflow-none fixed left-0 top-0 h-screen w-screen bg-gray-800 bg-opacity-75 backdrop-filter" />
              <div className="fixed left-0 bottom-0 w-full overflow-hidden rounded-t-lg bg-white shadow-lg">
                {content}
              </div>
            </div>
            {/* Large screen */}
            <div
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              role="presentation"
              aria-label="popover-panel-lg"
              className="hidden overflow-hidden rounded-lg bg-white shadow-lg md:block"
            >
              {content}
            </div>
          </HeadlessUIPopover.Panel>
        </Transition>
      </HeadlessUIListBox>
    </HeadlessUIPopover>
  );
}

ListBox.Button = DefaultButton;
ListBox.Group = ListBoxGroup;
ListBox.Option = ListBoxOption;

export { ListBox };
