import { showErrorToast } from '@shape-construction/arch-ui/src/Toast/toasts';
import { MEGABYTES_TO_BYTES } from 'app/components/Utils/FileInput/validation';
import { useCallback } from 'react';

export type FileValidationError = 'FILE_SIZE_MIN_ERROR' | 'FILE_SIZE_MAX_ERROR' | 'FILE_TYPE_INVALID_ERROR';

export interface FileValidationResult {
  file: File;
  isValid: boolean;
  errors: FileValidationError[];
}

export interface FileUploaderErrorMessages {
  fileSizeMin: (filename: string, min: number) => string;
  fileSizeMax: (filename: string, max: number) => string;
  fileTypeInvalid: (filename: string, allowedTypes: string[]) => string;
}

export interface UseFileUploadValidatorOptions {
  maxSizeInBytes?: number;
  minSizeInBytes?: number;
  allowedFileTypes?: string[];
  errorMessages: FileUploaderErrorMessages;
}

export const useFileUploadValidator = ({
  maxSizeInBytes = 10 * 1024 * 1024, // 10 MB default
  minSizeInBytes = 1,
  allowedFileTypes = [],
  errorMessages,
}: UseFileUploadValidatorOptions) => {
  const validateFile = useCallback(
    (file: File): FileValidationResult => {
      const errors: FileValidationError[] = [];

      // Size validations
      if (file.size < minSizeInBytes) {
        errors.push('FILE_SIZE_MIN_ERROR');
      }
      if (file.size > maxSizeInBytes) {
        errors.push('FILE_SIZE_MAX_ERROR');
      }

      // File type validation
      if (allowedFileTypes.length > 0 && !allowedFileTypes.includes(file.type)) {
        errors.push('FILE_TYPE_INVALID_ERROR');
      }

      return {
        file,
        isValid: errors.length === 0,
        errors,
      };
    },
    [maxSizeInBytes, minSizeInBytes, allowedFileTypes]
  );

  const validateFiles = useCallback(
    (files: File[]): FileValidationResult[] => {
      return files.map(validateFile);
    },
    [validateFile]
  );

  const handleValidationErrors = useCallback(
    (results: FileValidationResult[] | FileValidationResult) => {
      const fileResults = Array.isArray(results) ? results : [results];
      fileResults.forEach(({ file, isValid, errors }) => {
        if (!isValid) {
          errors.forEach((error) => {
            let message: string;

            switch (error) {
              case 'FILE_SIZE_MIN_ERROR':
                message = errorMessages.fileSizeMin(file.name, minSizeInBytes);
                break;
              case 'FILE_SIZE_MAX_ERROR':
                message = errorMessages.fileSizeMax(file.name, maxSizeInBytes / MEGABYTES_TO_BYTES);
                break;
              case 'FILE_TYPE_INVALID_ERROR':
                message = errorMessages.fileTypeInvalid(file.name, allowedFileTypes);
                break;
              default:
                message = `Unknown error occurred with file ${file.name}`;
            }

            showErrorToast({
              message,
              alignContent: 'start',
            });
          });
        }
      });
    },
    [errorMessages, maxSizeInBytes, minSizeInBytes, allowedFileTypes]
  );

  return {
    validateFiles,
    handleValidationErrors,
    validateFile,
  };
};
