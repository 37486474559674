import * as React from 'react';
import type { SVGProps } from 'react';
const WmaIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={44} viewBox="0 0 40 44" fill="none" aria-hidden="true" {...props}>
    <g id="file-icon/wma">
      <g id="Vector" filter="url(#filter0_dd_3816_38158)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6776 41C27.0141 41 13.7443 41 7.32669 41C5.85393 41 4.66667 39.82 4.66667 38.3473C4.66667 30.3468 4.66667 10.7752 4.66667 3.6498C4.66667 2.17704 5.86058 1 7.33334 1H26.2222Z"
          fill="#9333EA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6776 41C27.0141 41 13.7443 41 7.32669 41C5.85393 41 4.66667 39.82 4.66667 38.3473C4.66667 30.3468 4.66667 10.7752 4.66667 3.6498C4.66667 2.17704 5.86058 1 7.33334 1H26.2222Z"
          fill="url(#paint0_radial_3816_38158)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L26.2222 7.44444C26.2222 8.9172 27.4161 10.1111 28.8889 10.1111L35.3333 10.1111L26.2222 1Z"
          fill="white"
          fillOpacity={0.3}
        />
      </g>
      <path
        id="Vector_2"
        d="M26.3333 16.3332V26.7035C26.3333 27.0121 26.2284 27.2868 26.0185 27.5275C25.8086 27.7682 25.5432 27.955 25.2222 28.0877C24.9012 28.2204 24.5818 28.3191 24.2639 28.384C23.946 28.4488 23.6481 28.4812 23.3704 28.4812C23.0926 28.4812 22.7948 28.4488 22.4768 28.384C22.1589 28.3192 21.8395 28.2204 21.5185 28.0877C21.1975 27.9549 20.9321 27.7682 20.7222 27.5275C20.5123 27.2868 20.4074 27.0121 20.4074 26.7035C20.4074 26.3948 20.5123 26.1201 20.7222 25.8794C20.9321 25.6387 21.1975 25.452 21.5185 25.3193C21.8395 25.1865 22.1589 25.0877 22.4768 25.023C22.7948 24.9582 23.0926 24.9258 23.3704 24.9257C24.0185 24.9257 24.6111 25.0461 25.1481 25.2868V20.3147L18.0371 22.5091V29.0739C18.0371 29.3825 17.9322 29.6572 17.7223 29.8979C17.5124 30.1387 17.2469 30.3254 16.926 30.4581C16.605 30.5908 16.2855 30.6895 15.9676 30.7544C15.6497 30.8192 15.3519 30.8516 15.0741 30.8516C14.7963 30.8516 14.4985 30.8192 14.1806 30.7544C13.8627 30.6896 13.5432 30.5908 13.2222 30.4581C12.9013 30.3253 12.6358 30.1386 12.4259 29.8979C12.2161 29.6572 12.1111 29.3825 12.1111 29.0739C12.1111 28.7652 12.2161 28.4905 12.4259 28.2498C12.6358 28.0091 12.9013 27.8224 13.2222 27.6897C13.5432 27.5569 13.8627 27.4581 14.1806 27.3934C14.4985 27.3286 14.7963 27.2962 15.0741 27.2961C15.7222 27.2961 16.3148 27.4165 16.8518 27.6572V18.7037C16.8518 18.5123 16.9105 18.3379 17.0278 18.1805C17.145 18.0231 17.2963 17.9136 17.4815 17.8518L25.1851 15.4815C25.2591 15.4568 25.3456 15.4445 25.4443 15.4445C25.6912 15.4445 25.9011 15.5309 26.0739 15.7037C26.2468 15.8766 26.3332 16.0864 26.3332 16.3333L26.3333 16.3332Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_3816_38158"
        x={2.66667}
        y={0}
        width={34.6667}
        height={44}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3816_38158" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_3816_38158" result="effect2_dropShadow_3816_38158" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3816_38158" result="shape" />
      </filter>
      <radialGradient
        id="paint0_radial_3816_38158"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20 0.999999) rotate(90) scale(40 40.1825)"
      >
        <stop offset={0.297418} stopOpacity={0} />
        <stop offset={1} stopOpacity={0.08} />
      </radialGradient>
    </defs>
  </svg>
);
export default WmaIcon;
