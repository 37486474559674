import { useMessageGetter } from '@messageformat/react';
import { Button } from '@shape-construction/arch-ui';
import { ArrowUpCircleIcon } from '@shape-construction/arch-ui/src/Icons/solid';
import { useSidebar } from '@shape-construction/arch-ui/src/Sidebar';
import { now, parseDate } from '@shape-construction/utils/DateTime';
import { useQuery } from '@tanstack/react-query';
import { getProjectQueryOptions } from 'app/queries/projects/projects';
import { useTeamsSubscriptionPlan } from 'app/queries/teamsSubscriptionPlan/teamsSubscriptionPlan';
import React from 'react';
import { useMatch, useNavigate } from 'react-router-dom';

type AppSidebarUpgradePlanButtonProps = {
  activeTeamSubscriptionPlanSlug: string;
  trialDaysLeft: string;
  currentTeamUrl: string;
};

const AppSidebarUpgradePlanButton: React.FC<AppSidebarUpgradePlanButtonProps> = ({
  activeTeamSubscriptionPlanSlug,
  trialDaysLeft,
  currentTeamUrl,
}) => {
  const navigate = useNavigate();
  const { isMobile, open, toggleSidebar } = useSidebar();

  const showFullVersion = isMobile || open;
  const upgradePlanButtonMessages = useMessageGetter(`appSidebar.upgradePlanButton.${activeTeamSubscriptionPlanSlug}`);

  const redirectToSubscriptionPage = () => {
    if (isMobile) toggleSidebar(false);
    navigate(currentTeamUrl);
  };

  return (
    <>
      {showFullVersion ? (
        <button
          className="p-4 md:py-0"
          type="button"
          aria-label={upgradePlanButtonMessages('title')}
          onClick={redirectToSubscriptionPage}
        >
          <div className="bg-indigo-500 border-indigo-600 border rounded-md p-3 flex gap-x-3">
            <div>
              <ArrowUpCircleIcon className="text-white" width={24} />
            </div>
            <div className="flex flex-col gap-y-1">
              <div className="text-start text-sm leading-5 text-white font-medium">
                {upgradePlanButtonMessages('title')}
              </div>
              <div className="text-start text-xs leading-4 font-medium text-indigo-50">
                {upgradePlanButtonMessages('description', { trialDays: trialDaysLeft })}
              </div>
            </div>
          </div>
        </button>
      ) : (
        <div className="flex justify-center">
          <Button
            aria-label={upgradePlanButtonMessages('title')}
            size="md"
            color="primary"
            variant="contained"
            leadingIcon={ArrowUpCircleIcon}
            onClick={redirectToSubscriptionPage}
          />
        </div>
      )}
    </>
  );
};

const AppSidebarUpgradePlanButtonContainer: React.FC = () => {
  const pathParams = useMatch('/projects/:projectId/*');
  const projectId = pathParams?.params.projectId;
  const { data: project } = useQuery(getProjectQueryOptions(projectId!));
  const currentTeamUrl = `/projects/${projectId}/settings/teams/${project?.currentTeamId}/subscription`;
  const { data: teamSubscriptionData } = useTeamsSubscriptionPlan(projectId!, project?.currentTeamId!);
  const trialDaysLeft = parseDate(teamSubscriptionData?.trialEndAt || new Date())
    .diff(now(), 'days')
    .toString();

  const canEditTeamSubscription = teamSubscriptionData?.availableActions.edit || false;
  const activeTeamSubscriptionPlanSlug = teamSubscriptionData?.activePlanSlug;
  const inFreeOrTrialSubscriptionPlan =
    activeTeamSubscriptionPlanSlug === 'free' || activeTeamSubscriptionPlanSlug === 'trial';
  const canShowAppSidebarUpgradePlanButton = canEditTeamSubscription && inFreeOrTrialSubscriptionPlan;

  if (!canShowAppSidebarUpgradePlanButton) return null;

  return (
    <AppSidebarUpgradePlanButton
      activeTeamSubscriptionPlanSlug={activeTeamSubscriptionPlanSlug}
      trialDaysLeft={trialDaysLeft}
      currentTeamUrl={currentTeamUrl}
    />
  );
};

export { AppSidebarUpgradePlanButtonContainer as AppSidebarUpgradePlanButton };
