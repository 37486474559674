import React, { type HtmlHTMLAttributes, type PropsWithChildren, type ReactElement } from 'react';
import { twMerge } from 'tailwind-merge';
import { Tab } from './components/Tab';

export interface TabsProps extends Omit<HtmlHTMLAttributes<HTMLDivElement>, 'onChange'> {
  /**
   * React component to render inside Menu Item
   */
  children: React.ReactNode;
  /**
   * Classes to apply to nav element
   */
  className?: string;
  /**
   * Event handler to run when a tab is changed.
   */
  onChange?: (event: React.ChangeEvent, value: any) => void;
  /**
   * Value of the tab to be selected by default.
   */
  selectedValue?: any;
}

export const Tabs = ({ children, className, onChange, selectedValue }: PropsWithChildren<TabsProps>) => (
  <div className="overflow-x-auto overflow-y-hidden">
    <nav className={twMerge('flex flex-1 border-b border-gray-200', className)}>
      <div className="-mb-px flex space-x-5 px-4 md:px-8" aria-label="Tabs" role="tablist">
        {React.Children.map(children, (child, childIndex) => {
          if (!React.isValidElement(child)) return child;

          const childValue = child.props.value === undefined ? childIndex : child.props.value;

          return React.cloneElement(child as ReactElement, {
            value: childValue,
            selected: child.props.selected || childValue === selectedValue,
            onChange,
          });
        })}
      </div>
    </nav>
  </div>
);

Tabs.Tab = Tab;
