import React from 'react';

interface NotificationSkeletonProps {
  length: number;
}

const NotificationSkeleton: React.FC<NotificationSkeletonProps> = ({ length = 1 }) => (
  <div className="flex flex-col divide-y" data-testid="notification-skeleton">
    {Array.from(Array(length).keys()).map((id) => (
      <div key={id} role="alert" aria-busy="true" className="h-20 w-full animate-pulse bg-gray-300" />
    ))}
  </div>
);

export { NotificationSkeleton };
