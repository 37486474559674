import { useMessageGetter } from '@messageformat/react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { getProjectCustomFieldsQueryOptions } from 'app/queries/projects/custom.fields';
import { getProjectLocationsQueryOptions } from 'app/queries/projects/locations';
import { getProjectPeopleQueryOptions } from 'app/queries/projects/people';
import { getProjectQueryOptions } from 'app/queries/projects/projects';
import { getProjectTeamsQueryOptions } from 'app/queries/projects/teams';

export type SetupKey =
  | 'created_project'
  | 'created_locations'
  | 'setup_team'
  | 'invited_team_member'
  | 'invited_team'
  | 'created_custom_fields';

export type SetupOption = {
  label: string;
  href: string;
  completed: boolean;
};

export const useProjectSetupProgress = (projectId: string) => {
  const messageGetter = useMessageGetter('projectSetup');
  const { data: project } = useSuspenseQuery(getProjectQueryOptions(projectId));
  const { data: projectLocations } = useSuspenseQuery(getProjectLocationsQueryOptions(projectId));
  const { data: projectTeams } = useSuspenseQuery(getProjectTeamsQueryOptions(projectId));
  const { data: customFields } = useSuspenseQuery(getProjectCustomFieldsQueryOptions(projectId));
  const { data: teamMembers } = useSuspenseQuery(
    getProjectPeopleQueryOptions(projectId, { team_id: project.currentTeamId })
  );

  const hasLocations = projectLocations && projectLocations.length > 1;
  const hasMoreThanOneTeam = projectTeams && projectTeams.length > 1;
  const hasDefaultTeamSettedUp = projectTeams?.[0]?.displayName;
  const hasMoreThanOneTeamMembers = teamMembers && teamMembers.length > 1;
  const hasCustomFields = customFields && customFields.customFields.length > 0;

  const options: Record<SetupKey, SetupOption> = {
    created_project: {
      label: messageGetter('newProject'),
      href: '/my-projects/new',
      completed: true,
    },
    created_locations: {
      label: messageGetter('createLocation'),
      href: `/projects/${projectId}/settings/locations`,
      completed: Boolean(hasLocations),
    },
    setup_team: {
      label: 'Setup team',
      href: `/projects/${projectId}/settings/teams/${project?.currentTeamId}`,
      completed: Boolean(hasDefaultTeamSettedUp),
    },
    invited_team_member: {
      label: messageGetter('inviteTeamMember'),
      href: `/projects/${projectId}/settings/teams/${project?.currentTeamId}`,
      completed: Boolean(hasMoreThanOneTeamMembers),
    },
    invited_team: {
      label: messageGetter('inviteTeam'),
      href: `/projects/${projectId}/settings/teams/invite`,
      completed: Boolean(hasMoreThanOneTeam),
    },
    created_custom_fields: {
      label: messageGetter('createCustomField'),
      href: `/projects/${projectId}/settings/custom-fields/new`,
      completed: Boolean(hasCustomFields),
    },
  };

  const optionsArray = Object.values(options);
  const completedPercentage = Math.round(
    (optionsArray.filter(({ completed }) => completed).length / optionsArray.length) * 100
  );

  return {
    completedPercentage,
    options,
  };
};
