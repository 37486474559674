import * as Dialog from '@radix-ui/react-dialog';
import React from 'react';
import NotificationBadge from '../NotificationBadge';
import * as Tooltip from '../Tooltip';
import { cn } from '../utils/classes';
import { useSidebar } from './Sidebar.context';

const SIDEBAR_WIDTH_MOBILE = '320px';

export const SidebarContent = ({ className, ...props }: React.ComponentProps<'div'>) => (
  <div
    data-sidebar="content"
    className={cn(
      'flex min-h-0 flex-1 flex-col gap-2 overflow-auto group-data-[collapsible=icon]:overflow-x-hidden',
      className
    )}
    {...props}
  />
);

export const SidebarFooter = ({ className, ...props }: React.ComponentProps<'div'>) => (
  <div data-sidebar="footer" className={cn('flex flex-col gap-2', className)} {...props} />
);

export const SidebarHeader = ({ className, ...props }: React.ComponentProps<'div'>) => (
  <div data-sidebar="header" className={cn('flex flex-col gap-2', className)} {...props} />
);

export const SidebarIcon = ({
  badge,
  icon: Icon,
  className,
  ...props
}: Omit<React.ComponentProps<'div'>, 'children'> & {
  badge?: boolean | string;
  icon: React.ReactNode;
}) => {
  const { open } = useSidebar();

  return (
    <div
      data-sidebar="icon"
      className={cn(
        'h-9 grid items-center justify-center shrink-0 text-neutral-subtlest group-data-[active=true]:text-neutral',
        { 'w-full': !open, 'w-14': open },
        className
      )}
      {...props}
    >
      <NotificationBadge
        className="bg-danger text-white"
        active={!!badge}
        text={typeof badge === 'string' ? badge : undefined}
      >
        {Icon}
      </NotificationBadge>
    </div>
  );
};

export const SidebarItem = ({
  children,
  className,
  active,
  disabled,
  tooltip,
  ...props
}: React.ComponentProps<'button'> & {
  active?: boolean;
  tooltip?: React.ReactNode;
}) => {
  const { open } = useSidebar();

  return (
    <li data-sidebar="item" className="list-none truncate">
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <button
            {...props}
            data-active={active}
            disabled={disabled}
            className={cn(
              'group w-full h-10 rounded flex flex-nowrap items-center gap-2 my-px font-medium text-left text-sm',
              'hover:bg-neutral-subtlest-hovered',
              { 'bg-neutral-subtlest-pressed': active, 'opacity-40 hover:bg-transparent': disabled },
              className
            )}
          >
            {children}
          </button>
        </Tooltip.Trigger>
        <Tooltip.Content side="right" hidden={open || !tooltip}>
          {tooltip}
        </Tooltip.Content>
      </Tooltip.Root>
    </li>
  );
};

export const SidebarSection = ({
  children,
  className,
  label,
  ...props
}: React.ComponentProps<'div'> & {
  label?: React.ReactNode;
}) => {
  const { open } = useSidebar();

  return (
    <div data-sidebar="section" className={cn('relative flex w-full min-w-0 flex-col p-2', className)} {...props}>
      {open ? (
        label && (
          <div className="my-2 text-xs leading-4 font-semibold tracking-wider uppercase text-neutral">{label}</div>
        )
      ) : (
        <>
          <div className="w-10 m-auto border-b" />
          <div className="h-4 w-10 m-auto" />
        </>
      )}
      {children}
    </div>
  );
};

export type SidebarRootProps = React.ComponentProps<'div'> & {
  side?: 'left' | 'right';
  variant?: 'sidebar' | 'floating' | 'inset';
  collapsible?: 'offcanvas' | 'icon' | 'none';
};
export const SidebarRoot = ({
  side = 'left',
  variant = 'sidebar',
  collapsible = 'icon',
  className,
  children,
  ...props
}: SidebarRootProps) => {
  const { isMobile, open, toggleSidebar } = useSidebar();

  if (collapsible === 'none') {
    return (
      <div
        className={cn('flex h-full w-[--sidebar-width] flex-col bg-white text-sidebar-foreground', className)}
        {...props}
      >
        {children}
      </div>
    );
  }

  if (isMobile)
    return (
      <Dialog.Root open={open} onOpenChange={(value) => toggleSidebar(value)}>
        <Dialog.Trigger />
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 z-50 bg-black/80  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
          <Dialog.Content
            data-sidebar="sidebar"
            data-mobile="true"
            className={cn(
              'w-[--sidebar-width] bg-white p-0 [&>button]:hidden',
              'fixed z-50 gap-4 shadow-lg transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-500',
              'inset-y-0 left-0 h-full border-r data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left sm:max-w-sm'
            )}
            style={
              {
                '--sidebar-width': SIDEBAR_WIDTH_MOBILE,
              } as React.CSSProperties
            }
          >
            <Dialog.Title />
            <div className="flex h-full w-full flex-col" {...props}>
              {children}
            </div>
            <Dialog.Description />
            <Dialog.Close />
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    );

  return (
    <div
      className="group peer hidden md:block"
      data-state={open ? 'expanded' : 'collapsed'}
      data-collapsible={open ? '' : collapsible}
      data-variant={variant}
      data-side={side}
      data-testid="sidebar"
    >
      <div
        className={cn(
          'duration-200 relative h-full w-[--sidebar-width] bg-transparent transition-[width] ease-linear',
          'group-data-[collapsible=offcanvas]:w-0',
          'group-data-[side=right]:rotate-180',
          {
            'absolute right-0 group-data-[collapsible=offcanvas]:right-[calc(var(--sidebar-width)*-1)]':
              side === 'right',
            'group-data-[collapsible=icon]:w-[calc(var(--sidebar-width-icon)_+_theme(spacing.4))]':
              variant === 'floating' || variant === 'inset',
            'group-data-[collapsible=icon]:w-[--sidebar-width-icon]': variant === 'sidebar',
          }
        )}
      />
      <div
        className={cn(
          'duration-200 absolute inset-y-0 z-10 hidden w-[--sidebar-width] transition-[left,right,width] ease-linear md:flex',
          {
            'left-0  group-data-[collapsible=offcanvas]:left-[calc(var(--sidebar-width)*-1)]': side === 'left',
            'right-0 group-data-[collapsible=offcanvas]:right-[calc(var(--sidebar-width)*-1)]': side === 'right',
            'p-2 group-data-[collapsible=icon]:w-[calc(var(--sidebar-width-icon)_+_theme(spacing.4)_+2px)]':
              variant === 'floating' || variant === 'inset',
            'group-data-[collapsible=icon]:w-[--sidebar-width-icon] group-data-[side=left]:border-r group-data-[side=right]:border-l':
              variant === 'sidebar',
          }
        )}
        {...props}
      >
        <div
          data-sidebar="sidebar"
          className={cn(
            'flex h-full w-full flex-col bg-white group-data-[variant=floating]:rounded-lg group-data-[variant=floating]:border group-data-[variant=floating]:shadow',
            className
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
SidebarRoot.displayName = 'Sidebar.Root';
