import React, { type FC, type ComponentProps } from 'react';

export const DragIcon: FC<ComponentProps<'svg'>> = (props) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.3 11.2C6.3 11.97 5.67 12.6 4.9 12.6C4.13 12.6 3.5 11.97 3.5 11.2C3.5 10.43 4.13 9.80002 4.9 9.80002C5.67 9.80002 6.3 10.43 6.3 11.2ZM4.9 5.60002C4.13 5.60002 3.5 6.23002 3.5 7.00002C3.5 7.77002 4.13 8.40002 4.9 8.40002C5.67 8.40002 6.3 7.77002 6.3 7.00002C6.3 6.23002 5.67 5.60002 4.9 5.60002ZM4.9 1.40002C4.13 1.40002 3.5 2.03002 3.5 2.80002C3.5 3.57002 4.13 4.20002 4.9 4.20002C5.67 4.20002 6.3 3.57002 6.3 2.80002C6.3 2.03002 5.67 1.40002 4.9 1.40002ZM9.1 4.20002C9.87 4.20002 10.5 3.57002 10.5 2.80002C10.5 2.03002 9.87 1.40002 9.1 1.40002C8.33 1.40002 7.7 2.03002 7.7 2.80002C7.7 3.57002 8.33 4.20002 9.1 4.20002ZM9.1 5.60002C8.33 5.60002 7.7 6.23002 7.7 7.00002C7.7 7.77002 8.33 8.40002 9.1 8.40002C9.87 8.40002 10.5 7.77002 10.5 7.00002C10.5 6.23002 9.87 5.60002 9.1 5.60002ZM9.1 9.80002C8.33 9.80002 7.7 10.43 7.7 11.2C7.7 11.97 8.33 12.6 9.1 12.6C9.87 12.6 10.5 11.97 10.5 11.2C10.5 10.43 9.87 9.80002 9.1 9.80002Z"
      fill="currentColor"
    />
  </svg>
);
