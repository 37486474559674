import React, { type ComponentProps } from 'react';
import { DragIcon } from '../Icons/solid';
import { cn } from '../utils/classes';
import { useDraggableItem } from './context';

export type DraggableListHandleProps = Omit<ComponentProps<'button'>, 'ref'>;

export const DraggableListHandle = React.forwardRef<HTMLButtonElement, DraggableListHandleProps>(
  ({ className, ...props }, ref) => {
    const {
      id,
      sortable: { listeners, attributes },
    } = useDraggableItem();

    return (
      <button
        ref={ref}
        {...listeners}
        {...attributes}
        className={cn('touch-none cursor-move', className)}
        aria-labelledby={`draggable-item-${id}`}
        {...props}
      >
        <DragIcon className="w-4 h-4 text-neutral-subtle" />
      </button>
    );
  }
);

DraggableListHandle.displayName = 'DraggableList.Handle';
