import { useMessageGetter } from '@messageformat/react';
import React from 'react';

export const AppVersion = () => {
  const appVersion = process.env.REACT_APP_VERSION;
  const messages = useMessageGetter('error');

  if (!appVersion) return null;

  return (
    <p className="text-center text-xs font-normal leading-5 text-gray-400">
      {messages('version', { version: appVersion })}
    </p>
  );
};
