import { Listbox, type ListboxProps } from '@headlessui/react';
import classNames from 'clsx';
import React, { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { type PrimitiveProps, renderChildren } from '../utils/render';
import { SelectProvider } from './SelectContext';

type OwnProps = {
  onClose?: () => void;
};

export type SelectRootProps<TType, TActualType> = PrimitiveProps<
  Omit<ListboxProps<'div', TType, TActualType>, 'as' | 'className'> & OwnProps
>;

export function SelectRootBase<TType, TActualType>(
  { onClose, children, className, ...props }: SelectRootProps<TType, TActualType>,
  ref: React.Ref<React.ElementRef<typeof Listbox>>
) {
  return (
    <Listbox ref={ref} as="div" className={twMerge(classNames('relative', className as string))} {...props}>
      {(state) => (
        <SelectProvider onClose={onClose} open={state.open} multiple={props.multiple}>
          {renderChildren(children, state)}
        </SelectProvider>
      )}
    </Listbox>
  );
}

export const SelectRoot = forwardRef(SelectRootBase) as <TType, TActualType>(
  props: SelectRootProps<TType, TActualType> & { ref?: React.Ref<React.ElementRef<'div'>> }
) => ReturnType<typeof SelectRootBase>;
