import { HelpCenter } from './api/help-center';
import type { Configuration } from './base-api';

export class ZenDesk {
  public helpcenter: HelpCenter;

  constructor(config: Configuration) {
    this.helpcenter = new HelpCenter(config);
  }
}
