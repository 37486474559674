import { IconButton } from '@shape-construction/arch-ui';
import type { IconButtonProps } from '@shape-construction/arch-ui/src/Button/IconButton/IconButton';
import { ArrowPathIcon } from '@shape-construction/arch-ui/src/Icons/solid';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export interface ReloadButtonProps {
  color?: IconButtonProps['color'];
}

export const ReloadButton: React.FC<ReloadButtonProps> = ({ color = 'white' }: ReloadButtonProps) => {
  const navigate = useNavigate();

  const reloadHandler = (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    event.preventDefault();
    navigate(0);
  };

  return (
    <IconButton
      color={color}
      variant="text"
      aria-label="reload button"
      onClick={(event) => reloadHandler(event)}
      icon={ArrowPathIcon}
      size="xs"
    />
  );
};
