import * as React from 'react';
import type { SVGProps } from 'react';
const IfcIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={44} viewBox="0 0 40 44" fill="none" aria-hidden="true" {...props}>
    <g id="file-icon/ifc">
      <g id="Vector" filter="url(#filter0_dd_3816_38060)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6777 41C27.0141 41 13.7443 41 7.3267 41C5.85395 41 4.66669 39.82 4.66669 38.3473C4.66669 30.3468 4.66669 10.7752 4.66669 3.6498C4.66669 2.17704 5.86059 1 7.33335 1H26.2222Z"
          fill="#14B8A6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6777 41C27.0141 41 13.7443 41 7.3267 41C5.85395 41 4.66669 39.82 4.66669 38.3473C4.66669 30.3468 4.66669 10.7752 4.66669 3.6498C4.66669 2.17704 5.86059 1 7.33335 1H26.2222Z"
          fill="url(#paint0_radial_3816_38060)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L26.2222 7.44444C26.2222 8.9172 27.4161 10.1111 28.8889 10.1111L35.3333 10.1111L26.2222 1Z"
          fill="white"
          fillOpacity={0.3}
        />
      </g>
      <g id="Vector_2">
        <path
          d="M21.7697 24.3889L26.1824 19.9783L22.5332 16.3302L21.3906 17.4791L19.942 16.0336C19.9577 16.0158 19.988 15.9824 20.0203 15.9511C20.4597 15.5103 20.8997 15.0699 21.3405 14.6299C21.4958 14.4668 21.6825 14.3367 21.8894 14.2475C22.0962 14.1584 22.319 14.112 22.5442 14.1111C22.7695 14.1102 22.9926 14.1549 23.2002 14.2424C23.4077 14.33 23.5954 14.4586 23.752 14.6205C25.1446 16.0054 26.5333 17.3934 27.9182 18.7846C28.0779 18.9403 28.2047 19.1266 28.291 19.3324C28.3773 19.5381 28.4213 19.7591 28.4206 19.9822C28.4198 20.2053 28.3742 20.426 28.2864 20.6311C28.1987 20.8362 28.0706 21.0216 27.9098 21.1763L24.4267 24.6594C24.2712 24.8177 24.0856 24.9435 23.8809 25.0294C23.6762 25.1153 23.4565 25.1595 23.2345 25.1595C23.0125 25.1595 22.7928 25.1153 22.5881 25.0294C22.3834 24.9435 22.1979 24.8177 22.0423 24.6594C22.018 24.6353 21.9935 24.6114 21.9685 24.587C21.9066 24.5265 21.8419 24.4633 21.7697 24.3889Z"
          fill="white"
        />
        <path
          d="M13.5484 20.2318L17.2038 16.5764L21.6144 20.9859C21.6399 20.9615 21.6651 20.9377 21.6899 20.9144C21.7745 20.8347 21.8542 20.7596 21.9277 20.6788C22.2286 20.3614 22.3921 19.9379 22.3825 19.5006C22.3729 19.0633 22.1911 18.6474 21.8765 18.3435L18.4018 14.8698C18.2465 14.7078 18.06 14.5789 17.8536 14.4908C17.6472 14.4027 17.4251 14.3573 17.2007 14.3573C16.9763 14.3573 16.7542 14.4027 16.5478 14.4908C16.3414 14.5789 16.1549 14.7078 15.9996 14.8698L11.8523 19.0161C11.6888 19.1711 11.5586 19.3577 11.4694 19.5646C11.3802 19.7714 11.3339 19.9942 11.3333 20.2195C11.3327 20.4447 11.3779 20.6678 11.466 20.8751C11.5541 21.0824 11.6834 21.2697 11.846 21.4256L13.1839 22.7634C13.2065 22.7852 13.2297 22.8057 13.2482 22.8221C13.2536 22.8269 13.2588 22.8315 13.2633 22.8355L14.7108 21.388L13.5484 20.2318Z"
          fill="white"
        />
        <path
          d="M13.8027 25.5676L18.2299 21.1424C18.175 21.0917 18.1205 21.0447 18.068 20.9996C18.0287 20.9657 17.9905 20.9328 17.9542 20.9001C17.2586 20.2411 16.2487 20.2233 15.5876 20.8782C14.4032 22.0521 13.2244 23.2309 12.0512 24.4145C11.7426 24.7278 11.571 25.1507 11.5742 25.5904C11.5773 26.03 11.7549 26.4505 12.0679 26.7592L16.2602 30.9505C16.4146 31.1069 16.5986 31.2312 16.8015 31.316C17.0044 31.4008 17.222 31.4444 17.4419 31.4444C17.6618 31.4444 17.8795 31.4008 18.0823 31.316C18.2852 31.2312 18.4692 31.1069 18.6237 30.9505L19.9814 29.5927C20.0018 29.5724 20.0216 29.5504 20.0361 29.5342C20.0417 29.528 20.0466 29.5226 20.0503 29.5186L18.5986 28.0679L17.4581 29.223L13.8027 25.5676Z"
          fill="white"
        />
        <path
          d="M26.7639 22.7734L26.7457 22.7571C26.7372 22.7494 26.7287 22.7417 26.7201 22.7338C26.6987 22.749 26.6782 22.7654 26.6585 22.7828C26.5297 22.9113 26.4011 23.0399 26.2725 23.1685C25.9726 23.4683 25.673 23.7679 25.3718 24.0654C25.3039 24.1322 25.3238 24.173 25.3812 24.2293C25.6959 24.542 26.0092 24.8553 26.3212 25.1693C26.3386 25.1873 26.3543 25.2031 26.3683 25.2172C26.4291 25.2784 26.4578 25.3074 26.4567 25.3351C26.4556 25.36 26.4305 25.3839 26.3828 25.4292C26.3653 25.4459 26.3447 25.4654 26.3212 25.4889C25.189 26.6197 24.0569 27.7501 22.9248 28.8801C22.8641 28.9397 22.8338 28.9695 22.8036 28.9694C22.7735 28.9693 22.7435 28.9396 22.6835 28.8801C21.2861 27.4876 19.8904 26.0926 18.4965 24.6952C18.4653 24.6555 18.4393 24.6119 18.4192 24.5656L18.3565 24.5771C18.3292 24.6078 18.3022 24.6388 18.2753 24.6698C18.218 24.7358 18.1607 24.8018 18.0996 24.8644C17.8005 25.1691 17.6316 25.5782 17.6285 26.0051C17.6254 26.4321 17.7883 26.8435 18.0829 27.1527C19.2693 28.3502 20.4593 29.5409 21.6527 30.7245C21.9428 31.0151 22.3355 31.1801 22.7462 31.1841C22.9802 31.1912 23.213 31.1483 23.4292 31.0582C23.6453 30.9682 23.8397 30.833 23.9995 30.6619C24.5718 30.0881 25.1448 29.5151 25.7186 28.9428C26.5304 28.1351 27.3384 27.3257 28.1426 26.5145C28.3019 26.3612 28.4287 26.1774 28.5152 25.974C28.6018 25.7705 28.6464 25.5518 28.6464 25.3307C28.6464 25.1096 28.6018 24.8908 28.5152 24.6874C28.4287 24.484 28.3019 24.3001 28.1426 24.1468C27.7089 23.7117 27.2747 23.2775 26.8402 22.8445C26.816 22.8202 26.7904 22.7972 26.7641 22.7736L26.7639 22.7734Z"
          fill="white"
        />
        <path
          d="M18.1014 17.8543L21.4143 21.1661L19.9824 22.598C19.9764 22.5924 19.969 22.5856 19.9606 22.5779C19.9426 22.5615 19.9202 22.5411 19.8988 22.5197L17.879 20.4998C17.7166 20.3439 17.5874 20.1567 17.4991 19.9496C17.4109 19.7425 17.3654 19.5197 17.3654 19.2945C17.3654 19.0694 17.4109 18.8466 17.4991 18.6395C17.5874 18.4324 17.7166 18.2452 17.879 18.0893C17.932 18.037 17.9822 17.9827 18.0314 17.9295C18.0549 17.9041 18.0782 17.8789 18.1014 17.8543Z"
          fill="white"
        />
        <path
          d="M19.494 23.1148C19.6027 23.006 19.7116 22.897 19.821 22.788L18.386 21.3791L15.0888 24.6753C15.1191 24.7048 15.1505 24.7361 15.1827 24.7683C15.2405 24.826 15.3011 24.8864 15.3634 24.9447C15.6716 25.2399 16.0819 25.4048 16.5086 25.4048C16.9354 25.4048 17.3457 25.2399 17.6538 24.9447C17.9904 24.625 18.3172 24.2955 18.644 23.966C18.7816 23.8272 18.9192 23.6885 19.0575 23.5504C19.203 23.4061 19.3479 23.2611 19.493 23.1158L19.494 23.1148Z"
          fill="white"
        />
        <path
          d="M20.0078 22.9572C20.0078 22.9572 20.0266 22.9697 20.0381 22.9812C20.2546 23.1984 20.472 23.4148 20.6894 23.6312C21.1882 24.1277 21.6872 24.6244 22.176 25.1306C22.4691 25.4436 22.6291 25.8583 22.622 26.2871C22.615 26.7158 22.4416 27.1251 22.1384 27.4283C22.0807 27.486 22.0239 27.5451 21.9701 27.601C21.9421 27.6301 21.9149 27.6584 21.8888 27.6852L18.5832 24.3818L20.0078 22.9572Z"
          fill="white"
        />
        <path
          d="M24.1691 20.3182C23.9516 20.2198 23.7154 20.1695 23.4766 20.1706C23.2587 20.1662 23.0421 20.2063 22.8403 20.2886C22.6384 20.3708 22.4555 20.4935 22.3027 20.649C22.1904 20.7613 22.078 20.8738 21.9655 20.9863C21.4025 21.5496 20.8379 22.1145 20.2703 22.6751C20.1878 22.7576 20.2024 22.8015 20.2766 22.8746C20.6874 23.2791 21.095 23.6868 21.4996 24.0976C21.58 24.1791 21.6249 24.1812 21.7084 24.0976C22.718 23.0894 23.7276 22.0798 24.7372 21.0688C24.8183 20.9882 24.8583 20.9485 24.8586 20.9085C24.859 20.8676 24.818 20.8264 24.7372 20.7409C24.5805 20.5608 24.3867 20.4166 24.1691 20.3182Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_dd_3816_38060"
        x={2.66669}
        y={0}
        width={34.6667}
        height={44}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3816_38060" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_3816_38060" result="effect2_dropShadow_3816_38060" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3816_38060" result="shape" />
      </filter>
      <radialGradient
        id="paint0_radial_3816_38060"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20 0.999999) rotate(90) scale(40 40.1825)"
      >
        <stop offset={0.297418} stopOpacity={0} />
        <stop offset={1} stopOpacity={0.08} />
      </radialGradient>
    </defs>
  </svg>
);
export default IfcIcon;
