import { useMediaQuery } from '@shape-construction/hooks';
import React from 'react';
import { ModalBase, type ModalBaseProps } from '../ModalBase/ModalBase';
import type { Transition } from '../ModalBase/config/transitions';
import { breakpoints } from '../utils/breakpoints';
import { ModalContent } from './components/ModalContent';
import { ModalFooter } from './components/ModalFooter';
import { ModalHeader } from './components/ModalHeader';
import { ModalTitle } from './components/ModalTitle';

type Components = {
  Header: typeof ModalBase.Header;
  Title: typeof ModalBase.Title;
  SubTitle: typeof ModalBase.SubTitle;
  Content: typeof ModalBase.Content;
  Footer: typeof ModalBase.Footer;
};

export type ModalProps = Pick<ModalBaseProps, 'children' | 'open' | 'onClose' | 'initialFocus'>;

export const Modal: React.FC<ModalProps> & Components = ({ onClose, open, children, ...props }) => {
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));
  const fullScreenMode = !isLargeScreen;
  const isPadded = isLargeScreen;
  const hasRoundBorders = isLargeScreen;
  const transition: Transition = isLargeScreen ? 'fade' : 'bottom-to-top';

  return (
    <ModalBase
      {...props}
      onClose={onClose}
      open={open}
      fullScreen={fullScreenMode}
      outsidePad={isPadded}
      roundBorders={hasRoundBorders}
      transition={transition}
      fullWidth
      maxWidth={isLargeScreen ? 'lg' : 'none'}
    >
      {children}
    </ModalBase>
  );
};

Modal.Header = ModalHeader;
Modal.Title = ModalTitle;
Modal.SubTitle = ModalBase.SubTitle;
Modal.Content = ModalContent;
Modal.Footer = ModalFooter;
