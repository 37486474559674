import classNames from 'clsx';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import { cn } from '../../utils/classes';
import type { CardSize } from '../Card.types';

const headerPaddingClasses: Record<NonNullable<CardSize>, string> = {
  small: 'px-4 pt-3 pb-3',
  medium: 'px-6 pt-4 pb-4',
  large: 'px-8 pt-5 pb-5',
};

const headerTitleClassesMap: Record<NonNullable<CardSize>, string> = {
  small: 'text-sm leading-5 font-medium',
  medium: 'text-base leading-6 font-medium',
  large: 'text-lg leading-6 font-medium',
};

const headerSubtitleClassesMap: Record<NonNullable<CardSize>, string> = {
  small: 'text-xs leading-4 font-normal',
  medium: 'text-xs leading-4 font-normal',
  large: 'text-xs leading-4 font-normal',
};

export type CardHeaderProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
  border?: boolean;
  size?: CardSize;
  subtitle?: string;
  title: string;
};

export const CardHeader: React.FC<CardHeaderProps> = ({
  border = false,
  children,
  size = 'small',
  subtitle,
  title,

  ...props
}) => {
  const headerClasses = twMerge(classNames(headerPaddingClasses[size]));
  const headerTitleClasses = twMerge(classNames('text-gray-900', headerTitleClassesMap[size]));
  const headerSubtitleClasses = twMerge(classNames('text-gray-500', headerSubtitleClassesMap[size]));

  return (
    <div
      {...props}
      className={cn('flex justify-between items-center', headerClasses, props.className, {
        'border-b border-gray-200': border,
      })}
    >
      <div className="flex flex-col">
        <div className={headerTitleClasses}>{title}</div>
        {subtitle && <div className={headerSubtitleClasses}>{subtitle}</div>}
      </div>
      {children && <div className="flex gap-x-2">{children}</div>}
    </div>
  );
};
