import * as React from 'react';
import type { SVGProps } from 'react';
const XlsmIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={44} viewBox="0 0 40 44" fill="none" aria-hidden="true" {...props}>
    <g id="file-icon/xlsm">
      <g id="Vector" filter="url(#filter0_dd_3816_37950)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6776 41C27.0141 41 13.7443 41 7.32667 41C5.85392 41 4.66666 39.82 4.66666 38.3473C4.66666 30.3468 4.66666 10.7752 4.66666 3.6498C4.66666 2.17704 5.86056 1 7.33332 1H26.2222Z"
          fill="#059669"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6776 41C27.0141 41 13.7443 41 7.32667 41C5.85392 41 4.66666 39.82 4.66666 38.3473C4.66666 30.3468 4.66666 10.7752 4.66666 3.6498C4.66666 2.17704 5.86056 1 7.33332 1H26.2222Z"
          fill="url(#paint0_radial_3816_37950)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L26.2222 7.44444C26.2222 8.9172 27.4161 10.1111 28.8889 10.1111L35.3333 10.1111L26.2222 1Z"
          fill="white"
          fillOpacity={0.3}
        />
      </g>
      <path
        id="Vector_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.06 31.8828V31.8888H23.2381C24.7109 31.8888 25.9048 30.6949 25.9048 29.2222V19.2222H27.9647V17.8888C27.9647 16.7161 27.0563 15.7556 25.9048 15.6725V15.6666H16.7267C15.2539 15.6666 14.06 16.8605 14.06 18.3333V28.3333H12V29.6664C12 30.8392 12.9084 31.7997 14.06 31.8828ZM20.9876 18.5691L20.7548 25.6566H19.0617L18.8218 18.5691H20.9876ZM18.7654 27.8645C18.7654 27.5332 18.8689 27.2589 19.0758 27.0415C19.2875 26.8189 19.5649 26.7076 19.9083 26.7076C20.2563 26.7076 20.5338 26.8189 20.7407 27.0415C20.9477 27.2589 21.0511 27.5332 21.0511 27.8645C21.0511 28.1854 20.9477 28.4571 20.7407 28.6797C20.5338 28.9022 20.2563 29.0135 19.9083 29.0135C19.5649 29.0135 19.2875 28.9022 19.0758 28.6797C18.8689 28.4571 18.7654 28.1854 18.7654 27.8645Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_3816_37950"
        x={2.66666}
        y={0}
        width={34.6667}
        height={44}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3816_37950" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_3816_37950" result="effect2_dropShadow_3816_37950" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3816_37950" result="shape" />
      </filter>
      <radialGradient
        id="paint0_radial_3816_37950"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20 0.999999) rotate(90) scale(40 40.1825)"
      >
        <stop offset={0.297418} stopOpacity={0} />
        <stop offset={1} stopOpacity={0.08} />
      </radialGradient>
    </defs>
  </svg>
);
export default XlsmIcon;
