import type { RequestInterceptors, ResponseInterceptors } from 'app/axios';
import { environment } from 'app/config/environment';
import type { AxiosInstance, Method } from 'axios';
import {
  onMaintenanceMode,
  onMutateError,
  onNotAcceptedEndUserAgreement,
  onUnAuthorized,
  preserveAuthorizationHeader,
  setupAuthorizationHeader,
} from './axios-interceptor-handlers';

export const onRequest: RequestInterceptors['onRequest'] = (request) => {
  request.headers = request.headers ?? {}; // eslint-disable-line no-param-reassign
  request.headers['X-Shape-User-Agent'] = `ShapeApp/${environment.VERSION}`; // eslint-disable-line no-param-reassign
  return setupAuthorizationHeader(request);
};

export const onResponse: ResponseInterceptors['onResponse'] = (response) => {
  preserveAuthorizationHeader(response);

  return response;
};

const mutateMethods: Method[] = ['patch', 'post', 'put', 'delete'];
export const onResponseError: ResponseInterceptors['onResponseError'] = (error) => {
  const method = error.config?.method as Method | undefined;
  const isMutating = method && mutateMethods.includes(method);

  if (error.response) {
    const status = error.response.status;
    // @ts-expected-error
    const errorCode = error.response.data?.errorCode;

    if (status === 401) {
      if (errorCode === 'unconfirmed') return Promise.reject(error);

      onUnAuthorized();
    }

    if (status === 403) {
      if (errorCode === 'latest_end_user_agreement_not_accepted') {
        onNotAcceptedEndUserAgreement();
      }
    }

    if (status === 503) {
      /**
       * Using Promise.resolve to bypass the channels service unavailable error because the request is being made through a POST and it
       * is going to be show a toast through the "if (isMutating) onMutateError(error);"
       * */
      if (errorCode === 'channels_service_unavailable') return Promise.resolve(error);

      if (errorCode === 'maintenance') onMaintenanceMode();
    }
  }

  if (isMutating) onMutateError(error);

  return Promise.reject(error);
};

export const setupInterceptors = (instance: AxiosInstance) => {
  instance.interceptors.request.use(onRequest, undefined);
  instance.interceptors.response.use(onResponse, onResponseError);
};
