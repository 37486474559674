import Hotjar from '@hotjar/browser';
import { useCookieConsent } from 'app/analytics/hooks/useCookieConsent';
import { trackerTypes } from 'app/analytics/trackingConfig';
import { environment } from 'app/config/environment';
import { useEffect, useRef, useState } from 'react';

const HOTJAR_VERSION = 6;

export const useHotjar = () => {
  const hotjarSiteId = environment.HOTJAR_SITE_ID;
  const { consentData } = useCookieConsent();
  const [isInitialized, setIsInitialized] = useState(false);
  const singletonRef = useRef(false);

  useEffect(() => {
    const shouldInitialize = !singletonRef.current && Boolean(hotjarSiteId) && consentData?.[trackerTypes.hotjar];

    if (shouldInitialize) {
      Hotjar.init(Number(hotjarSiteId), HOTJAR_VERSION);
      setIsInitialized(true);
      singletonRef.current = true;
    }
  }, [consentData, hotjarSiteId]);

  return { isInitialized };
};
