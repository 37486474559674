import * as React from 'react';
import type { SVGProps } from 'react';
const TxtIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={44} viewBox="0 0 40 44" fill="none" aria-hidden="true" {...props}>
    <g id="file-icon/txt">
      <g id="Vector" filter="url(#filter0_dd_3816_37901)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6776 41C27.0141 41 13.7443 41 7.32667 41C5.85392 41 4.66666 39.82 4.66666 38.3473C4.66666 30.3468 4.66666 10.7752 4.66666 3.6498C4.66666 2.17704 5.86056 1 7.33332 1H26.2222Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6776 41C27.0141 41 13.7443 41 7.32667 41C5.85392 41 4.66666 39.82 4.66666 38.3473C4.66666 30.3468 4.66666 10.7752 4.66666 3.6498C4.66666 2.17704 5.86056 1 7.33332 1H26.2222Z"
          fill="url(#paint0_radial_3816_37901)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L26.2221 7.44444C26.2221 8.9172 27.416 10.1111 28.8887 10.1111L35.3333 10.1111L26.2222 1Z"
          fill="#F3F4F6"
        />
      </g>
      <g id="Vector_2">
        <path d="M27.7778 15.8889H12.2222V17.8889H27.7778V15.8889Z" fill="#374151" />
        <path d="M27.7778 19.6667H12.2222V21.6667H27.7778V19.6667Z" fill="#374151" />
        <path d="M12.2222 23.4445H27.7778V25.4445H12.2222V23.4445Z" fill="#374151" />
        <path d="M21.5556 27.2223H12.2222V29.2223H21.5556V27.2223Z" fill="#374151" />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_dd_3816_37901"
        x={2.66666}
        y={0}
        width={34.6667}
        height={44}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3816_37901" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_3816_37901" result="effect2_dropShadow_3816_37901" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3816_37901" result="shape" />
      </filter>
      <radialGradient
        id="paint0_radial_3816_37901"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20 0.999999) rotate(90) scale(40 40.1825)"
      >
        <stop offset={0.297418} stopOpacity={0} />
        <stop offset={1} stopOpacity={0.03} />
      </radialGradient>
    </defs>
  </svg>
);
export default TxtIcon;
