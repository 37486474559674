import { yupResolver } from '@hookform/resolvers/yup';
import { useMessage, useMessageGetter } from '@messageformat/react';
import { Button, InputTextArea, Modal } from '@shape-construction/arch-ui';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { AppVersion } from './AppVersion';

export type CrashReportFormData = {
  description: string;
};

interface CrashReportProps {
  onClose: () => void;
  onSubmit: ({ description }: CrashReportFormData) => void;
}

const CrashReport: React.FC<CrashReportProps> = ({ onClose, onSubmit }) => {
  const messages = useMessageGetter('crashReport');
  const requiredFieldMessage = useMessage('errors.requiredField');

  const crashReportSchema = Yup.object().shape({
    description: Yup.string().required(requiredFieldMessage),
  });

  const form = useForm<CrashReportFormData>({
    resolver: yupResolver(crashReportSchema),
    defaultValues: {
      description: '',
    },
  });

  const { register, handleSubmit } = form;

  const submitFeedback = (values: CrashReportFormData) => {
    const { description } = values;
    onSubmit({
      description: description,
    });
    handleCloseModal();
  };

  const handleCloseModal = () => {
    onClose();
  };

  return (
    <Modal open onClose={handleCloseModal}>
      <Modal.Header onClose={handleCloseModal}>
        <Modal.Title>{messages('title')}</Modal.Title>
      </Modal.Header>

      <Modal.Content className="flex p-4">
        <form className="space-y-6" onSubmit={handleSubmit(submitFeedback)}>
          <InputTextArea label={messages('description.label')} rows={6} {...register('description')} />
        </form>
        <div className="pt-8 pb-4">
          <AppVersion />
        </div>
      </Modal.Content>
      <Modal.Footer>
        <Button color="secondary" variant="outlined" size="md" onClick={handleCloseModal}>
          {messages('cancelCTA')}
        </Button>
        <Button color="primary" variant="contained" size="md" type="submit" onClick={handleSubmit(submitFeedback)}>
          {messages('submitCTA')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CrashReport;
