import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '..';

export type APIError = {
  response: {
    data: {
      errorCode: string;
      errorDescription: string;
    };
  };
};

export type PrintPreferencesState = {
  visibility: {
    [key: string]: boolean;
  };
};

type SetVisibilityPayload = {
  key: string;
  value: boolean;
};

const initialState: PrintPreferencesState = {
  visibility: {},
};

const printPreferencesSlice = createSlice({
  name: 'printPreferences',
  initialState,
  reducers: {
    setFieldVisibility(state, action: PayloadAction<SetVisibilityPayload>) {
      return {
        ...state,
        visibility: {
          ...state.visibility,
          [action.payload.key]: action.payload.value,
        },
      };
    },
  },
});

export const { setFieldVisibility } = printPreferencesSlice.actions;
export default printPreferencesSlice.reducer;

export const getRememberedVisibility = (state: RootState) => state.printPreferences.visibility;
