import { useMessage, useMessageGetter } from '@messageformat/react';
import * as Sentry from '@sentry/react';
import { Button, Link } from '@shape-construction/arch-ui';
import { ErrorIcon } from '@shape-construction/arch-ui/src/Icons/custom';
import { breakpoints } from '@shape-construction/arch-ui/src/utils/breakpoints';
import { useMediaQuery } from '@shape-construction/hooks';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { environment } from '../../config/environment';
import { useModal } from '../../hooks/useModal';
import { AppVersion } from './AppVersion';
import CrashReport, { type CrashReportFormData } from './CrashReport';

interface CrashPageProps {
  resetError?: () => void;
}

export const CrashPage: React.FC<CrashPageProps> = ({ resetError }) => {
  const supportEmail = environment.SUPPORT_EMAIL;
  const messages = useMessageGetter('error');
  const subject = useMessage('crashReport.subject');

  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));
  const { open, openModal, closeModal } = useModal(false);
  const [eventId, setEventId] = useState<string | null>(null);
  const client = Sentry.getClient();
  const isSentryAvailable = Boolean(client);
  const isFeedbackAvailable = isSentryAvailable && eventId;
  const url = window.location.href;

  useEffect(() => {
    const lastEventId = Sentry.lastEventId();
    if (client && lastEventId) {
      setEventId(lastEventId);
    } else {
      setEventId(null);
    }
  }, []);

  const openCrashReportModal = () => {
    if (isSentryAvailable) {
      openModal();
    }
  };

  const closeCrashReportModal = () => {
    if (isSentryAvailable) {
      closeModal();
    }
  };

  const goToProjects = () => {
    if (resetError) {
      resetError();
    } else {
      navigate('/');
    }
  };

  const submitCrashReport = ({ description }: CrashReportFormData) => {
    if (isSentryAvailable && eventId) {
      Sentry.captureFeedback({
        associatedEventId: eventId,
        // The originator doesn't matter, it's only a tag.
        // Sentry receives Shape support email instead of user PII.
        email: supportEmail,
        message: description,
        // Subject is also fixed, user does not need to specify it.
        name: subject,
        url: url,
        source: '',
      });
      setEventId(null);
      closeModal();
      goToProjects();
    }
  };

  const renderButtonPanel = () => {
    if (isFeedbackAvailable) {
      return (
        <>
          <Button
            color="primary"
            variant="contained"
            size="md"
            onClick={openCrashReportModal}
            fullWidth={!isLargeScreen}
          >
            {messages('submitFeedbackCTA')}
          </Button>
          <Link as="button" color={'primary'} onClick={goToProjects}>
            {messages('goBackCTA')}
          </Link>
        </>
      );
    }

    return (
      <Button color="primary" variant="contained" size="md" onClick={goToProjects} fullWidth={!isLargeScreen}>
        {messages('goBackCTA')}
      </Button>
    );
  };

  return (
    <div className="h-screen">
      <div className="flex h-full flex-col items-center justify-center space-y-6 p-4">
        <ErrorIcon />
        <div className="flex flex-col space-y-2 text-center">
          <div className="text-3xl font-bold">{messages('title')}</div>
          <p className="text-base leading-6">
            {messages('content')}{' '}
            <a
              href={`mailto:${supportEmail}`}
              target="_blank"
              rel="noreferrer"
              className="font-medium text-indigo-500 hover:text-indigo-400"
            >
              {messages('contactCTA')}
            </a>
          </p>
        </div>
        {renderButtonPanel()}
        {<AppVersion />}
      </div>
      {isFeedbackAvailable && open && <CrashReport onClose={closeCrashReportModal} onSubmit={submitCrashReport} />}
    </div>
  );
};
