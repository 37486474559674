import { Combobox, type ComboboxOptionsProps } from '@headlessui/react';
import classNames from 'clsx';
import React, { type ElementType } from 'react';
import { twMerge } from 'tailwind-merge';
import type { PrimitiveProps } from '../../utils/render';

export type SearchOptionsProps<TTag extends ElementType> = PrimitiveProps<ComboboxOptionsProps<TTag>>;

export function SearchOptions<TTag extends ElementType>({ className, ...props }: SearchOptionsProps<TTag>) {
  return (
    <Combobox.Options
      className={twMerge(
        classNames(
          'absolute z-popover mt-3 py-2',
          'w-full min-w-80 max-h-72 overflow-auto',
          'bg-white rounded-md ring-1 ring-black ring-opacity-5 shadow-lg outline-none'
        ),
        className
      )}
      {...props}
    />
  );
}
SearchOptions.displayName = 'Search.Options';
