import * as React from 'react';
import type { SVGProps } from 'react';
const PlusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/plus">
      <path
        id="Icon"
        d="M12.9 5.70005C12.9 5.20299 12.497 4.80005 12 4.80005C11.5029 4.80005 11.1 5.20299 11.1 5.70005V11.1H5.69999C5.20293 11.1 4.79999 11.503 4.79999 12C4.79999 12.4971 5.20293 12.9 5.69999 12.9L11.1 12.9V18.3C11.1 18.7971 11.5029 19.2 12 19.2C12.497 19.2 12.9 18.7971 12.9 18.3V12.9H18.3C18.797 12.9 19.2 12.4971 19.2 12C19.2 11.503 18.797 11.1 18.3 11.1H12.9V5.70005Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default PlusIcon;
