import { FileExtensionIcon } from '@shape-construction/arch-ui';
import type { DocumentPreview } from '../../types';
import { getFileExtension, isFileImageType } from '../../utils/UtilsGallery';

export type DocumentThumbnailProps = {
  document: DocumentPreview;
};

export const DocumentThumbnail: React.FC<DocumentThumbnailProps> = ({ document }) => {
  if (isFileImageType(document!.fileToUpload)) {
    return <img className="w-full h-full object-cover" src={document!.preview} alt={document!.fileToUpload.name} />;
  }

  return <FileExtensionIcon extension={getFileExtension(document!.fileToUpload)} className="w-8 h-8" />;
};
