import {
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarIcon,
  SidebarItem,
  SidebarRoot,
  SidebarSection,
} from './Sidebar';
import { SidebarProvider, useSidebar } from './Sidebar.context';

export { useSidebar };
export default {
  Provider: SidebarProvider,
  Root: SidebarRoot,
  Content: SidebarContent,
  Footer: SidebarFooter,
  Header: SidebarHeader,
  Icon: SidebarIcon,
  Item: SidebarItem,
  Section: SidebarSection,
};
