import React from 'react';

import { AuthPagesHeader } from '../AuthPagesHeader/AuthPagesHeader';
import Button from '../Button';
import InputEmail from '../InputEmail';
import type { InputExtraPropsType } from '../types/InputBase';
import { AuthPageBottomSection } from './components/AuthPageBottomSection';

export type AuthFields = {
  email: string;
};

type Components = {
  BottomSection: typeof AuthPageBottomSection;
};

export type ExtraPropsType = {
  email: InputExtraPropsType;
};

export interface AuthPageProps {
  /**
   * Logo to be displayed on the top section
   */
  logo?: React.ReactNode;
  /**
   * function to be called on the submit of form
   */
  submitEmail: (e: React.FormEvent<HTMLFormElement>) => void;
  /**
   * Disables submit button while submitting the form
   */
  disableSubmitButton: boolean;
  /**
   * Stores the error message for the email input
   */
  error?: string;
  /**
   * Extra props to register an input and apply validation rules to React Hook Form
   */
  extraProps: ExtraPropsType;
  /**
   * Element to be rendered at the end of the component
   */
  children?: React.ReactNode;
  /**
   * Main title text to be displayed prominently at the top of the form or page.
   */
  title?: string;
  /**
   * Text to be displayed as a subtitle under the main title.
   */
  subTitle?: string;
}

export const AuthPage: React.FC<AuthPageProps> & Components = ({
  logo,
  submitEmail,
  disableSubmitButton,
  error,
  extraProps,
  children,
  title = 'Log in or create an account',
  subTitle,
}) => {
  return (
    <div className="flex flex-col justify-center">
      <AuthPagesHeader title={title} logo={logo} subTitle={subTitle} />

      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="py-8">
          <form className="space-y-6" onSubmit={submitEmail} noValidate>
            <InputEmail {...extraProps.email} name="email" label="Email" error={error} aria-label="insert email" />
            <Button
              color="primary"
              variant="contained"
              size="md"
              type="submit"
              fullWidth
              disabled={disableSubmitButton}
            >
              Continue
            </Button>
          </form>
        </div>
        {children}
      </div>
    </div>
  );
};

AuthPage.BottomSection = AuthPageBottomSection;
