import { useMediaQuery } from '@shape-construction/hooks';
import React, { useEffect } from 'react';
import toast, { Toaster as ReactHotToastToaster, useToasterStore, type ToasterProps } from 'react-hot-toast';
import { breakpoints } from '../utils/breakpoints';
import { ToastPortal } from './ToastPortal';

export const Toaster: React.FC<ToasterProps> = ({ ...props }) => {
  const isLargeScreen = useMediaQuery(breakpoints.up('sm'));
  const { toasts } = useToasterStore();
  const TOAST_LIMIT = isLargeScreen ? 5 : 2;

  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.dismiss(t.id)); // Dismiss old notifications
  }, [toasts, TOAST_LIMIT]);

  return (
    <ToastPortal>
      <ReactHotToastToaster
        position={isLargeScreen ? 'bottom-right' : 'bottom-center'}
        reverseOrder={false}
        toastOptions={{
          duration: 6000,
        }}
        {...props}
      />
    </ToastPortal>
  );
};
