import changeCase from 'change-case';
import type { Location } from 'history';
import queryString from 'query-string';

const toSnakeCase = (value: any) => {
  if (Array.isArray(value)) {
    return value;
  }
  if (value !== null && value.constructor === Object) {
    return Object.keys(value).reduce(
      (result, key) => ({
        ...result,
        [changeCase.snakeCase(key)]: value[key],
      }),
      {}
    );
  }
  return value;
};

export const stringifyToAPI = (object: any) => {
  const newObject = toSnakeCase(object);

  const stringyQuery = queryString.stringify(newObject, {
    arrayFormat: 'bracket',
  });

  return stringyQuery;
};

export const stringify = (object: any) =>
  queryString.stringify(object, {
    arrayFormat: 'bracket',
  });

export const parse = (query: string) =>
  queryString.parse(query, {
    arrayFormat: 'bracket',
  });

// @ts-expect-error ts-migrate(7024) FIXME: Function implicitly has return type 'any' because ... Remove this comment to see the full error message
export const sanitizeQueryString = (string: any) =>
  string[0] === '?' ? sanitizeQueryString(string.substring(1)) : string;

export const addQueryParams = (
  search: Location['search'],
  newParams: { [key: string]: string | string[] }
): Location['search'] => {
  const queryParams = queryString.parse(search);
  const params = Object.keys(newParams).reduce(
    (result, key) => ({ ...result, [key]: newParams[key] === '' ? undefined : newParams[key] }),
    {}
  );

  return queryString.stringify(
    {
      ...queryParams,
      ...params,
    },
    { sort: false }
  );
};

export const removeQueryParams = (search: Location['search'], paramsToRemove: string[]): Location['search'] => {
  const queryParams = queryString.parse(search);

  paramsToRemove.forEach((toRemove) => delete queryParams[toRemove]);

  return queryString.stringify(queryParams);
};
