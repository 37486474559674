import React, { type ComponentPropsWithoutRef, type FC } from 'react';
import Skeleton from '../../Skeleton';
import Stack from '../../Stack';
import { cn } from '../../utils/classes';

export const ListSectionsSkeleton: React.FC = () => (
  <div className="bg-gray-50 p-6">
    <Stack direction="column" spacing="4" aria-label="sections-skeleton">
      <Skeleton className="h-7 w-full bg-gray-400" />
      <Stack.Item aria-label="article">
        <article className="space-y-2">
          <Skeleton className="h-10 w-full" />
        </article>
      </Stack.Item>
      <Stack.Item aria-label="article">
        <article className="space-y-2">
          <Skeleton className="h-10 w-full" />
        </article>
      </Stack.Item>
    </Stack>
  </div>
);

export type ListSectionsProps = ComponentPropsWithoutRef<'div'> & {
  title: string;
};

export const ListSectionsRoot: FC<ListSectionsProps> = ({ title, children, className, ...props }) => (
  <div className={className} {...props}>
    <div className="h-4 bg-gradient-to-t from-gray-200" />
    <Stack direction="column" aria-label="sections">
      <h4 className="text-neutral-subtle text-xs leading-4 font-medium tracking-wider uppercase px-4 py-3.5">
        {title}
      </h4>
      {children}
    </Stack>
  </div>
);

export type ListSectionsItemProps = ComponentPropsWithoutRef<typeof Stack.Item> & {
  icon?: React.ReactNode;
};

export const ListSectionsItem: FC<ListSectionsItemProps> = ({ className, children, icon, ...props }) => (
  <Stack.Item className={cn('flex w-full flex-row justify-between px-4 py-3', className)} {...props}>
    <span className="text-sm font-medium leading-5">{children}</span>
    {icon}
  </Stack.Item>
);

export const ListSections = {
  Root: ListSectionsRoot,
  Item: ListSectionsItem,
};
