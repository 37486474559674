import React from 'react';
import { ModalBase } from '../../ModalBase';
import type { ModalBaseHeaderProps } from '../../ModalBase/components/ModalBaseHeader';

export type ModalHeaderProps = Omit<ModalBaseHeaderProps, 'bottomBorder'>;

export const ModalHeader: React.FC<ModalHeaderProps> = (props) => {
  return (
    <ModalBase.Header bottomBorder {...props}>
      {props.children}
    </ModalBase.Header>
  );
};
