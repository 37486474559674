import classnames from 'clsx';
import React, { type FC, type HtmlHTMLAttributes } from 'react';

export interface PanelProps extends HtmlHTMLAttributes<HTMLDivElement> {
  // Props coming from SplitLayout React.cloneElement
  index?: number;
  isHidden?: boolean;
  isExpanded?: boolean;
}

export const Panel: FC<PanelProps> = ({ children, className, index, isHidden, isExpanded, ...props }) => (
  <div
    aria-expanded={isExpanded}
    aria-hidden={isHidden}
    className={classnames(className, {
      'hidden opacity-0': isHidden,
      'w-full opacity-100': isExpanded,
    })}
    {...props}
  >
    {children}
  </div>
);
