import { useMessageGetter } from '@messageformat/react';
import type { ProjectSchema } from '@shape-construction/api/model';
import { Menu } from '@shape-construction/arch-ui';
import { BoltIcon, RectangleStackIcon } from '@shape-construction/arch-ui/src/Icons/outline';
import React from 'react';
import { Link, type LinkProps, useLocation } from 'react-router-dom';
import { useProject } from '../../queries/projects/projects';

export type IssueTrackerOption = {
  label: string;
  description: string;
  icon: React.FC<React.ComponentProps<'svg'>>;
  link: LinkProps;
};

export type IssueTrackerSectionProps = {
  projectId: ProjectSchema['id'];
};

export const IssueTrackerSection = ({ projectId }: IssueTrackerSectionProps) => {
  const messages = useMessageGetter('quickNewButton');
  const location = useLocation();
  const { data: project } = useProject(projectId);
  const canCreateIssue = project?.availableActions?.createIssue;

  if (!project || !canCreateIssue) return null;

  const options: IssueTrackerOption[] = [
    {
      label: messages('issueTracker.options.completeIssue.title'),
      description: messages('issueTracker.options.completeIssue.description'),
      link: {
        to: `/projects/${projectId}/issues/new`,
        state: { background: location },
      },
      icon: RectangleStackIcon,
    },
    {
      label: messages('issueTracker.options.quickIssue.title'),
      description: messages('issueTracker.options.quickIssue.description'),
      link: {
        to: `/projects/${projectId}/issues/quick-capture`,
        state: undefined,
      },
      icon: BoltIcon,
    },
  ];

  return (
    <>
      <Menu.Heading>{messages('issueTracker.heading')}</Menu.Heading>
      {options.map((option) => (
        <Link {...option.link} key={option.label} className="w-full">
          <Menu.Item aria-label={option.label}>
            <div className="flex flex-row gap-3">
              <div className="justify-start items-start gap-2.5 flex">
                <option.icon className="opacity-50 w-5 h-5" />
              </div>
              <div className="flex flex-col gap-1 justify-start items-start">
                <div className="text-sm font-medium leading-tight">{option.label}</div>
                <div className="opacity-60 text-sm font-normal leading-tight">{option.description}</div>
              </div>
            </div>
          </Menu.Item>
        </Link>
      ))}
    </>
  );
};
