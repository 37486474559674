import * as React from 'react';
import type { SVGProps } from 'react';
const BmpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={44} viewBox="0 0 40 44" fill="none" aria-hidden="true" {...props}>
    <g id="file-icon/bmp">
      <g id="Vector" filter="url(#filter0_dd_3816_38200)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6776 41C27.0141 41 13.7443 41 7.32669 41C5.85393 41 4.66667 39.82 4.66667 38.3473C4.66667 30.3468 4.66667 10.7752 4.66667 3.6498C4.66667 2.17704 5.86058 1 7.33334 1H26.2222Z"
          fill="#10B981"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6776 41C27.0141 41 13.7443 41 7.32669 41C5.85393 41 4.66667 39.82 4.66667 38.3473C4.66667 30.3468 4.66667 10.7752 4.66667 3.6498C4.66667 2.17704 5.86058 1 7.33334 1H26.2222Z"
          fill="url(#paint0_radial_3816_38200)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L26.2222 7.44444C26.2222 8.9172 27.4161 10.1111 28.8889 10.1111L35.3333 10.1111L26.2222 1Z"
          fill="white"
          fillOpacity={0.3}
        />
      </g>
      <path
        id="Vector_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.7415 16.1111H13.4074C12.9983 16.1111 12.6667 16.4428 12.6667 16.852V28.7041C12.6667 29.1131 12.9983 29.4444 13.4074 29.4444H26.7415C27.1506 29.4444 27.4823 29.1131 27.4823 28.7041V16.852C27.4823 16.4428 27.1506 16.1111 26.7415 16.1111ZM25.2603 27.2223H14.8895V23.5188L17.1118 21.2963L20.8158 25.0002L23.038 22.7776L25.2603 25.0002V27.2223ZM22.2971 19.8149C22.2971 20.6331 22.9603 21.2963 23.7785 21.2963C24.5968 21.2963 25.2601 20.6331 25.2601 19.8149C25.2601 18.9963 24.5968 18.3334 23.7785 18.3334C22.9603 18.3334 22.2971 18.9963 22.2971 19.8149Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_3816_38200"
        x={2.66667}
        y={0}
        width={34.6667}
        height={44}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3816_38200" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_3816_38200" result="effect2_dropShadow_3816_38200" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3816_38200" result="shape" />
      </filter>
      <radialGradient
        id="paint0_radial_3816_38200"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20 0.999999) rotate(90) scale(40 40.1825)"
      >
        <stop offset={0.297418} stopOpacity={0} />
        <stop offset={1} stopOpacity={0.08} />
      </radialGradient>
    </defs>
  </svg>
);
export default BmpIcon;
