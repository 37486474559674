import { Listbox, type ListboxOptionsProps } from '@headlessui/react';
import type { Placement, PositioningStrategy } from '@popperjs/core';
import { useMediaQuery } from '@shape-construction/hooks';
import classNames from 'clsx';
import React, { type ElementType, useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { twMerge } from 'tailwind-merge';
import { breakpoints } from '../utils/breakpoints';
import type { PrimitiveProps } from '../utils/render';
import { useSelectContext } from './SelectContext';

type PopperProps = {
  strategy?: PositioningStrategy;
  placement?: Placement;
};

export type SelectPanelProps<TTag extends ElementType> = PrimitiveProps<ListboxOptionsProps<TTag>> &
  PopperProps & { show?: boolean };

export function SelectPanel<TTag extends ElementType>({
  className,
  placement = 'bottom-start',
  strategy = 'absolute',
  ...props
}: SelectPanelProps<TTag>) {
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));
  const { triggerRef } = useSelectContext();
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(triggerRef.current, popperElement, {
    strategy,
    placement,
  });

  useEffect(() => {
    if (popperElement && !isLargeScreen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'unset';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isLargeScreen, popperElement]);

  return (
    <Listbox.Options
      {...props}
      data-testid="select-panel"
      ref={setPopperElement}
      style={props.style || styles.popper}
      {...attributes.popper}
      className={twMerge(
        classNames(
          'absolute z-popover mt-1.5 flex flex-col',
          'w-full min-w-[240px]',
          'bg-white rounded-md ring-1 ring-black ring-opacity-5 shadow-lg outline-none overflow-hidden',
          'divide-y divide-gray-100'
        ),
        className as string
      )}
    />
  );
}
SelectPanel.displayName = 'Select.Panel';
