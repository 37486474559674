import type { ProjectSchema, UserSchemaDefaultProduct } from '@shape-construction/api/model';

const toProductPath = (product: UserSchemaDefaultProduct | undefined) => {
  if (!product) return 'timeline';
  return {
    shift_manager: 'shift-reports',
    issues: 'issues',
    channels: 'channels',
  }[product];
};

export const defaultProjectPath = (
  projectId?: ProjectSchema['id'] | null,
  defaultProduct?: UserSchemaDefaultProduct
) => {
  const path = projectId ? `/projects/${projectId}/${toProductPath(defaultProduct)}` : '/my-projects';

  return path;
};
