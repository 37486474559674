import React from 'react';
import Divider from '../../Divider';
import type { HelpCenterProps } from '../HelpCenter';
import EmptyListArticles from './EmptyListArticles';
import { ListArticles, ListArticlesSkeleton } from './ListArticles';

type ListArticlesContainerProps = Omit<
  HelpCenterProps,
  'sections' | 'selectedArticle' | 'open' | 'onClose' | 'onOpenBookSession'
>;

export const ListArticlesContainer: React.FC<ListArticlesContainerProps> = ({
  isLoading,
  helpCenterEmail,
  helpCenterUrl,
  articles,
  onSelectArticle,
}) => {
  const noArticles = articles.length === 0;

  return (
    <div data-testid="list article section" className="h-full overflow-y-auto">
      {isLoading && <ListArticlesSkeleton />}
      {!isLoading && (
        <div className="flex h-full flex-col gap-y-4 p-4">
          {noArticles ? (
            <EmptyListArticles helpCenterEmail={helpCenterEmail} helpCenterUrl={helpCenterUrl} />
          ) : (
            <>
              <h4 className="text-base font-medium leading-6 text-gray-900">{'Need some help?'}</h4>
              <Divider orientation="horizontal" />
              <ListArticles articles={articles} onSelect={onSelectArticle} />
            </>
          )}
        </div>
      )}
    </div>
  );
};
