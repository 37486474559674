import React from 'react';
import IconBadge from '../../IconBadge';
import { SignalSlashIcon } from '../../Icons/solid';

const Offline: React.FC = () => (
  <IconBadge>
    <SignalSlashIcon />
  </IconBadge>
);

export default Offline;
