import type { DocumentSchema } from '@shape-construction/api/model';

/**
 * This function retrieves a document by ID from an array of documents.
 *
 * @function getDocumentById
 * @param {DocumentSchema[] | undefined} documents - The array of documents to search.
 * @param {string | undefined} documentId - The ID of the document to find.
 * @returns {DocumentSchema | undefined} Returns the found document or undefined
 * if the document or the ID is not provided or if the document is not found.
 *
 */
export const getDocumentById = (documents: (DocumentSchema | null)[] | undefined, documentId: string | undefined) => {
  if (!documents || !documentId) return undefined;

  return documents.find((document) => document?.id === documentId);
};

/**
 * Retrieves the file extension from a File object.
 *
 * @param {File|undefined} file The File object to extract the extension.
 * @returns {string} The file extension, or an empty string if no file or extension is found.
 */
export const getFileExtension = (file: File | undefined) => file?.name?.match(/\.([^.]+)$/)?.[1] ?? '';

/**
 * Checks whether a File object represents an image file.
 * It also classes images with 0 bytes as files.
 *
 * @param {File} file - The File object to be checked.
 * @returns {boolean} `true` if the file is an image, `false` otherwise.
 */
export const isFileImageType = (file: File) => {
  const imageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml', 'image/webp'];
  return imageTypes.includes(file?.type) && file?.size > 0;
};
