import { useMessage } from '@messageformat/react';
import { Alert } from '@shape-construction/arch-ui';
import { SignalSlashIcon } from '@shape-construction/arch-ui/src/Icons/outline';
import { useNetworkState } from '@shape-construction/hooks';
import React from 'react';

export const NetworkStatusNotification: React.FC = () => {
  const { online } = useNetworkState();
  const message = useMessage('offline.status');

  if (online) return null;

  return (
    <Alert color="danger" emphasis="bold" rounded={false} customIcon={<SignalSlashIcon className="h-4 w-4" />}>
      {message}
    </Alert>
  );
};
