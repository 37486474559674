import * as React from 'react';
import type { SVGProps } from 'react';
const AstaIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={44} viewBox="0 0 40 44" fill="none" aria-hidden="true" {...props}>
    <g id="file-icon/asta">
      <g id="Vector" filter="url(#filter0_dd_3816_38098)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6777 41C27.0141 41 13.7443 41 7.3267 41C5.85395 41 4.66669 39.82 4.66669 38.3473C4.66669 30.3468 4.66669 10.7752 4.66669 3.6498C4.66669 2.17704 5.86059 1 7.33335 1H26.2222Z"
          fill="#DC2626"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6777 41C27.0141 41 13.7443 41 7.3267 41C5.85395 41 4.66669 39.82 4.66669 38.3473C4.66669 30.3468 4.66669 10.7752 4.66669 3.6498C4.66669 2.17704 5.86059 1 7.33335 1H26.2222Z"
          fill="url(#paint0_radial_3816_38098)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L26.2222 7.44444C26.2222 8.9172 27.4161 10.1111 28.8889 10.1111L35.3333 10.1111L26.2222 1Z"
          fill="white"
          fillOpacity={0.3}
        />
      </g>
      <path
        id="Vector_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.9996 24.1165H26.2462L20.6388 29.7239V33.4773L29.9996 24.1165ZM19.575 33.4776V29.7241L13.9673 24.1165H10.2138L19.575 33.4776ZM10 23.0526H13.7693L19.575 17.2469V13.4777L10 23.0526ZM20.6388 13.478V17.2472L26.4443 23.0526H30.2135L20.6388 13.478ZM14.8409 23.4855L20.1068 18.2197L25.3726 23.4855L20.1068 28.7514L14.8409 23.4855Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_3816_38098"
        x={2.66669}
        y={0}
        width={34.6667}
        height={44}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3816_38098" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_3816_38098" result="effect2_dropShadow_3816_38098" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3816_38098" result="shape" />
      </filter>
      <radialGradient
        id="paint0_radial_3816_38098"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20 0.999999) rotate(90) scale(40 40.1825)"
      >
        <stop offset={0.297418} stopOpacity={0} />
        <stop offset={1} stopOpacity={0.08} />
      </radialGradient>
    </defs>
  </svg>
);
export default AstaIcon;
