import type { GetArticleAttachmentsParams } from 'zendesk/api/help-center/article-attachments';
import type { GetArticlesParams } from 'zendesk/api/help-center/articles';
import type { GetCategoriesParams } from 'zendesk/api/help-center/categories';
import type { GetSectionsParams } from 'zendesk/api/help-center/sections';
import type { Article, Locale } from 'zendesk/api/help-center/types';

export const queryKeys = {
  helpCenterCategories: (locale: Locale, params?: GetCategoriesParams) => [
    'locales',
    locale,
    'help_center_categories',
    ...(params ? [params] : []),
  ],
  helpCenterSections: (locale: Locale, params?: GetSectionsParams) => [
    'locales',
    locale,
    'help_center_sections',
    ...(params ? [params] : []),
  ],
  helpCenterSection: (locale: Locale, sectionId: number) => ['locales', locale, 'help_center_section', sectionId],
  helpCenterArticles: (locale: Locale, params?: GetArticlesParams) => [
    'locales',
    locale,
    'help_center_articles',
    ...(params ? [params] : []),
  ],
  helpCenterArticleLabels: () => [],
  helpCenterArticleAttachments: (articleId: Article['id'], params?: GetArticleAttachmentsParams) => [
    'articleIds',
    articleId,
    'help_center_article_attachments',
    ...(params ? [params] : []),
  ],
};
