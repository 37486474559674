import { DirectUpload } from 'app/lib/direct-upload/direct-upload';
import type { AxiosProgressEvent } from 'axios';
import useUploadingDocuments from './useUploadingDocuments';

export const useDocumentDirectUpload = (contextId: string) => {
  const { updateDocumentUploadProgress } = useUploadingDocuments(contextId);

  const createDocumentDirectUpload = (temporaryId: string, file: File) => {
    return DirectUpload(file, 'document', {
      onUploadProgress(progressEvent: AxiosProgressEvent) {
        const currentUploadProgress = progressEvent.total ? (progressEvent.loaded / progressEvent.total) * 100 : 0;
        updateDocumentUploadProgress(temporaryId, currentUploadProgress);
      },
    });
  };

  return { createDocumentDirectUpload };
};
