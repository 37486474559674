import { Hidden } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountBoXMarkIcon from '@material-ui/icons/AccountBox';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import RateReviewIcon from '@material-ui/icons/RateReview';
import { useMessageGetter } from '@messageformat/react';
import { ArrowDownTrayIcon } from '@shape-construction/arch-ui/src/Icons/solid';
import { useInstallApp } from 'app/hooks/useInstallApp';
import { useLogout } from 'app/queries/users/users';
import { endSession } from 'app/session';
import React from 'react';
import { Link } from 'react-router-dom';
import { useStyles } from './UserProfile.style';

interface UserProfileProps {
  onSelectMenuItem?: () => void;
  setFeedbackDialogOpen: (open: boolean) => void;
  appVersion?: string;
}

export const UserProfile: React.FC<UserProfileProps> = ({ onSelectMenuItem, setFeedbackDialogOpen, appVersion }) => {
  const classes = useStyles();
  const { mutate: logout } = useLogout({
    mutation: { onMutate: endSession },
  });

  const messages = useMessageGetter('userProfile');
  const { isInstallSupported, isInStandaloneMode, promptToInstall } = useInstallApp();
  const hideInstallButton = !isInstallSupported || isInStandaloneMode;
  const handleInstall = () => {
    promptToInstall();
  };

  const handleLogout = async () => {
    logout();
  };

  const renderContent = () => (
    <>
      <List onClick={onSelectMenuItem}>
        <Link
          to="/my-profile"
          key={messages('menu.myAccount')}
          data-testid="res-profile-menu-my-account-link"
          onClick={onSelectMenuItem}
        >
          <ListItem aria-label={messages('menu.myAccount')} button onClick={onSelectMenuItem}>
            <ListItemIcon>
              <AccountBoXMarkIcon />
            </ListItemIcon>
            <ListItemText primary={messages('menu.myAccount')} />
          </ListItem>
        </Link>
        <Link
          to="/my-projects"
          key={messages('menu.myProjects')}
          data-cy="res-profile-menu-my-projects-link"
          onClick={onSelectMenuItem}
        >
          <ListItem aria-label={messages('menu.myProjects')} button onClick={onSelectMenuItem}>
            <ListItemIcon>
              <AccountTreeIcon />
            </ListItemIcon>
            <ListItemText primary={messages('menu.myProjects')} />
          </ListItem>
        </Link>
      </List>
      <List className={classes.bottomSection}>
        {!hideInstallButton && (
          <ListItem button key={messages('menu.installApp')} onClick={handleInstall}>
            <ListItemIcon>
              <ArrowDownTrayIcon width={24} />
            </ListItemIcon>
            <ListItemText primary={messages('menu.installApp')} />
          </ListItem>
        )}
        <ListItem
          button
          key={messages('menu.sendFeedback')}
          onClick={() => setFeedbackDialogOpen(true)}
          data-cy="profile-menu-send-feedback"
        >
          <ListItemIcon>
            <RateReviewIcon />
          </ListItemIcon>
          <ListItemText primary={messages('menu.sendFeedback')} />
        </ListItem>
        <Link to="#" key={messages('menu.logout')} data-cy="res-profile-menu-logout-link" onClick={handleLogout}>
          <ListItem button>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={messages('menu.logout')} />
          </ListItem>
        </Link>
        {appVersion && (
          <ListItem aria-label={messages('appVersion', { version: appVersion })}>
            <ListItemText
              className={classes.appVersionListItem}
              primary={messages('appVersion', { version: appVersion })}
            />
          </ListItem>
        )}
      </List>
    </>
  );

  return (
    <>
      {
        // @ts-expect-error Type '{ children: Element; client: QueryClient; }' is not assignable to type 'IntrinsicAttributes & QueryClientProviderProps'.
        <Hidden mdUp>
          <div className={classes.container} data-testid="res-user-profile">
            {renderContent()}
          </div>
        </Hidden>
      }
      {
        // @ts-expect-error Type '{ children: Element; client: QueryClient; }' is not assignable to type 'IntrinsicAttributes & QueryClientProviderProps'.
        <Hidden smDown>{renderContent()}</Hidden>
      }
    </>
  );
};
