import React, { type ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';

export type ConfirmationModalImageProps = ComponentProps<'div'>;

export const ConfirmationModalImage: React.FC<ConfirmationModalImageProps> = ({ className, ...props }) => {
  const classes = twMerge('flex-none flex-shrink-0', className);

  return <div className={classes} {...props} />;
};
