import * as React from 'react';
import type { SVGProps } from 'react';
const NwdIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={44} viewBox="0 0 40 44" fill="none" aria-hidden="true" {...props}>
    <g id="file-icon/nwd">
      <g id="Vector" filter="url(#filter0_dd_3816_38045)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6776 41C27.0141 41 13.7443 41 7.32667 41C5.85392 41 4.66666 39.82 4.66666 38.3473C4.66666 30.3468 4.66666 10.7752 4.66666 3.6498C4.66666 2.17704 5.86056 1 7.33332 1H26.2222Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6776 41C27.0141 41 13.7443 41 7.32667 41C5.85392 41 4.66666 39.82 4.66666 38.3473C4.66666 30.3468 4.66666 10.7752 4.66666 3.6498C4.66666 2.17704 5.86056 1 7.33332 1H26.2222Z"
          fill="url(#paint0_radial_3816_38045)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L26.2222 7.44444C26.2222 8.9172 27.4161 10.1111 28.8889 10.1111L35.3333 10.1111L26.2222 1Z"
          fill="#F3F4F6"
        />
      </g>
      <g id="Vector_2">
        <path
          d="M28.5333 14.1111H24.7111C24.3289 14.4933 24.1378 14.6844 23.7556 15.0666V22.95C23.7556 22.95 23.8033 23.8577 23.4211 23.9533C23.0389 24.0489 22.6089 23.4278 22.6089 23.4278L15.4422 14.1111H11.3333V33.2222H15.1556C15.5378 32.84 15.7289 32.6489 16.1111 32.2666C16.1111 32.2666 15.9678 24.7655 16.7322 24.7177C18.2133 24.6222 24.1378 33.2222 24.1378 33.2222H28.5333V14.1111Z"
          fill="#1976D2"
        />
        <path
          d="M23.7562 15.0665V22.9498C23.7562 22.9498 23.804 23.8576 23.4217 23.9531C23.0395 24.0487 22.6095 23.4276 22.6095 23.4276L19.1217 18.8887C18.8828 20.5131 18.7873 22.3765 18.7873 24.2876C18.7873 25.0043 18.7873 25.6731 18.8351 26.342C21.0806 28.7787 24.1384 33.222 24.1384 33.222H24.9506C25.6195 30.9287 26.0495 27.7754 26.0495 24.2876C26.0495 20.1787 25.4762 16.5476 24.5684 14.2543C24.2817 14.5409 24.0906 14.732 23.7562 15.0665Z"
          fill="#2770AA"
        />
        <path
          d="M23.3741 23.9531C22.9919 24.0009 22.6095 23.4276 22.6095 23.4276L19.6952 19.6531C18.5008 18.6975 24.6641 30.7375 25.5241 30.0209C26.3841 29.352 25.1896 26.2464 23.4696 24.0487C23.4696 24.0487 23.4219 24.0009 23.3741 23.9531Z"
          fill="#3A95CC"
        />
        <path
          d="M16.7325 24.7177C18.2136 24.6222 24.138 33.2222 24.138 33.2222H28.5336V32.8877L19.7425 19.7011L15.4425 14.1111H11.668C12.3369 19.7489 13.388 26.5333 15.1558 33.2222C15.538 32.84 15.7291 32.6489 16.1114 32.2666C16.1114 32.2666 15.968 24.7655 16.7325 24.7177Z"
          fill="#1E88E5"
        />
        <path
          d="M16.0163 19.1756C13.9619 19.7011 15.1563 33.2222 15.1563 33.2222C15.5385 32.84 15.7296 32.6489 16.1119 32.2667C16.1119 32.2667 15.9685 24.7656 16.733 24.7178C18.2141 24.6222 24.1385 33.2222 24.1385 33.2222C24.1385 33.2222 17.8796 18.6978 16.0163 19.1756Z"
          fill="#2559AA"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_dd_3816_38045"
        x={2.66666}
        y={0}
        width={34.6667}
        height={44}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3816_38045" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_3816_38045" result="effect2_dropShadow_3816_38045" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3816_38045" result="shape" />
      </filter>
      <radialGradient
        id="paint0_radial_3816_38045"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20 0.999999) rotate(90) scale(40 40.1825)"
      >
        <stop offset={0.297418} stopOpacity={0} />
        <stop offset={1} stopOpacity={0.03} />
      </radialGradient>
    </defs>
  </svg>
);
export default NwdIcon;
