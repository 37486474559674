import { useMessageGetter } from '@messageformat/react';
import type { NotificationsOverviewSchema } from '@shape-construction/api/model';
import { Button } from '@shape-construction/arch-ui';
import { CheckCircleIcon } from '@shape-construction/arch-ui/src/Icons/solid';
import { useQuery } from '@tanstack/react-query';
import {
  getNotificationsOverviewQueryOptions,
  useNotificationMarkReadAllMutation,
} from 'app/queries/notifications/notifications';
import React from 'react';

export const MarkAllNotificationRead = () => {
  const messages = useMessageGetter('notifications.page');

  const { data: notificationsOverview } = useQuery(getNotificationsOverviewQueryOptions());
  const { mutate: onMarkAllNotificationRead } = useNotificationMarkReadAllMutation();

  const hasNotifications =
    notificationsOverview && (notificationsOverview as NotificationsOverviewSchema)?.totalUnread > 0;

  if (!hasNotifications) return null;

  return (
    <Button
      variant="text"
      size="sm"
      color="primary"
      onClick={() => onMarkAllNotificationRead()}
      leadingIcon={CheckCircleIcon}
    >
      {messages('markReadCTA')}
    </Button>
  );
};
