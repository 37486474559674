import { useMessageGetter } from '@messageformat/react';
import Offline from '@shape-construction/arch-ui/src/Overlays/Illustrations/Offline';
import { NotificationModal } from '@shape-construction/arch-ui/src/Overlays/NotificationModal/NotificationModal';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { type APIError, removeFirst, selectFirstError } from '../../../store/errors/errorSlice';

export const ErrorNotificationModal = () => {
  const dispatch = useDispatch();
  const messages = useMessageGetter('notification.error');
  const error = useSelector(selectFirstError);
  const errorMessage = (error as APIError)?.response?.data?.errorDescription ?? (error as Error)?.message;

  const onClose = () => dispatch(removeFirst());

  if (!error) return null;

  return (
    <div className="w-full">
      <NotificationModal
        isOpen
        preTitleContent={<Offline />}
        title={messages('title')}
        subTitle={messages('subTitle', { message: errorMessage })}
        actionText={messages('acknowledgeCTA')}
        onClose={onClose}
      />
    </div>
  );
};
