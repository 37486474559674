import { useMessage } from '@messageformat/react';
import {
  getApiProjectsProjectIdIssues,
  getGetApiProjectsProjectIdIssuesGroupCountQueryOptions,
  getGetApiProjectsProjectIdIssuesInfiniteQueryOptions,
  getGetApiProjectsProjectIdIssuesIssueIdQueryKey,
  getGetApiProjectsProjectIdIssuesIssueIdQueryOptions,
  getGetApiProjectsProjectIdIssuesIssueIdVisitQueryKey,
  getGetApiProjectsProjectIdIssuesQueryKey,
  postApiProjectsProjectIdIssues,
  deleteApiProjectsProjectIdIssuesIssueIdWatchings as unwatchIssue,
  useDeleteApiProjectsProjectIdIssuesIssueId,
  useGetApiProjectsProjectIdIssues,
  useGetApiProjectsProjectIdIssuesIssueId,
  useGetApiProjectsProjectIdIssuesIssueIdVisit,
  usePatchApiProjectsProjectIdIssuesIssueId,
  usePostApiProjectsProjectIdIssuesExport,
  usePostApiProjectsProjectIdIssuesIssueIdArchive,
  usePostApiProjectsProjectIdIssuesIssueIdRestore,
  usePostApiProjectsProjectIdIssuesIssueIdSubmit,
  usePostApiProjectsProjectIdIssuesIssueIdUpdateImpact,
  usePostApiProjectsProjectIdIssuesIssueIdVisit,
  postApiProjectsProjectIdIssuesIssueIdWatchings as watchIssue,
} from '@shape-construction/api/api';
import type {
  IssueBasicDetailsSchema,
  IssueSchema,
  IssueVisitSchema,
  OffsetPaginationSchemaMeta,
  PostApiProjectsProjectIdIssuesBody,
  ProjectSchema,
} from '@shape-construction/api/model';
import { showSuccessToast } from '@shape-construction/arch-ui/src/Toast/toasts';
import { type UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import UtilsStats from 'app/components/Utils/UtilsStats';
import type { IssueImageUpload } from 'app/localData/issueImages';
import { type Issue, buildIssue, createIssue, deleteIssue } from 'app/localData/issues';
import { builderIssue } from 'app/store/issues/issues-action-creators';
import type { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import { IDEMPOTENCY_KEY_HEADER } from '../constants';
import { retryOnNetworkOrServerErrors } from '../utils';
import { getInfinityIssueFeedQueryOptions } from './feed/feed';
import { useUploadIssueImage } from './images/images';

export type IssuesIndex = {
  issues: IssueBasicDetailsSchema[];
  meta: OffsetPaginationSchemaMeta;
};

// queries
export const getProjectIssuesInfiniteQueryOptions: typeof getGetApiProjectsProjectIdIssuesInfiniteQueryOptions = (
  ...args
) => {
  const [projectId, params, options] = args;

  return getGetApiProjectsProjectIdIssuesInfiniteQueryOptions(projectId, params, {
    query: {
      ...options?.query,
      queryFn: ({ pageParam, signal }) =>
        getApiProjectsProjectIdIssues(
          projectId,
          {
            ...params,
            // @ts-expect-error
            ...pageParam,
          },
          options?.request,
          signal
        ),
      getNextPageParam: ({ meta }) =>
        meta.currentPage !== meta.totalPages ? { page: meta.currentPage + 1 } : undefined,
      initialPageParam: { page: undefined as undefined | string },
      refetchInterval: 0,
      refetchOnWindowFocus: false,
    },
  });
};

export const getProjectIssuesGroupQueryOptions = getGetApiProjectsProjectIdIssuesGroupCountQueryOptions;

export const useProjectIssues = useGetApiProjectsProjectIdIssues;

/**
 * @deprecated use getProjectIssueQueryOptions instead
 */
export const useProjectIssue = useGetApiProjectsProjectIdIssuesIssueId;
export const getProjectIssueQueryOptions = getGetApiProjectsProjectIdIssuesIssueIdQueryOptions;

// mutations
type CreateIssueParameters = PostApiProjectsProjectIdIssuesBody['issue'] & {
  projectId: ProjectSchema['id'];
  images?: IssueImageUpload[];
  idempotencyKey?: string;
};
export const useCreateIssueOptions = (): UseMutationOptions<IssueSchema, AxiosError, CreateIssueParameters, Issue> => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { mutate: uploadImage } = useUploadIssueImage();
  const uploadSuccessMessage = useMessage('issue.uploadSync.status.success');

  return {
    mutationKey: ['createIssue'],
    mutationFn: ({ projectId, idempotencyKey, ...issue }: CreateIssueParameters) =>
      postApiProjectsProjectIdIssues(
        projectId,
        { issue },
        { headers: idempotencyKey ? { [IDEMPOTENCY_KEY_HEADER]: idempotencyKey } : {} }
      ),
    onMutate: ({
      projectId,
      ...issue
    }: PostApiProjectsProjectIdIssuesBody['issue'] & {
      projectId: ProjectSchema['id'];
    }) => {
      const temporaryIssue = buildIssue(issue, projectId);

      createIssue(temporaryIssue);

      return temporaryIssue;
    },
    onSuccess: (issue: IssueSchema, variables, context) => {
      showSuccessToast({
        message: uploadSuccessMessage,
        alignContent: 'start',
        id: 'upload-issue-success',
      });
      dispatch(builderIssue(issue.id));
      queryClient.invalidateQueries({
        queryKey: getGetApiProjectsProjectIdIssuesQueryKey(issue.projectId),
      });
      deleteIssue(context!.id);

      variables.images?.forEach((image) =>
        uploadImage({
          file: image.originalFile!,
          kind: 'image' as const,
          issueId: issue.id,
          projectId: issue.projectId,
          temporaryId: UtilsStats.uuidv4(),
        })
      );
    },

    retry: (_, error) => retryOnNetworkOrServerErrors(error),
  };
};

export const useCreateIssue = () => {
  return useMutation(useCreateIssueOptions());
};

export const useQuickCreateIssue = () => {
  const queryClient = useQueryClient();
  const { mutate: uploadImage } = useUploadIssueImage();

  return useMutation({
    mutationKey: ['createIssue'],
    mutationFn: ({ projectId, idempotencyKey, ...issue }: CreateIssueParameters) =>
      postApiProjectsProjectIdIssues(
        projectId,
        { issue },
        { headers: idempotencyKey ? { [IDEMPOTENCY_KEY_HEADER]: idempotencyKey } : {} }
      ),
    onMutate: ({
      projectId,
      images,
      ...issue
    }: CreateIssueParameters & {
      projectId: ProjectSchema['id'];
    }) => {
      const temporaryIssue = buildIssue(issue, projectId);

      createIssue(temporaryIssue);

      return temporaryIssue;
    },
    onSuccess: (issue: IssueSchema, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: getGetApiProjectsProjectIdIssuesQueryKey(issue.projectId),
      });
      deleteIssue(context!.id);

      variables.images?.forEach((image) =>
        uploadImage({
          file: image.originalFile!,
          kind: 'image' as const,
          issueId: issue.id,
          projectId: issue.projectId,
          temporaryId: UtilsStats.uuidv4(),
        })
      );
    },
    retry: (_, error) => retryOnNetworkOrServerErrors(error as AxiosError),
  });
};

export const useUpdateIssue: typeof usePatchApiProjectsProjectIdIssuesIssueId = () => {
  const queryClient = useQueryClient();

  return usePatchApiProjectsProjectIdIssuesIssueId({
    mutation: {
      onSuccess: (issue) => {
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdIssuesQueryKey(issue.projectId),
        });
        queryClient.setQueryData<IssueSchema>(
          getGetApiProjectsProjectIdIssuesIssueIdQueryKey(issue.projectId, issue.id),
          issue
        );
      },
    },
  });
};

export const useUpdateIssueImpact = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdIssuesIssueIdUpdateImpact({
    mutation: {
      onSuccess: (issue, { projectId, issueId }) => {
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdIssuesQueryKey(issue.projectId),
        });
        queryClient.setQueryData<IssueSchema>(
          getGetApiProjectsProjectIdIssuesIssueIdQueryKey(projectId, issueId),
          issue
        );
      },
    },
  });
};

export const useIssueVisit = useGetApiProjectsProjectIdIssuesIssueIdVisit;

export const useVisitIssue = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdIssuesIssueIdVisit({
    mutation: {
      onSuccess: (visit, { projectId, issueId }) => {
        queryClient.setQueryData<IssueVisitSchema>(
          getGetApiProjectsProjectIdIssuesIssueIdVisitQueryKey(projectId, issueId),
          visit
        );
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdIssuesQueryKey(projectId),
        });
      },
    },
  });
};

export const useDeleteIssue = () => {
  const queryClient = useQueryClient();

  return useDeleteApiProjectsProjectIdIssuesIssueId({
    mutation: {
      onSuccess: (_, { projectId }) => {
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdIssuesQueryKey(projectId),
        });
      },
    },
  });
};

export const useIssueSubmit = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdIssuesIssueIdSubmit({
    mutation: {
      onSuccess: (issue, { projectId }) => {
        queryClient.setQueryData<IssueSchema>(
          getGetApiProjectsProjectIdIssuesIssueIdQueryKey(projectId, issue.id),
          issue
        );
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdIssuesQueryKey(projectId),
        });
      },
    },
  });
};

export const useIssueArchive = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdIssuesIssueIdArchive({
    mutation: {
      onSuccess: (issue, { projectId, issueId }) => {
        queryClient.setQueryData<IssueSchema>(
          getGetApiProjectsProjectIdIssuesIssueIdQueryKey(projectId, issueId),
          issue
        );
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdIssuesQueryKey(projectId),
        });
        queryClient.invalidateQueries(getInfinityIssueFeedQueryOptions('public', projectId, issueId));
      },
    },
  });
};

export const useIssueRestore = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdIssuesIssueIdRestore({
    mutation: {
      onSuccess: (issue, { projectId, issueId }) => {
        queryClient.setQueryData<IssueSchema>(
          getGetApiProjectsProjectIdIssuesIssueIdQueryKey(projectId, issueId),
          issue
        );
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdIssuesQueryKey(projectId),
        });
        queryClient.invalidateQueries(getInfinityIssueFeedQueryOptions('public', projectId, issueId));
      },
    },
  });
};

export const useIssueWatch = (projectId: ProjectSchema['id'], issueId: IssueSchema['id']) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (isWatching: boolean) => {
      const method = isWatching ? watchIssue : unwatchIssue;
      return method(projectId, issueId);
    },
    onSuccess: (_, isWatching) => {
      queryClient.setQueryData<IssueSchema | undefined>(
        getGetApiProjectsProjectIdIssuesIssueIdQueryKey(projectId, issueId),
        (issue) => {
          if (!issue) return undefined;

          return {
            ...issue,
            isWatching,
          };
        }
      );
      queryClient.invalidateQueries({
        queryKey: getGetApiProjectsProjectIdIssuesQueryKey(projectId),
      });
    },
  });
};

export const useIssuesExport = usePostApiProjectsProjectIdIssuesExport;
