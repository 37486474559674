import React, { type ComponentProps } from 'react';
import { Waypoint } from 'react-waypoint';

export type InfiniteLoadWaypointsProps = {
  children: React.ReactNode;
  scrollableAncestor?: ComponentProps<typeof Waypoint>['scrollableAncestor'];
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  fetchNext?: (...args: any[]) => any;
  fetchPrevious?: (...args: any[]) => any;
  isFirst?: boolean;
  isLast?: boolean;
};

export const InfiniteLoadWaypoints: React.FC<InfiniteLoadWaypointsProps> = ({
  children,
  isFirst,
  fetchNext,
  fetchPrevious,
  hasNextPage,
  hasPreviousPage,
  isLast,
  scrollableAncestor,
}) => {
  if (hasPreviousPage && isFirst) {
    return (
      <Waypoint scrollableAncestor={scrollableAncestor} onEnter={() => fetchPrevious?.()}>
        <div data-testid="bottom-waypoint">{children}</div>
      </Waypoint>
    );
  }

  if (hasNextPage && isLast) {
    return (
      <Waypoint scrollableAncestor={scrollableAncestor} onEnter={() => fetchNext?.()}>
        <div data-testid="top-waypoint">{children}</div>
      </Waypoint>
    );
  }

  return <>{children}</>;
};
