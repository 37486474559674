import { parseDateWithFormat } from '@shape-construction/utils/DateTime';
import React, { type PropsWithChildren } from 'react';
import { type CaptionProps, DayPicker, type DayPickerProps, useNavigation } from 'react-day-picker';
import { IconButton } from '../Button';
import { ArrowLeftIcon, ArrowRightIcon } from '../Icons/solid';
import 'react-day-picker/dist/style.css';
import './DatePickerCalendar.css';

export type {
  DateRange,
  DayPickerProps,
  DayPickerRangeProps,
  DayPickerSingleProps,
} from 'react-day-picker';

const Caption = ({ children, displayMonth }: PropsWithChildren<CaptionProps>) => {
  const { goToMonth, nextMonth, previousMonth } = useNavigation();
  return (
    <div className="flex items-center justify-between">
      <IconButton
        disabled={!previousMonth}
        aria-label="Previous"
        color="secondary"
        variant="text"
        icon={ArrowLeftIcon}
        size="xxs"
        onClick={() => previousMonth && goToMonth(previousMonth)}
      />
      {children || parseDateWithFormat(displayMonth, 'MMMM YYYY')}
      <IconButton
        disabled={!nextMonth}
        aria-label="Next"
        color="secondary"
        variant="text"
        icon={ArrowRightIcon}
        size="xxs"
        onClick={() => nextMonth && goToMonth(nextMonth)}
      />
    </div>
  );
};

export const DatePickerCalendar: React.FC<DayPickerProps> = ({ components, ...props }) => (
  <DayPicker
    {...props}
    components={{
      Caption,
      ...components,
    }}
  />
);

DatePickerCalendar.displayName = 'DatePickerCalendar';
