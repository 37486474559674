import {
  getGetApiProjectsProjectIdCustomFieldsQueryKey,
  getGetApiProjectsProjectIdCustomFieldsQueryOptions,
  useDeleteApiProjectsProjectIdCustomFieldsCustomFieldId,
  useGetApiProjectsProjectIdCustomFields,
  usePatchApiProjectsProjectIdCustomFieldsCustomFieldId,
  usePostApiProjectsProjectIdCustomFields,
} from '@shape-construction/api/api';
import type { CustomFieldListSchema } from '@shape-construction/api/model';
import { useQueryClient } from '@tanstack/react-query';

// queries
/**
 * @deprecated use getProjectCustomFieldsQueryOptions instead
 */
export const useProjectCustomFields = useGetApiProjectsProjectIdCustomFields;
export const getProjectCustomFieldsQueryOptions = getGetApiProjectsProjectIdCustomFieldsQueryOptions;

// mutations
export const useCreateCustomField = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdCustomFields({
    mutation: {
      onSuccess: (newCustomField, { projectId }) => {
        const oldCustomFieldsData = queryClient.getQueryData<CustomFieldListSchema>(
          getGetApiProjectsProjectIdCustomFieldsQueryKey(projectId)
        );

        queryClient.setQueryData(getGetApiProjectsProjectIdCustomFieldsQueryKey(projectId), {
          ...oldCustomFieldsData,
          customFields: [newCustomField, ...(oldCustomFieldsData?.customFields || [])],
        });
      },
    },
  });
};

export const useEditCustomField = () => {
  const queryClient = useQueryClient();

  return usePatchApiProjectsProjectIdCustomFieldsCustomFieldId({
    mutation: {
      onSuccess: (updatedCustomField, { projectId, customFieldId }) => {
        const oldCustomFieldsData = queryClient.getQueryData<CustomFieldListSchema>(
          getGetApiProjectsProjectIdCustomFieldsQueryKey(projectId)
        );

        queryClient.setQueryData(getGetApiProjectsProjectIdCustomFieldsQueryKey(projectId), {
          ...oldCustomFieldsData,
          customFields: oldCustomFieldsData?.customFields.map((customField) => {
            if (customField.id === customFieldId) {
              return updatedCustomField;
            }

            return customField;
          }),
        });
      },
    },
  });
};

export const useDeleteCustomField = () => {
  const queryClient = useQueryClient();

  return useDeleteApiProjectsProjectIdCustomFieldsCustomFieldId({
    mutation: {
      onSuccess: (_, { projectId, customFieldId }) => {
        const oldCustomFieldsData = queryClient.getQueryData<CustomFieldListSchema>(
          getGetApiProjectsProjectIdCustomFieldsQueryKey(projectId)
        );

        queryClient.setQueryData(getGetApiProjectsProjectIdCustomFieldsQueryKey(projectId), {
          ...oldCustomFieldsData,
          customFields: oldCustomFieldsData?.customFields.filter((customField) => customField.id !== customFieldId),
        });
      },
    },
  });
};
