import React, { type ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type ContentProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
  children: ReactNode;
};

export const CollapsableCardContent: React.FC<ContentProps> = ({ className, children, ...props }) => (
  <div className={twMerge('px-4 pb-4', className)} {...props}>
    {children}
  </div>
);
