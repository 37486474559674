import * as React from 'react';
import type { SVGProps } from 'react';
const MovIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={44} viewBox="0 0 40 44" fill="none" aria-hidden="true" {...props}>
    <g id="file-icon/mov">
      <g id="Vector" filter="url(#filter0_dd_3816_37980)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6776 41C27.0141 41 13.7443 41 7.32668 41C5.85393 41 4.66667 39.82 4.66667 38.3473C4.66667 30.3468 4.66667 10.7752 4.66667 3.6498C4.66667 2.17704 5.86057 1 7.33333 1H26.2222Z"
          fill="#3B82F6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6776 41C27.0141 41 13.7443 41 7.32668 41C5.85393 41 4.66667 39.82 4.66667 38.3473C4.66667 30.3468 4.66667 10.7752 4.66667 3.6498C4.66667 2.17704 5.86057 1 7.33333 1H26.2222Z"
          fill="url(#paint0_radial_3816_37980)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L26.2222 7.44444C26.2222 8.9172 27.4161 10.1111 28.8889 10.1111L35.3333 10.1111L26.2222 1Z"
          fill="white"
          fillOpacity={0.3}
        />
      </g>
      <path
        id="Vector_2"
        d="M20.2282 15.4445C15.9333 15.4445 12.4444 18.9333 12.4444 23.2282C12.4444 27.5232 15.9333 31 20.2282 31C24.5232 31 28 27.5232 28 23.2282C28 18.9333 24.5111 15.4565 20.2282 15.4445ZM23.5006 23.4689C23.4404 23.5771 23.3562 23.6614 23.2479 23.7215L18.8086 25.9472C18.5319 26.0795 18.1951 25.9712 18.0627 25.6945C18.0146 25.6223 18.0026 25.5381 18.0026 25.4419V21.0026C18.0026 20.6898 18.2552 20.4492 18.556 20.4492C18.6402 20.4492 18.7244 20.4612 18.8086 20.5093L23.2479 22.723C23.5246 22.8673 23.6329 23.1922 23.5006 23.4689Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_3816_37980"
        x={2.66667}
        y={0}
        width={34.6667}
        height={44}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3816_37980" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_3816_37980" result="effect2_dropShadow_3816_37980" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3816_37980" result="shape" />
      </filter>
      <radialGradient
        id="paint0_radial_3816_37980"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20 0.999999) rotate(90) scale(40 40.1825)"
      >
        <stop offset={0.297418} stopOpacity={0} />
        <stop offset={1} stopOpacity={0.08} />
      </radialGradient>
    </defs>
  </svg>
);
export default MovIcon;
