import { useMessageGetter } from '@messageformat/react';
import { MagnifyingGlassIcon } from '@shape-construction/arch-ui/src/Icons/outline';
import React from 'react';

export const NoSearchResults: React.FC<{ children?: React.ReactElement }> = ({ children }) => {
  const noSearchResultsMessage = useMessageGetter('search.noResults');

  return (
    <div className="flex w-full flex-col items-center gap-y-2 mb-6">
      <MagnifyingGlassIcon className="h-12 w-12 text-gray-400" />
      <p className="text-sm font-medium text-gray-900">{noSearchResultsMessage('title')}</p>
      <p className="text-sm text-gray-500">{noSearchResultsMessage('subTitle')}</p>
      {children}
    </div>
  );
};
