import type { IssueViewFilterItemSchemaName } from '@shape-construction/api/model';
import type { IssuesFilterFormValues, IssuesFilters, IssuesFiltersFormDateKeys } from './types/types';

export const emptyFilters: IssuesFilters = {
  impact: [],
  filter_state: [],
  category: [],
  sub_category: [],
  discipline: [],
  location: [],
  critical: null,
  observer: [],
  responsible_team: [],
  responsible: [],
  published_date: null,
  due_date: null,
  updated_date: null,
  planned_closure_date: null,
  closed_date: null,
  visibility_status: null,
  group_by: null,
  group_properties: [],
  sort_by: null,
  sort_order: null,
};

export const issuesFiltersFormKeys: (keyof IssuesFilterFormValues)[] = [
  'published_date',
  'due_date',
  'updated_date',
  'planned_closure_date',
  'closed_date',
  'filter_state',
  'category',
  'sub_category',
  'discipline',
  'location',
  'impact',
  'critical',
  'observer',
  'responsible_team',
  'responsible',
  'visibility_status',
];

export const issuesFiltersFormDateKeys: IssuesFiltersFormDateKeys[] = [
  'published_date',
  'due_date',
  'updated_date',
  'planned_closure_date',
  'closed_date',
];

export const ISSUES_FILTERS_KEY = 'issues_filters';

export const dateFilterToViewPropertyMapping: Record<
  IssuesFiltersFormDateKeys,
  {
    start: IssueViewFilterItemSchemaName;
    end: IssueViewFilterItemSchemaName;
    relative: IssueViewFilterItemSchemaName;
  }
> = {
  published_date: {
    start: 'published_date_start',
    end: 'published_date_end',
    relative: 'published_date_relative',
  },
  due_date: {
    start: 'due_date_start',
    end: 'due_date_end',
    relative: 'due_date_relative',
  },
  updated_date: {
    start: 'updated_date_start',
    end: 'updated_date_end',
    relative: 'updated_date_relative',
  },
  planned_closure_date: {
    start: 'planned_closure_date_start',
    end: 'planned_closure_date_end',
    relative: 'planned_closure_date_relative',
  },
  closed_date: {
    start: 'closed_date_start',
    end: 'closed_date_end',
    relative: 'closed_date_relative',
  },
};
