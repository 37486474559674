import { useMessageGetter } from '@messageformat/react';
import { Button, Popover } from '@shape-construction/arch-ui';
import type { PopoverContentProps } from '@shape-construction/arch-ui/src/Popover/PopoverContent/Content';
import React from 'react';
import { useProductTourPopover } from '../hooks/useProductTourPopover';

export type ProductTourPopoverProps = PopoverContentProps;

export const ProductTourPopover: React.FC<ProductTourPopoverProps> = ({ ...popoverProps }) => {
  const popoverMessages = useMessageGetter('productTour.popover');
  const { closeProductTourPopover } = useProductTourPopover();

  return (
    <Popover.Root open>
      <Popover.Trigger className="absolute w-8 h-8" />

      <Popover.Content color="discovery" onClose={closeProductTourPopover} sideOffset={6} {...popoverProps}>
        <Popover.Content.Heading>{popoverMessages('title')}</Popover.Content.Heading>
        <Popover.Content.Body>{popoverMessages('description')}</Popover.Content.Body>
        <Popover.Content.Footer>
          <Button color="white" variant="contained" size="sm" onClick={closeProductTourPopover}>
            {popoverMessages('gotItCTA')}
          </Button>
        </Popover.Content.Footer>
      </Popover.Content>
    </Popover.Root>
  );
};
