import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import type { ContextState, ILayoutConfig } from './layout.types';
import { LayoutConfigs } from './layoutConfigs';

const LAYOUT_ATOM_KEY = 'LAYOUT';
const storageLayoutAtom = atomWithStorage<{ drawerExpanded: boolean } | null>(LAYOUT_ATOM_KEY, null);

export const LayoutContext = createContext<ContextState>({
  drawerExpanded: false,
  drawerOpen: true,
  layoutConfig: LayoutConfigs.initialVariant,
  closeDrawer: () => {},
  openDrawer: () => {},
  setLayoutConfig: () => {},
  setDrawerExpanded: () => {},
});

export interface LayoutContextProviderProps {
  children: React.ReactNode;
  layoutConfig?: ILayoutConfig;
  drawerOpen?: boolean;
  drawerExpanded?: boolean;
}

export const LayoutProvider: React.FC<LayoutContextProviderProps> = ({
  children,
  drawerOpen: drawerOpenProp = false,
  layoutConfig: layoutConfigProp = LayoutConfigs.initialVariant,
  drawerExpanded = true,
}) => {
  const [storageLayout, setStorageLayout] = useAtom(storageLayoutAtom);
  const [layoutConfig, setLayoutConfig] = useState<ILayoutConfig>(layoutConfigProp);
  const [drawerOpen, setDrawerOpen] = useState(drawerOpenProp);
  const currentDrawerExpanded = storageLayout?.drawerExpanded ?? drawerExpanded;

  const openDrawer = useCallback(() => {
    setDrawerOpen(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setDrawerOpen(false);
  }, []);

  const setDrawerExpanded = useCallback(
    (drawerExpanded: boolean) => {
      setStorageLayout({ drawerExpanded });
    },
    [setStorageLayout]
  );

  const values = useMemo(
    () => ({
      setLayoutConfig,
      layoutConfig,
      drawerOpen,
      openDrawer,
      closeDrawer,
      setDrawerExpanded,
      drawerExpanded: currentDrawerExpanded,
    }),
    [closeDrawer, drawerOpen, layoutConfig, storageLayout, openDrawer, setDrawerExpanded]
  );

  return <LayoutContext.Provider value={values}>{children}</LayoutContext.Provider>;
};

export const useLayoutContext = () => useContext(LayoutContext);
