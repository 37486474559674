import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import React, { type ComponentProps } from 'react';
import { DraggableItemIdCtx } from './context';

export type DraggableListItemsProps<T extends { id: string }> = Omit<
  ComponentProps<typeof SortableContext>,
  'items' | 'children'
> & {
  items: T[];
  children: (item: T, index: number) => React.ReactElement;
};

export const DraggableListItems = <T extends { id: string }>({
  items,
  children,
  ...sortableProps
}: DraggableListItemsProps<T>) => {
  return (
    <SortableContext strategy={verticalListSortingStrategy} {...sortableProps} items={items}>
      {items.map((item, index) => (
        <DraggableItemIdCtx.Provider value={item.id} key={item.id}>
          {children(item, index)}
        </DraggableItemIdCtx.Provider>
      ))}
    </SortableContext>
  );
};

DraggableListItems.displayName = 'DraggableList.Items';
