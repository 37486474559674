import { yupResolver } from '@hookform/resolvers/yup';
import { useMessageGetter } from '@messageformat/react';
import { Button, InputTextArea } from '@shape-construction/arch-ui';
import { ModalBase as Modal } from '@shape-construction/arch-ui/src/ModalBase';
import { breakpoints } from '@shape-construction/arch-ui/src/utils/breakpoints';
import { useMediaQuery } from '@shape-construction/hooks';
import { environment } from 'app/config/environment';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

export type FeedbackFormValues = {
  likeText: string;
  improvementText: string;
};

const feedbackFormSchema: Yup.SchemaOf<FeedbackFormValues> = Yup.object({
  likeText: Yup.string().required('Required field'),
  improvementText: Yup.string().required('Required field'),
});

type FeedbackFormProps = {
  onClose: () => void;
  onSubmit: (feedbackFormValues: FeedbackFormValues) => void;
};

export const FeedbackForm: React.FC<FeedbackFormProps> = ({ onClose, onSubmit }) => {
  const feedbackFormMessages = useMessageGetter('feedbackForm');
  const isSmallScreen = useMediaQuery(breakpoints.down('md'));
  const supportEmail = environment.SUPPORT_EMAIL;

  const form = useForm<FeedbackFormValues>({
    mode: 'onChange',
    defaultValues: {
      likeText: '',
      improvementText: '',
    },
    resolver: yupResolver(feedbackFormSchema),
  });
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = form;

  return (
    <Modal open fullScreen={isSmallScreen} onClose={onClose} roundBorders={!isSmallScreen}>
      <Modal.Header className="mb-6 min-h-fit" bottomBorder onClose={onClose}>
        <Modal.Title>{feedbackFormMessages('title')}</Modal.Title>
        <Modal.SubTitle>
          {feedbackFormMessages('supportMessage')}{' '}
          <a
            href={`mailto:${supportEmail}`}
            target="_blank"
            rel="noreferrer"
            className="font-medium text-indigo-500 hover:text-indigo-400"
          >
            {supportEmail}
          </a>
          .
        </Modal.SubTitle>
      </Modal.Header>
      <Modal.Content className="flex gap-4 py-1">
        <InputTextArea rows={6} {...register('likeText')} label={feedbackFormMessages('likeQuestion.question')} />
        <InputTextArea
          rows={6}
          {...register('improvementText')}
          label={feedbackFormMessages('improveQuestion.question')}
        />
      </Modal.Content>
      <Modal.Footer topBorder className="mt-8 space-x-4">
        <Button
          color="secondary"
          variant="outlined"
          size="md"
          aria-label={feedbackFormMessages('cancelCTA')}
          onClick={onClose}
        >
          {feedbackFormMessages('cancelCTA')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          size="md"
          disabled={!isValid}
          aria-label={feedbackFormMessages('submitCTA')}
          onClick={handleSubmit(onSubmit)}
          type="submit"
          data-cy="submit-feedback"
        >
          {feedbackFormMessages('submitCTA')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
