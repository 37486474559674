import * as React from 'react';
import type { SVGProps } from 'react';
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={44} viewBox="0 0 40 44" fill="none" aria-hidden="true" {...props}>
    <g id="file-icon/svg">
      <g id="Vector" filter="url(#filter0_dd_3816_38082)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6776 41C27.014 41 13.7442 41 7.32664 41C5.85388 41 4.66663 39.82 4.66663 38.3473C4.66663 30.3468 4.66663 10.7752 4.66663 3.6498C4.66663 2.17704 5.86053 1 7.33329 1H26.2222Z"
          fill="#F97316"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6776 41C27.014 41 13.7442 41 7.32664 41C5.85388 41 4.66663 39.82 4.66663 38.3473C4.66663 30.3468 4.66663 10.7752 4.66663 3.6498C4.66663 2.17704 5.86053 1 7.33329 1H26.2222Z"
          fill="url(#paint0_radial_3816_38082)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L26.2222 7.44444C26.2222 8.9172 27.4161 10.1111 28.8888 10.1111L35.3333 10.1111L26.2222 1Z"
          fill="white"
          fillOpacity={0.3}
        />
      </g>
      <path
        id="Vector_2"
        d="M28.83 26.1651H28.2548C28.0631 23.38 26.4586 20.9179 24.1277 19.5657H26.1761C26.4082 20.2014 27.0136 20.6656 27.7301 20.6656C28.6382 20.6656 29.3749 19.9189 29.3749 19.0107C29.3749 18.1025 28.6382 17.3558 27.7301 17.3558C27.0136 17.3558 26.4082 17.82 26.1761 18.4557H21.6756V17.9108C21.6756 17.6081 21.4234 17.3558 21.1206 17.3558H18.9209C18.6181 17.3558 18.376 17.6081 18.376 17.9108V18.4557H13.8654C13.6434 17.82 13.038 17.3558 12.3215 17.3558C11.4133 17.3558 10.6666 18.1025 10.6666 19.0107C10.6666 19.9189 11.4133 20.6656 12.3215 20.6656C13.038 20.6656 13.6434 20.2014 13.8654 19.5657H15.9239C13.5829 20.9179 11.9784 23.38 11.7968 26.1651H11.2216C10.9189 26.1651 10.6666 26.4072 10.6666 26.71V28.9198C10.6666 29.2225 10.9189 29.4647 11.2216 29.4647H13.4214C13.7241 29.4647 13.9663 29.2225 13.9663 28.9198V26.71C13.9663 26.4072 13.7241 26.1651 13.4214 26.1651H12.9169C13.159 23.0874 15.379 20.4941 18.376 19.7877V20.1106C18.376 20.4133 18.6181 20.6656 18.9209 20.6656H21.1206C21.4234 20.6656 21.6756 20.4133 21.6756 20.1106V19.7877C24.6625 20.4941 26.8825 23.0874 27.1246 26.1651H26.6302C26.3174 26.1651 26.0752 26.4072 26.0752 26.71V28.9198C26.0752 29.2225 26.3174 29.4647 26.6302 29.4647H28.83C29.1327 29.4647 29.3749 29.2225 29.3749 28.9198V26.71C29.3749 26.4072 29.1327 26.1651 28.83 26.1651Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_3816_38082"
        x={2.66663}
        y={0}
        width={34.6666}
        height={44}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3816_38082" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_3816_38082" result="effect2_dropShadow_3816_38082" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3816_38082" result="shape" />
      </filter>
      <radialGradient
        id="paint0_radial_3816_38082"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20 0.999999) rotate(90) scale(40 40.1825)"
      >
        <stop offset={0.297418} stopOpacity={0} />
        <stop offset={1} stopOpacity={0.08} />
      </radialGradient>
    </defs>
  </svg>
);
export default SvgIcon;
