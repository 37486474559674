import React, { type ComponentProps } from 'react';

export type InputFileProps = Omit<ComponentProps<'input'>, 'type'> & {
  label?: string;
  startAdornment?: JSX.Element;
};

export const InputFile = React.forwardRef<HTMLInputElement, InputFileProps>(
  ({ id, label, startAdornment, ...inputProps }, ref) => {
    return (
      <label htmlFor={id} className="cursor-pointer flex flex-row items-center gap-2">
        {startAdornment && startAdornment}
        {label && <span className="text-center font-medium">{label}</span>}
        <input className="hidden" multiple {...inputProps} ref={ref} id={id} type="file" />
      </label>
    );
  }
);

InputFile.displayName = 'InputFile';
