import React from 'react';
import { ModalBase } from '../../ModalBase';
import type { ModalBaseFooterProps } from '../../ModalBase/components/ModalBaseFooter';

export type FooterHeaderProps = Omit<ModalBaseFooterProps, 'topBorder'>;

export const ModalFooter: React.FC<FooterHeaderProps> = (props) => {
  return (
    <ModalBase.Footer topBorder {...props}>
      {props.children}
    </ModalBase.Footer>
  );
};
