import { combineReducers } from 'redux';
import { getUserConsent, isPushNotificationSupported } from '../../lib/push-notifications';
import * as actionTypes from '../actions/actionTypes';

const USER_CONSENT_STATE = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  CLOSED: 'closed',
  DENIED: 'denied',
};

const userConsentStatusInitialState = () => {
  const pushNotificationSupported = isPushNotificationSupported();
  const userConsent = getUserConsent();

  if (!pushNotificationSupported) return USER_CONSENT_STATE.CLOSED;
  if (userConsent === 'granted') return USER_CONSENT_STATE.CLOSED;
  if (userConsent === 'denied') return USER_CONSENT_STATE.DENIED;
  return USER_CONSENT_STATE.PENDING;
};

interface PermissionState {
  accepted: null | boolean;
  date: null | Date;
}

interface NotificationsState {
  permission: PermissionState;
  userConsentStatus: string;
}

const initialState: NotificationsState = {
  permission: {
    accepted: null,
    date: null,
  },
  userConsentStatus: userConsentStatusInitialState(),
};

const permission = (state = initialState.permission, { type }: any = {}) => {
  switch (type) {
    case actionTypes.NOTIFICATIONS_PERMISSIONS_ACCEPT:
      return { accepted: true, date: new Date() };
    case actionTypes.NOTIFICATIONS_PERMISSIONS_REJECT:
      return { accepted: false, date: new Date() };
    default:
      return state;
  }
};

const userConsentStatus = (state = initialState.userConsentStatus, { type }: any = {}) => {
  switch (type) {
    case actionTypes.USER_CONSENT_ACCEPT:
      return USER_CONSENT_STATE.ACCEPTED;
    case actionTypes.USER_CONSENT_CLOSE:
      return USER_CONSENT_STATE.CLOSED;
    case actionTypes.USER_CONSENT_DENY:
      return USER_CONSENT_STATE.DENIED;
    default:
      return state;
  }
};

export const notificationsReducer = combineReducers({
  permission,
  userConsentStatus,
});
