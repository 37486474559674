import {
  DndContext,
  type DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import type { SortableData } from '@dnd-kit/sortable';
import React, { type ComponentProps } from 'react';

export type DraggableListRootProps = Omit<ComponentProps<typeof DndContext>, 'onDragEnd'> & {
  onDrop: (fromIndex: number, toIndex: number, event: DragEndEvent) => void;
};

export const DraggableListRoot: React.FC<DraggableListRootProps> = ({ onDrop, children, ...dndProps }) => {
  const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

  const handleDragEnd = (event: DragEndEvent) => {
    if (!event.over) return;

    const activeItem = event.active.data.current as SortableData;
    const targetItem = event.over.data.current as SortableData;
    const fromIndex = activeItem.sortable.index;
    const toIndex = targetItem.sortable.index;

    onDrop(fromIndex, toIndex, event);
  };

  return (
    <DndContext collisionDetection={closestCenter} sensors={sensors} {...dndProps} onDragEnd={handleDragEnd}>
      {children}
    </DndContext>
  );
};

DraggableListRoot.displayName = 'DraggableList.Root';
