import * as React from 'react';
import type { SVGProps } from 'react';
const ZipIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={44} viewBox="0 0 40 44" fill="none" aria-hidden="true" {...props}>
    <g id="file-icon/zip">
      <g id="Vector" filter="url(#filter0_dd_3816_38085)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6776 41C27.014 41 13.7442 41 7.32664 41C5.85388 41 4.66663 39.82 4.66663 38.3473C4.66663 30.3468 4.66663 10.7752 4.66663 3.6498C4.66663 2.17704 5.86053 1 7.33329 1H26.2222Z"
          fill="#FACC15"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6776 41C27.014 41 13.7442 41 7.32664 41C5.85388 41 4.66663 39.82 4.66663 38.3473C4.66663 30.3468 4.66663 10.7752 4.66663 3.6498C4.66663 2.17704 5.86053 1 7.33329 1H26.2222Z"
          fill="url(#paint0_radial_3816_38085)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L26.2222 7.44444C26.2222 8.9172 27.4161 10.1111 28.8888 10.1111L35.3333 10.1111L26.2222 1Z"
          fill="white"
          fillOpacity={0.3}
        />
      </g>
      <g id="Vector_2">
        <path
          d="M19.9999 27.0695C20.5369 27.0695 20.9722 26.6342 20.9722 26.0972C20.9722 25.5603 20.5369 25.125 19.9999 25.125C19.463 25.125 19.0277 25.5603 19.0277 26.0972C19.0277 26.6342 19.463 27.0695 19.9999 27.0695Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.1944 15.8889C12.6574 15.8889 12.2222 16.3242 12.2222 16.8611V30.4722C12.2222 31.0092 12.6574 31.4445 13.1944 31.4445H26.8055C27.3424 31.4445 27.7777 31.0092 27.7777 30.4722V16.8611C27.7777 16.3242 27.3424 15.8889 26.8055 15.8889H21.4583V17.3472H19.9999V15.8889H13.1944ZM19.9999 18.8056V17.3472H18.5416V18.8056H19.9999ZM19.9999 20.2639H21.4583V18.8056H19.9999V20.2639ZM19.9999 20.2639H18.5416V21.2361H18.5413L17.6171 25.6111H17.618C17.5861 25.7682 17.5694 25.9308 17.5694 26.0972C17.5694 27.4396 18.6576 28.5278 19.9999 28.5278C21.3423 28.5278 22.4305 27.4396 22.4305 26.0972C22.4305 25.903 22.4077 25.714 22.3646 25.5329L21.4579 21.2361H19.9999V20.2639Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_dd_3816_38085"
        x={2.66663}
        y={0}
        width={34.6666}
        height={44}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3816_38085" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_3816_38085" result="effect2_dropShadow_3816_38085" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3816_38085" result="shape" />
      </filter>
      <radialGradient
        id="paint0_radial_3816_38085"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20 0.999999) rotate(90) scale(40 40.1825)"
      >
        <stop offset={0.297418} stopOpacity={0} />
        <stop offset={1} stopOpacity={0.08} />
      </radialGradient>
    </defs>
  </svg>
);
export default ZipIcon;
