import { useDebounceCallback } from '@shape-construction/hooks';
import React, { type ComponentPropsWithoutRef, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { MagnifyingGlassIcon } from '../Icons/outline';

export type SearchFieldProps = ComponentPropsWithoutRef<'input'>;

export const SearchField = React.forwardRef<HTMLInputElement, SearchFieldProps>(
  ({ placeholder = 'Search', ...props }, ref) => {
    const [innerValue, setInnerValue] = useState('');

    // Debounced Callback Deferring the form update
    const debouncedHandleChange: React.ComponentProps<'input'>['onChange'] = useDebounceCallback(
      (event) => props.onChange?.(event),
      250
    );

    const handleChange: React.ComponentProps<'input'>['onChange'] = (event) => {
      event.persist();
      setInnerValue(event.target.value);
      debouncedHandleChange(event);
    };

    // https://github.com/react-hook-form/react-hook-form/issues/9756
    useEffect(() => {
      if (typeof props.value === 'string') setInnerValue(props.value);
    }, [props.value, props.name]);

    return (
      <div className="flex w-full">
        <div className="relative w-full text-gray-500 focus-within:text-gray-900">
          <div className="pointer-events-none absolute inset-y-0 left-2 flex items-center">
            <MagnifyingGlassIcon className="h-4 w-4" aria-hidden="true" />
          </div>
          <input
            {...props}
            className={twMerge(
              'h-full w-full rounded-md border-0 py-2 pl-9 pr-3 placeholder-gray-500 ring-0 focus:placeholder-gray-400 focus:ring-indigo-500 sm:text-sm',
              props.className
            )}
            ref={ref}
            value={innerValue}
            type="search"
            placeholder={placeholder}
            onChange={handleChange}
          />
        </div>
      </div>
    );
  }
);
SearchField.displayName = 'SearchField';
