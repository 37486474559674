import { useMessageGetter } from '@messageformat/react';
import { MagnifyingGlassIcon } from '@shape-construction/arch-ui/src/Icons/outline';
import React, { type PropsWithChildren } from 'react';

export const NoResultsPlaceholder = ({ children }: PropsWithChildren) => {
  const messages = useMessageGetter('projectGallery.noResultsPlaceholder');

  return (
    <section className="flex h-full flex-1 flex-col items-center justify-center px-4 self-center md:self-auto">
      <MagnifyingGlassIcon className="text-gray-400 w-12 h-12" />
      <h6 className="text-lg leading-6 font-medium text-gray-800 mt-4">{messages('title')}</h6>
      <p className="text-sm leading-5 font-normal text-gray-500 text-center mt-2 mb-6">{messages('subtitle')}</p>
      {children}
    </section>
  );
};
