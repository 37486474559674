import classNames from 'clsx';
import React, { type ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';

export type SelectOptionsProps = ComponentProps<'div'>;

export function SelectOptions({ className, ...props }: SelectOptionsProps) {
  return (
    <div {...props} className={twMerge(classNames('w-full flex flex-col overflow-y-auto'), className as string)} />
  );
}
SelectOptions.displayName = 'Select.Options';
