import type { NotificationTypeSchema } from '@shape-construction/api/model';
import type { NotificationSchema } from '@shape-construction/api/model';
import { NotificationCard } from '@shape-construction/arch-ui';
import { formatDateAndTime } from '@shape-construction/utils/DateTime';
import { TIMEZONE_DEFAULT } from 'app/constants/DateTime';
import { InAppNotificationDecorator } from 'app/constants/NotificationDecorator';
import { useNotificationMarkReadMutation } from 'app/queries/notifications/notifications';
import { useProject } from 'app/queries/projects/projects';
import { useCurrentUser } from 'app/queries/users/users';
import React from 'react';
import { Link } from 'react-router-dom';

export interface NotificationItemProps {
  batchNotification?: boolean;
  notification: NotificationSchema;
}

const NotificationItem: React.FC<NotificationItemProps> = ({ batchNotification, notification }) => {
  const { projectId } = notification.params || {};
  const { title: projectTitle } = notification.project || {};
  const user = useCurrentUser();
  const { data: project } = useProject(projectId, {
    query: {
      enabled: !!projectId,
    },
  });
  const { mutate: onMarkNotificationRead } = useNotificationMarkReadMutation();
  const timezone = project?.timezone || user.timezone || TIMEZONE_DEFAULT;

  const decoratedNotification = InAppNotificationDecorator(notification as NotificationSchema);
  if (!decoratedNotification) return null;

  const { title, inAppTitle, body, actor, createdAt, bodyExtra, batchTitle, url, icon, color } = decoratedNotification;

  const notificationTitle = (batchNotification && batchTitle) || inAppTitle || title || '';

  const markRead = () => {
    const readableNotifications: Partial<NotificationTypeSchema>[] = [
      'new_issue_comment',
      'new_issue_private_comment',
      'new_shift_report_comment',
    ];

    onMarkNotificationRead({
      notificationId: notification.id,
      data: readableNotifications.includes(notification.type) ? { similar: true } : {},
    });
  };

  const handleClick = () => {
    markRead();
  };

  return (
    <Link to={url}>
      <NotificationCard
        title={notificationTitle}
        body={body}
        bodyExtra={bodyExtra}
        subtitle={
          <div>
            <span className="text-xs leading-4 font-medium">{formatDateAndTime(createdAt, timezone)} </span>
            <span className="text-neutral-subtlest px-2">•</span>
            <span className="text-xs leading-4 font-medium">{projectTitle}</span>{' '}
          </div>
        }
        notificationId={notification.id}
        read={notification.read}
        handleClick={handleClick}
        avatarUrl={actor?.avatarUrl}
        actorName={actor?.name}
        icon={icon}
        color={color}
      />
    </Link>
  );
};

export { NotificationItem };
