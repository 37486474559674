import classnames from 'clsx';
import React from 'react';

import type { SIZE, THEME } from '../Badge.types';
import { dotColors } from '../BadgeTheme';

export interface DotProps {
  size: SIZE;
  theme: THEME;
}

export const Dot: React.FC<DotProps> = ({ theme }: DotProps) => {
  const classNames = classnames('h-2 w-2', dotColors[theme]);

  return (
    <svg className={classNames} fill="currentColor" viewBox="0 0 8 8">
      <title>Dot</title>
      <circle cx={4} cy={4} r={3} />
    </svg>
  );
};
