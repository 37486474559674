import { PopoverContent } from './PopoverContent/Content';
import { PopoverContentBody } from './PopoverContent/ContentBody';
import { PopoverContentFooter } from './PopoverContent/ContentFooter';
import { PopoverContentHeading } from './PopoverContent/ContentHeading';
import { PopoverRoot } from './PopoverRoot';
import { PopoverTrigger } from './PopoverTrigger';

export const Popover = Object.assign(PopoverRoot, {
  Root: PopoverRoot,
  Content: Object.assign(PopoverContent, {
    Heading: PopoverContentHeading,
    Body: PopoverContentBody,
    Footer: PopoverContentFooter,
  }),
  Trigger: PopoverTrigger,
});
