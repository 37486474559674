import React, { type ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';
import PageBody from './PageBody';
import PageFooter from './PageFooter';
import PageHeader from './PageHeader';

type Components = {
  Header: typeof PageHeader;
  Body: typeof PageBody;
  Footer: typeof PageFooter;
};

export type PageProps = ComponentProps<'div'>;

const PageRoot = React.forwardRef<HTMLDivElement, PageProps>(({ className, ...props }, ref) => (
  <div ref={ref} className={twMerge('flex flex-1 h-full flex-col overflow-auto', className)} {...props} />
));

PageRoot.displayName = 'Page';

export const Page: typeof PageRoot & Components = Object.assign(PageRoot, {
  Header: PageHeader,
  Body: PageBody,
  Footer: PageFooter,
});
