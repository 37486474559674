import { Menu } from '@headlessui/react';
import React, { Fragment } from 'react';

export type DropdownButtonProps = {
  children: React.ReactNode;
};

export const DropdownButton = React.forwardRef<HTMLButtonElement, DropdownButtonProps>(({ children }, ref) => (
  <Menu.Button as={Fragment} ref={ref}>
    {children}
  </Menu.Button>
));
