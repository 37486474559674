import * as Progress from '@radix-ui/react-progress';
import classNames from 'clsx';
import React, { type PropsWithChildren } from 'react';
import Badge from '../Badge';
import { type BadgeProps, SHAPE, SIZE, THEME } from '../Badge/Badge.types';

const ProgressBarActiveColorMap = {
  primary: {
    default: 'bg-indigo-500',
    disabled: 'bg-indigo-300',
  },
  success: {
    default: 'bg-green-500',
    disabled: 'bg-green-300',
  },
  warning: {
    default: 'bg-yellow-500',
    disabled: 'bg-yellow-300',
  },
  danger: {
    default: 'bg-red-500',
    disabled: 'bg-red-300',
  },
  secondary: {
    default: 'bg-gray-500',
    disabled: 'bg-gray-300',
  },
};

const ProgressBarSizeMap = {
  small: 'h-1',
  medium: 'h-2',
  large: 'h-3',
};

export type ProgressBarActiveColor = 'primary' | 'success' | 'warning' | 'danger' | 'secondary';

export type ProgressBarSize = 'small' | 'medium' | 'large';

export type ProgressBarState = 'default' | 'disabled';

export type ProgressBarProps = {
  color?: ProgressBarActiveColor;
  progress: number;
  size?: ProgressBarSize;
  disabled?: boolean;
};

export const ProgressBarRoot = React.forwardRef<
  React.ElementRef<typeof Progress.Root>,
  React.ComponentPropsWithoutRef<typeof Progress.Root> & ProgressBarProps
>(({ color = 'primary', progress, size = 'medium', className, children, disabled, ...progressBarProps }, ref) => (
  <div className="flex flex-col space-y-1">
    {children &&
      React.Children.map(children, (child) => {
        if (React.isValidElement(child)) return React.cloneElement(child, { ...child.props, progress, size });
      })}

    <Progress.Root
      ref={ref}
      value={progress}
      className={classNames(
        'w-full rounded-full bg-gray-900 bg-opacity-[.08] dark:bg-white dark:bg-opacity-[.2] relative',
        ProgressBarSizeMap[size],
        className
      )}
      aria-disabled={disabled}
      {...(progressBarProps as any)}
    >
      <Progress.Indicator
        aria-disabled={disabled}
        className={classNames(
          'rounded-full bg-indigo-500',
          ProgressBarSizeMap[size],
          ProgressBarActiveColorMap[color][(disabled ? 'disabled' : 'default') as ProgressBarState]
        )}
        style={{ width: `${progress}%` }}
      />
    </Progress.Root>
  </div>
));
ProgressBarRoot.displayName = 'ProgressBar.Root';

export interface ProgressBarHeaderProps {
  showProgress?: boolean;
  progress?: ProgressBarProps['progress'];
  size?: ProgressBarProps['size'];
}
export const ProgressBarHeader: React.FC<PropsWithChildren<ProgressBarHeaderProps>> = ({
  children,
  progress,
  size,
  showProgress,
}) => (
  <div className="flex justify-between space-x-2 items-baseline">
    {children && <div className="flex flex-col">{children}</div>}
    {showProgress && (
      <span
        className={classNames('text-gray-500 dark:text-gray-300 leading-4 font-medium ml-auto', {
          'text-[10px]': size === 'small',
          'text-xs': size === 'medium' || size === 'large',
        })}
      >
        {progress}%
      </span>
    )}
  </div>
);
ProgressBarHeader.displayName = 'ProgressBar.Header';

export const ProgressBarTitle: React.FC<PropsWithChildren> = ({ children }) => (
  <span className="text-gray-700 dark:text-white text-sm leading-5 font-medium flex items-center gap-x-1">
    {children}
  </span>
);
ProgressBarTitle.displayName = 'ProgressBar.Title';

export const ProgressBarSubtitle: React.FC<PropsWithChildren> = ({ children }) => (
  <span className="text-gray-500 dark:text-gray-300 text-xs leading-4 font-normal flex items-center gap-x-1">
    {children}
  </span>
);
ProgressBarSubtitle.displayName = 'ProgressBar.Subtitle';

export { THEME as ProgressBarBadgeTheme };
export const ProgressBarBadge: React.FC<PropsWithChildren<Pick<BadgeProps, 'theme' | 'label'>>> = ({
  label,
  theme,
}) => <Badge label={label} size={SIZE.EXTRA_SMALL} shape={SHAPE.BASIC} theme={theme} />;
ProgressBarBadge.displayName = 'ProgressBar.Badge';
