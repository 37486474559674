import { RadioGroup } from '@headlessui/react';
import React, { useCallback, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { GalleryGrid, GalleryGridItem, type GalleryGridItemProps } from './components/GalleryGrid/GalleryGrid';
import type { GalleryDocument } from './hooks/useDocumentsGallery';

type DocumentId = NonNullable<GalleryDocument>['id'];

export type GalleryPickerProps = {
  children?: (props: GalleryGridItemProps) => React.ReactNode;
  multiple?: boolean;
  documents: GalleryDocument[];
  value: DocumentId[];
  onChange: (ids: DocumentId[]) => void;
};

type Option = {
  label?: string;
  value?: string;
  children: React.ReactNode;
};

type GalleryPickerItemProps = {
  option: Option;
};

const checkedClasses = {
  container:
    '[&_.gallery-grid-item]:aria-checked:border-indigo-500 [&_.gallery-grid-item]:aria-checked:ring-2 [&_.gallery-grid-item]:aria-checked:ring-indigo-500',
  caption: '[&_figcaption]:aria-checked:bg-indigo-500 [&_figcaption]:aria-checked:text-white',
  icon: '[&_svg]:aria-checked:inline [&_svg]:aria-checked:bg-indigo-500 [&_svg]:aria-checked:ring-0',
};
const hoverClasses = {
  icon: '[&_svg]:hover:inline [&_svg]:hover:bg-white [&_svg]:hover:ring-1 [&_svg]:hover:ring-gray-300 [&_svg]:hover:ring-inset',
};

const GalleryPickerItem = ({ option }: GalleryPickerItemProps) => {
  return (
    <RadioGroup.Option
      value={option.value}
      aria-label={option.label}
      className={twMerge(
        'contents rounded-md cursor-pointer',
        hoverClasses.icon,
        checkedClasses.container,
        checkedClasses.caption,
        checkedClasses.icon
      )}
    >
      {option.children}
    </RadioGroup.Option>
  );
};

export const GalleryPicker: React.FC<GalleryPickerProps> = ({
  children = GalleryGridItem,
  documents,
  multiple,
  value,
  onChange,
}) => {
  const by = useCallback((ids: string, id: string) => Array.isArray(ids) && ids.includes(id), []);
  const options: Option[] = useMemo(
    () =>
      documents.map((document, index) => ({
        label: document?.filename,
        value: document?.id,
        children: children({ document, index, as: 'div' }),
      })),
    [documents, children]
  );

  const handleChange = (id: string) => {
    let newValue: DocumentId[] = [id];
    if (multiple) newValue = value.includes(id) ? value.filter((_id) => _id !== id) : [...value, id];
    onChange(newValue);
  };

  return (
    <RadioGroup
      by={by}
      // @ts-ignore
      value={value}
      onChange={handleChange}
    >
      <GalleryGrid size="large" query="media">
        {options.map((option) => (
          <GalleryPickerItem key={option.value} option={option} />
        ))}
      </GalleryGrid>
    </RadioGroup>
  );
};
