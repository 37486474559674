import { useMessageGetter } from '@messageformat/react';
import { Divider, IconButton, VisuallyHidden } from '@shape-construction/arch-ui';
import { ArrowLeftOnRectangleIcon, ArrowRightOnRectangleIcon } from '@shape-construction/arch-ui/src/Icons/solid';
import Sidebar, { useSidebar } from '@shape-construction/arch-ui/src/Sidebar';
import { breakpoints } from '@shape-construction/arch-ui/src/utils/breakpoints';
import { useMediaQuery } from '@shape-construction/hooks';
import { useQuery } from '@tanstack/react-query';
import { ProjectsSelector } from 'app/components/ProjectsSelector/ProjectsSelector';
import { getProjectQueryOptions } from 'app/queries/projects/projects';
import { useCurrentUser } from 'app/queries/users/users';
import React, { useEffect } from 'react';
import { Link, matchPath, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { ADMIN_OR_ABOVE } from '../../../constants/Roles';
import { BackNavigationButton } from '../Navbar/components/BackNavigationButton';
import { ForwardNavigationButton } from '../Navbar/components/ForwardNavigationButton';
import { ReloadButton } from '../Navbar/components/ReloadButton';
import ShapeLogo from '../ShapeLogo/ShapeLogo';
import { AppSidebarUpgradePlanButton } from './AppSidebarUpgradePlanButton';
import { type SidebarItemType, useAdminItems, useOverviewItems } from './useSidebarItems';

export const AppSidebarCollapseButton = () => {
  const { isMobile, open, toggleSidebar } = useSidebar();
  const isExpanded = !isMobile && open;
  return (
    <div className="hidden p-4 pt-0 lg:flex lg:justify-end">
      <IconButton
        icon={isExpanded ? ArrowLeftOnRectangleIcon : ArrowRightOnRectangleIcon}
        size="md"
        aria-label="sidebar control"
        aria-expanded={isExpanded}
        color="secondary"
        variant="text"
        onClick={() => toggleSidebar()}
      />
    </div>
  );
};

export const AppSidebarMobileHeader: React.FC = () => {
  const projectRoute = useMatch('/projects/:projectId/*');
  const notificationRoute = useMatch('/notifications/projects/:projectId');
  const match = projectRoute || notificationRoute;
  const { defaultProject } = useCurrentUser();

  const currentProjectId = match?.params?.projectId ?? defaultProject;

  return (
    <div className="section-header my-2 md:hidden">
      <div className="flex h-16 w-full items-center px-6 py-5 justify-between">
        <div className="h-full shrink-0">
          <Link to="/">
            <ShapeLogo withLettering />
          </Link>
        </div>
        <div className="flex items-center">
          {currentProjectId && (
            <>
              <BackNavigationButton color="primary" />
              <ForwardNavigationButton color="primary" />
            </>
          )}
          <ReloadButton color="primary" />
        </div>
      </div>
      <Divider orientation="horizontal" />
    </div>
  );
};

type AppSidebarItemProps = {
  item: SidebarItemType;
};
export const AppSidebarItem = ({ item }: AppSidebarItemProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const matchRoute = matchPath(item.isActiveOn ?? item.route, location.pathname);
  const isRoute = !!matchRoute;

  const { isMobile, toggleSidebar } = useSidebar();

  const notifications = item.notifications;
  const activateBadge = Boolean(!isMobile && notifications && notifications > 0);
  const badgeText = activateBadge ? notifications?.toString() : undefined;

  const handleRedirect: React.MouseEventHandler<HTMLButtonElement> = () => {
    navigate(item.route);
    if (isMobile) toggleSidebar(false);
  };

  return (
    <Sidebar.Item
      active={isRoute}
      disabled={item.disabled}
      tooltip={item.title}
      onClick={handleRedirect}
      aria-current="page"
    >
      <Sidebar.Icon badge={badgeText} icon={item.image} />
      <VisuallyHidden.Root>{`${item.title}-icon`}</VisuallyHidden.Root>
      {item.title}
    </Sidebar.Item>
  );
};

export const AppSidebarRoot: React.FC = () => {
  const projectRoute = useMatch('/projects/:projectId/*');
  const notificationRoute = useMatch('/notifications/projects/:projectId');
  const match = projectRoute || notificationRoute;
  const projectId = match?.params.projectId;

  const { data: currentProject } = useQuery(getProjectQueryOptions(projectId!));
  const currentUser = useCurrentUser();

  const overviewItems = useOverviewItems(currentProject);
  const adminItems = useAdminItems(currentProject);

  const messages = useMessageGetter('appSidebar');

  const { isMobile, open, toggleSidebar } = useSidebar();

  const isLargeScreen = useMediaQuery(breakpoints.up('lg'));

  useEffect(() => {
    if (!isMobile && !isLargeScreen) toggleSidebar(false);
  }, [isMobile, isLargeScreen]);

  const renderHeader = () => {
    return (
      <>
        <div className="hidden px-2 md:block">
          <ProjectsSelector currentProject={currentProject} isExpanded={open} />
        </div>
        <AppSidebarMobileHeader />
      </>
    );
  };

  const renderOverviewItems = () => {
    return (
      <Sidebar.Section>
        {overviewItems.map((item) => (
          <AppSidebarItem key={item.key} item={item} />
        ))}
      </Sidebar.Section>
    );
  };

  const renderAdminItems = () => {
    if (!currentProject) return null;
    if (!ADMIN_OR_ABOVE.includes(currentProject.currentTeamMemberRole)) return null;
    return (
      <Sidebar.Section
        label={<span className="text-neutral-subtlest">{messages('admin')}</span>}
        data-testid="admin-section"
      >
        {adminItems.map((item) => (
          <AppSidebarItem key={item.key} item={item} />
        ))}
      </Sidebar.Section>
    );
  };

  if (!currentUser) return null;
  return (
    <Sidebar.Root className="gap-y-3" data-testid="app-sidebar">
      <Sidebar.Header data-testid="app-sidebar-header">{renderHeader()}</Sidebar.Header>
      <Sidebar.Content>
        {renderOverviewItems()}
        {renderAdminItems()}
      </Sidebar.Content>
      <Sidebar.Footer data-testid="app-sidebar-footer">
        <AppSidebarUpgradePlanButton />
        <AppSidebarCollapseButton />
      </Sidebar.Footer>
    </Sidebar.Root>
  );
};
