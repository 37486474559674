import { Slot } from '@radix-ui/react-slot';
import React, { forwardRef } from 'react';
import type {
  ButtonRef,
  DivRef,
  SlottableButtonProps,
  SlottableDivProps,
  SlottableSpanProps,
  SpanRef,
} from '../../primitives/types';

export const Root = forwardRef<DivRef, SlottableDivProps>(({ asChild, ...props }, ref) => {
  const Component = asChild ? Slot : 'div';
  return <Component ref={ref} {...props} />;
});
Root.displayName = 'List.Root';

export const Divider = forwardRef<DivRef, SlottableDivProps>(({ asChild, ...props }, ref) => {
  const Component = asChild ? Slot : 'div';
  return <Component ref={ref} {...props} />;
});
Divider.displayName = 'List.Divider';

export const Item = forwardRef<ButtonRef, SlottableButtonProps>(({ asChild, ...props }, ref) => {
  const Component = asChild ? Slot : 'button';
  return <Component ref={ref} {...props} />;
});
Item.displayName = 'List.Item';

export const Content = forwardRef<DivRef, SlottableDivProps>(({ asChild, ...props }, ref) => {
  const Component = asChild ? Slot : 'div';
  return <Component ref={ref} {...props} />;
});
Content.displayName = 'List.Content';

export const Title = forwardRef<SpanRef, SlottableSpanProps>(({ asChild, ...props }, ref) => {
  return <span ref={ref} {...props} />;
});
Title.displayName = 'List.Title';

export const SupportingText = forwardRef<SpanRef, SlottableSpanProps>(({ asChild, ...props }, ref) => {
  return <span ref={ref} {...props} />;
});
SupportingText.displayName = 'List.SupportingText';
