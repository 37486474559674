export const environment = {
  GTM_ID: process.env.REACT_APP_GTM_ID,
  HOTJAR_SITE_ID: process.env.REACT_APP_HOTJAR_SITE_ID,
  PUSH_NOTIFICATIONS_PUBLIC_KEY: process.env.REACT_APP_PUSH_NOTIFICATIONS_PUBLIC_KEY,
  NOTIFICATIONS_CHECK_INTERVAL_MS: process.env.REACT_APP_NOTIFICATIONS_CHECK_INTERVAL_MS || 10000,

  API_URL: process.env.REACT_APP_RAILS_API_URL,
  ACTION_CABLE_URL: process.env.REACT_APP_RAILS_ACTION_CABLE_URL,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  SENTRY_ENVIRONMENT: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  STATUS_PAGE_EMBED_URL: process.env.REACT_APP_STATUS_PAGE_EMBED_URL,
  SUPPORT_EMAIL: process.env.REACT_APP_SUPPORT_EMAIL as string,
  REVISION: process.env.REACT_APP_REVISION,
  VERSION: process.env.REACT_APP_VERSION,
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID as string,
  MICROSOFT_CLIENT_ID: process.env.REACT_APP_MICROSOFT_CLIENT_ID as string,

  // Zendesk sdk
  ZENDESK_OAUTH_TOKEN: process.env.REACT_APP_ZENDESK_OAUTH_TOKEN,
  ZENDESK_API_URL: `${process.env.REACT_APP_ZENDESK_URL}/api/v2`,
  ZENDESK_HELPCENTER_URL: process.env.REACT_APP_ZENDESK_URL as string,
  ZENDESK_ALLOW_DRAFT_ARTICLES: process.env.REACT_APP_ZENDESK_DISPLAY_DRAFT_ARTICLES === 'true',

  // Dev tools
  REDUX_DEVTOOLS_ENABLED: process.env.REACT_APP_REDUX_DEVTOOLS_ENABLED === 'true',
  DISABLE_REACT_QUERY_DEV_TOOLS: process.env.REACT_APP_DISABLE_REACT_QUERY_DEV_TOOLS === 'true',

  // Feed
  FEED_EVENTS_BATCH_NUMBER: process.env.REACT_APP_FEED_EVENTS_BATCH_NUMBER || 20,
  ISSUE_COORDINATION_FEED_CHECK_INTERVAL_MS: process.env.REACT_APP_ISSUE_COORDINATION_FEED_CHECK_INTERVAL_MS || 10000,

  // Shift reports
  SHIFT_REPORT_COMMS_BATCH_NUMBER: Number(process.env.REACT_APP_SHIFT_REPORT_COMMS_BATCH_NUMBER) || 20,
  SHIFT_REPORT_COMMS_CHECK_INTERVAL_MS: Number(process.env.REACT_APP_SHIFT_REPORT_COMMS_CHECK_INTERVAL_MS) || 10000,

  // Activities
  ACTIVITIES_REFETCH_INTERVAL_MS: process.env.REACT_APP_ACTIVITIES_REFETCH_INTERVAL_MS || 5000,

  // Others
  DISABLE_HTTPS_REDIRECT: process.env.REACT_APP_DISABLE_HTTPS_REDIRECT === 'true',
  EVENTS_MAX_BATCH_NUMBER: process.env.REACT_APP_EVENTS_MAX_BATCH_NUMBER || 10,
  EVENTS_BATCHING_MAX_TIME_MINUTES: process.env.REACT_APP_EVENTS_BATCHING_MAX_TIME_MINUTES || 10, // Max 59
  INSTALL_RESHOW_PROMPT_DELAY_MINUTES: process.env.REACT_APP_INSTALL_APP_RESHOW_DELAY_MINUTES || 12 * 60, // 12 hrs * 60 minutes

  // Channels links
  CHANNELS_APP_STORE_URL: process.env.REACT_APP_CHANNELS_APP_STORE_URL,
  CHANNELS_PLAY_STORE_URL: process.env.REACT_APP_CHANNELS_PLAY_STORE_URL,
  CHANNELS_URL: process.env.REACT_APP_CHANNELS_URL,

  // Cache
  LOCAL_PERSISTER_KEY: 'localData',
  REDUX_PERSISTER_KEY: 'localforage',
  REACT_QUERY_PERSISTER_KEY: 'react-query-persister',
  JSON_STORAGE_KEY: 'json-storage',

  // Cookiebot
  COOKIEBOT_DOMAIN_GROUP_ID: process.env.REACT_APP_COOKIEBOT_DOMAIN_GROUP_ID,

  // Hubspot
  HUBSPOT_ONBOARDING_MEETING_LINK: process.env.REACT_APP_HUBSPOT_ONBOARDING_MEETING_LINK,

  // Useberry
  USEBERRY: process.env.REACT_APP_USEBERRY === 'true',

  // Stream Chat
  STREAM_API_KEY: process.env.REACT_APP_CHANNELS_STREAM_API_KEY,
};
