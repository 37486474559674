import type {
  NotificationParametersSchema,
  NotificationSchema,
  NotificationTypeSchema,
  PushNotificationSchema,
} from '@shape-construction/api/model';
import {
  ArrowUturnLeftIcon,
  AtSymbolIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  ChatPrivateIcon,
  CheckCircleIcon,
  ClockIcon,
  FolderIcon,
  NoSymbolIcon,
  UserCircleIcon,
} from '@shape-construction/arch-ui/src/Icons/solid';
import type { NotificationIconColorType } from '@shape-construction/arch-ui/src/NotificationCard/NotificationIcon';
import type { ShiftReportCommsChannel } from 'app/queries/shiftReports/comments/comments';
import type React from 'react';

const buildNotificationsIssueUrl = (projectId: string, issueId: string) => `/projects/${projectId}/issues/${issueId}`;

const buildShiftReportCommentsUrl = (projectId: string, shiftReportId: string, channel: ShiftReportCommsChannel) =>
  `/projects/${projectId}/shift-reports/${shiftReportId}/${channel}`;

const buildProjectUrl = (projectId: string) => `/projects/${projectId}`;

type NotificationDecorationFields = {
  body?: string;
  title: string;
  url: string;
  batchTitle?: string;
  bodyExtra?: string;
  inAppTitle?: JSX.Element | string;
  tag?: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  color: NotificationIconColorType;
};

export type PushNotificationDecoratedType = PushNotificationSchema & NotificationDecorationFields;
export type InAppNotificationDecoratedType = NotificationSchema & NotificationDecorationFields;

type NotificationTitleProps = {
  actor: string;
  action: string;
  object?: string;
};

export const NOTIFICATION_ICON_TYPES: Record<NotificationTypeSchema, React.FC<React.SVGProps<SVGSVGElement>>> = {
  added_to_project: FolderIcon,
  you_were_assigned_to_issue: UserCircleIcon,
  your_issue_assignment_was_accepted: UserCircleIcon,
  your_issue_assignment_was_rejected: UserCircleIcon,
  your_issue_was_reassigned: UserCircleIcon,
  new_issue_comment: ChatBubbleOvalLeftEllipsisIcon,
  new_issue_private_comment: ChatPrivateIcon,
  new_shift_report_comment: ChatBubbleOvalLeftEllipsisIcon,
  issue_comment_mention: AtSymbolIcon,
  shift_report_comment_mention: AtSymbolIcon,
  issue_needs_your_approval: ClockIcon,
  your_issue_approval_request_was_rejected: NoSymbolIcon,
  your_issue_approval_request_was_accepted: CheckCircleIcon,
  issue_was_resolved: CheckCircleIcon,
  issue_was_reopened: ArrowUturnLeftIcon,
};

export const NOTIFICATION_ICON_COLORS: Record<NotificationTypeSchema, NotificationIconColorType> = {
  added_to_project: 'indigo',
  you_were_assigned_to_issue: 'indigo',
  your_issue_assignment_was_accepted: 'green',
  your_issue_assignment_was_rejected: 'red',
  your_issue_was_reassigned: 'indigo',
  new_issue_comment: 'yellow',
  new_issue_private_comment: 'yellow',
  new_shift_report_comment: 'yellow',
  issue_comment_mention: 'pink',
  shift_report_comment_mention: 'pink',
  issue_needs_your_approval: 'indigo',
  your_issue_approval_request_was_rejected: 'red',
  your_issue_approval_request_was_accepted: 'green',
  issue_was_resolved: 'green',
  issue_was_reopened: 'indigo',
};

const NotificationTitle = ({ actor, action, object }: NotificationTitleProps) => {
  return (
    <>
      <span className="font-semibold"> {actor} </span> {action}{' '}
      {object && <span className="font-semibold"> {object} </span>}
    </>
  );
};

export const PushNotificationDecorator = (
  notification: PushNotificationSchema
): PushNotificationDecoratedType | undefined => {
  const decoratedFields = buildNotificationDecorationFields(notification);
  if (!decoratedFields) return undefined;
  return { ...notification, ...decoratedFields };
};

export const InAppNotificationDecorator = (
  notification: NotificationSchema
): InAppNotificationDecoratedType | undefined => {
  const decoratedFields = buildNotificationDecorationFields(notification);
  if (!decoratedFields) return undefined;
  return { ...notification, ...decoratedFields };
};

const buildNotificationDecorationFields = (
  notification: NotificationParametersSchema
): NotificationDecorationFields | undefined => {
  switch (notification.type) {
    case 'added_to_project':
      return {
        title: `${notification.actor.firstName} has added you to the project ${notification.params.projectTitle}`,
        inAppTitle: (
          <NotificationTitle
            actor={notification.actor.firstName}
            action="has added you to the project"
            object={notification.params.projectTitle}
          />
        ),
        body: 'Click here to view the project and start collaborating.',
        icon: NOTIFICATION_ICON_TYPES.added_to_project,
        color: NOTIFICATION_ICON_COLORS.added_to_project,
        url: buildProjectUrl(notification.params.projectId),
      };
    case 'you_were_assigned_to_issue':
      return {
        title: `${notification.actor.firstName} has assigned you the issue`,
        inAppTitle: (
          <NotificationTitle
            actor={notification.actor.firstName}
            action="has assigned you the issue"
            object={notification.params.issueTitle}
          />
        ),
        icon: NOTIFICATION_ICON_TYPES.you_were_assigned_to_issue,
        color: NOTIFICATION_ICON_COLORS.you_were_assigned_to_issue,
        url: buildNotificationsIssueUrl(notification.params.projectId, notification.params.issueId),
      };
    case 'your_issue_assignment_was_accepted':
      return {
        title: `${notification.actor.firstName} has accepted an issue you assigned`,
        inAppTitle: (
          <NotificationTitle
            actor={notification.actor.firstName}
            action="has accepted an issue you assigned"
            object={notification.params.issueTitle}
          />
        ),
        icon: NOTIFICATION_ICON_TYPES.your_issue_assignment_was_accepted,
        color: NOTIFICATION_ICON_COLORS.your_issue_assignment_was_accepted,
        url: buildNotificationsIssueUrl(notification.params.projectId, notification.params.issueId),
      };
    case 'your_issue_assignment_was_rejected':
      return {
        title: `${notification.actor.firstName} has rejected an issue you assigned`,
        inAppTitle: (
          <NotificationTitle
            actor={notification.actor.firstName}
            action="has rejected an issue you assigned"
            object={notification.params.issueTitle}
          />
        ),
        icon: NOTIFICATION_ICON_TYPES.your_issue_assignment_was_rejected,
        color: NOTIFICATION_ICON_COLORS.your_issue_assignment_was_rejected,
        url: buildNotificationsIssueUrl(notification.params.projectId, notification.params.issueId),
      };
    case 'your_issue_was_reassigned':
      return {
        title: `${notification.actor.firstName} re-assigned an issue you created assigned`,
        inAppTitle: (
          <NotificationTitle
            actor={notification.actor.firstName}
            action="re-assigned an issue you created assigned"
            object={notification.params.issueTitle}
          />
        ),
        icon: NOTIFICATION_ICON_TYPES.your_issue_was_reassigned,
        color: NOTIFICATION_ICON_COLORS.your_issue_was_reassigned,
        url: buildNotificationsIssueUrl(notification.params.projectId, notification.params.issueId),
      };
    case 'new_issue_comment':
      return {
        title: `${notification.actor.firstName} left a comment on the issue ${notification.params.issueTitle}`,
        inAppTitle: (
          <NotificationTitle
            actor={notification.actor.firstName}
            action="left a comment on the issue"
            object={notification.params.issueTitle}
          />
        ),
        bodyExtra: `"${notification.params.message}"`,
        tag: `new_issue_comment-${notification.params.issueId}`,
        batchTitle: `${notification.actor.name} left the latest comment on ${notification.params.issueTitle}`,
        icon: NOTIFICATION_ICON_TYPES.new_issue_comment,
        color: NOTIFICATION_ICON_COLORS.new_issue_comment,
        url: buildNotificationsIssueUrl(notification.params.projectId, notification.params.issueId),
      };
    case 'new_issue_private_comment':
      return {
        title: `${notification.actor.firstName} left a private comment on the issue ${notification.params.issueTitle}`,
        inAppTitle: (
          <NotificationTitle
            actor={notification.actor.firstName}
            action="left a private comment on the issue"
            object={notification.params.issueTitle}
          />
        ),
        bodyExtra: `"${notification.params.message}"`,
        tag: `new_issue_private_comment-${notification.params.issueId}`,
        batchTitle: `${notification.actor.name} left the latest private comment on ${notification.params.issueTitle}`,
        icon: NOTIFICATION_ICON_TYPES.new_issue_private_comment,
        color: NOTIFICATION_ICON_COLORS.new_issue_private_comment,
        url: buildNotificationsIssueUrl(notification.params.projectId, notification.params.issueId),
      };
    case 'new_shift_report_comment':
      return {
        title: `${notification.actor.firstName} left a comment on the shift report ${notification.params.shiftReportTitle || notification.params.shiftReportDate}`,
        inAppTitle: (
          <NotificationTitle
            actor={notification.actor.firstName}
            action="left a comment on the shift report"
            object={notification.params.shiftReportTitle || notification.params.shiftReportDate || ''}
          />
        ),
        bodyExtra: `"${notification.params.message}"`,
        tag: `new_shift_report_${notification.params.channel}_comment-${notification.params.shiftReportId}`, // group comments by shift report
        batchTitle: `${notification.actor.name} left the latest comment on ${notification.params.shiftReportTitle || notification.params.shiftReportDate || ''}`,
        icon: NOTIFICATION_ICON_TYPES.new_shift_report_comment,
        color: NOTIFICATION_ICON_COLORS.new_shift_report_comment,
        url: buildShiftReportCommentsUrl(
          notification.params.projectId,
          notification.params.shiftReportId,
          notification.params.channel
        ),
      };
    case 'issue_comment_mention':
      return {
        title: `${notification.actor.firstName} mentioned you on the issue ${notification.params.issueTitle}`,
        inAppTitle: (
          <NotificationTitle
            actor={notification.actor.firstName}
            action="mentioned you on the issue"
            object={notification.params.issueTitle}
          />
        ),
        // TODO: API is not sending the message
        // bodyExtra: notification.params.message && `"${notification.params.message}"`,
        icon: NOTIFICATION_ICON_TYPES.issue_comment_mention,
        color: NOTIFICATION_ICON_COLORS.issue_comment_mention,
        url: buildNotificationsIssueUrl(notification.params.projectId, notification.params.issueId),
      };
    case 'shift_report_comment_mention':
      return {
        title: `${notification.actor.firstName} mentioned you on the shift report ${notification.params.shiftReportTitle || notification.params.shiftReportDate}`,
        inAppTitle: (
          <NotificationTitle
            actor={notification.actor.firstName}
            action="mentioned you on the shift report"
            object={notification.params.shiftReportTitle || notification.params.shiftReportDate || ''}
          />
        ),
        // TODO: API is not sending the message
        // bodyExtra: notification.params.message && `"${notification.params.message}"`,
        icon: NOTIFICATION_ICON_TYPES.shift_report_comment_mention,
        color: NOTIFICATION_ICON_COLORS.shift_report_comment_mention,
        url: buildShiftReportCommentsUrl(
          notification.params.projectId,
          notification.params.shiftReportId,
          notification.params.channel
        ),
      };
    case 'issue_needs_your_approval':
      return {
        title: 'An issue is waiting for your approval. Review it!',
        inAppTitle: (
          <>
            The issue <span className="font-semibold">{notification.params.issueTitle}</span> needs your approval
          </>
        ),
        icon: NOTIFICATION_ICON_TYPES.issue_needs_your_approval,
        color: NOTIFICATION_ICON_COLORS.issue_needs_your_approval,
        url: buildNotificationsIssueUrl(notification.params.projectId, notification.params.issueId),
      };
    case 'your_issue_approval_request_was_rejected':
      return {
        title: `${notification.actor.firstName} rejected your approval request for the issue ${notification.params.issueTitle}`,
        inAppTitle: (
          <NotificationTitle
            actor={notification.actor.firstName}
            action="rejected your approval request for the issue"
            object={notification.params.issueTitle}
          />
        ),
        bodyExtra: `Reason: "${notification.params.reason.trim()}"`,
        icon: NOTIFICATION_ICON_TYPES.your_issue_approval_request_was_rejected,
        color: NOTIFICATION_ICON_COLORS.your_issue_approval_request_was_rejected,
        url: buildNotificationsIssueUrl(notification.params.projectId, notification.params.issueId),
      };
    case 'your_issue_approval_request_was_accepted':
      return {
        title: `${notification.actor.firstName} (Approver ${notification.params.progress}) approved the issue ${notification.params.issueTitle}`,
        inAppTitle: (
          <>
            <span className="font-semibold"> {notification.actor.firstName} </span>{' '}
            {`(Approver ${notification.params.progress})`} approved the issue{' '}
            <span className="font-semibold"> {notification.params.issueTitle}</span>
          </>
        ),
        icon: NOTIFICATION_ICON_TYPES.your_issue_approval_request_was_accepted,
        color: NOTIFICATION_ICON_COLORS.your_issue_approval_request_was_accepted,
        url: buildNotificationsIssueUrl(notification.params.projectId, notification.params.issueId),
      };
    case 'issue_was_resolved':
      return {
        title: `${notification.actor.firstName} (Approver ${notification.params.progress}) approved the issue ${notification.params.issueTitle}`,
        inAppTitle: (
          <NotificationTitle
            actor={notification.actor.firstName}
            action={`(Approver ${notification.params.progress}) approved the issue`}
            object={notification.params.issueTitle}
          />
        ),
        body: 'Issue resolved!',
        icon: NOTIFICATION_ICON_TYPES.issue_was_resolved,
        color: NOTIFICATION_ICON_COLORS.issue_was_resolved,
        url: buildNotificationsIssueUrl(notification.params.projectId, notification.params.issueId),
      };
    case 'issue_was_reopened':
      return {
        title: `${notification.actor.firstName} reopened the issue ${notification.params.issueTitle}`,
        inAppTitle: (
          <NotificationTitle
            actor={notification.actor.firstName}
            action="reopened the issue"
            object={notification.params.issueTitle}
          />
        ),
        icon: NOTIFICATION_ICON_TYPES.issue_was_reopened,
        color: NOTIFICATION_ICON_COLORS.issue_was_reopened,
        url: buildNotificationsIssueUrl(notification.params.projectId, notification.params.issueId),
      };
  }
};
