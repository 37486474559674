import { Transition } from '@headlessui/react';
import classNames from 'clsx';
import React, { Fragment } from 'react';
import type { Toast as HotToastType } from 'react-hot-toast';
import { IconButton } from '../Button/IconButton/IconButton';
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from '../Icons/solid';

export const colors = ['primary', 'secondary', 'success', 'warning', 'danger'] as const;
export type ToastColor = (typeof colors)[number];

export interface ToastProps {
  color: ToastColor;
  message: React.ReactNode;
  /**
   * Called when the Toast is dismissed.
   */
  onClose?: () => void;
  Actions?: React.ReactNode;
  alignContent?: 'center' | 'start';
  toast: HotToastType;
  hasIcon?: boolean;
}

const icons: Record<NonNullable<ToastColor>, React.ReactNode> = {
  primary: <InformationCircleIcon className="h-5 w-5 text-indigo-400" aria-hidden="true" />,
  secondary: <InformationCircleIcon className="h-5 w-5 text-white" aria-hidden="true" />,
  success: <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />,
  warning: <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />,
  danger: <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />,
};

export const Toast: React.FC<ToastProps> = ({
  message,
  color,
  onClose,
  Actions,
  alignContent = 'center',
  toast,
  hasIcon = true,
}) => {
  const icon = icons[color];

  return (
    <Transition
      as={Fragment}
      show={toast.visible}
      enter="transition-all ease duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100 -translate-y-full"
      leave="transition-all ease duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0 translate-y-full"
    >
      <div
        className="bg-gray-800 border-0 text-sm leading-5 font-normal text-white rounded-lg shadow-lg p-3 max-w-[368px] w-full"
        role="status"
      >
        <div
          className={classNames('flex gap-3', {
            'items-center': alignContent === 'center',
            'items-start': alignContent === 'start',
          })}
        >
          {hasIcon && <div className="shrink-0">{icon}</div>}
          <div
            className={classNames('min-w-0 flex flex-1 flex-wrap justify-between gap-3', {
              'items-center': alignContent === 'center',
              'items-start': alignContent === 'start',
            })}
          >
            <span className="flex-1 min-w-0 break-words">{message}</span>
            {Actions && <div>{Actions}</div>}
          </div>
          {onClose && (
            <div className="flex shrink-0">
              <IconButton
                aria-label="close toast"
                type="button"
                size="xxs"
                color="white"
                variant="text"
                icon={XMarkIcon}
                onClick={onClose}
              />
            </div>
          )}
        </div>
      </div>
    </Transition>
  );
};
