import { usePrevious } from '@shape-construction/hooks';
import React, { type PropsWithChildren, createContext, useContext, useEffect, useRef } from 'react';

export const SelectContext = createContext<{
  multiple?: boolean;
  triggerRef: React.MutableRefObject<HTMLButtonElement | null>;
}>({
  multiple: false,
  triggerRef: { current: null },
});

export const useSelectContext = () => useContext(SelectContext);

export type SelectProviderProps = {
  open?: boolean;
  multiple?: boolean;
  onClose?: () => void;
};

export const SelectProvider = ({ children, multiple, open, onClose }: PropsWithChildren<SelectProviderProps>) => {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const wasOpen = usePrevious(open);

  useEffect(() => {
    if (!open && wasOpen) onClose?.();
  }, [open, wasOpen, onClose]);

  return <SelectContext.Provider value={{ triggerRef, multiple }}>{children}</SelectContext.Provider>;
};
