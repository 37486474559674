import classNames from 'clsx';
import React, { type ButtonHTMLAttributes } from 'react';
import Badge from '../../Badge';
import { SHAPE, SIZE, THEME } from '../../Badge/Badge.types';

export interface TabProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onChange'> {
  /**
   * A badge (label) to show on the tab
   */
  badge?: number | null;
  /**
   * Handler to is run when selecting the tab.
   * Doesn't get run when clicking on a 'selected' tab.
   * See also Tabs onChange property
   */
  onSelect?: React.EventHandler<any>;
  /**
   * Is this tab the currently selected one - if selected, highlight the tab.
   * When inside Tabs component, this property's value will be set automatically.
   */
  selected?: boolean;
  /**
   * The internal value of the tab. If not set, Tabs will set it as the tab index
   */
  value?: any;
  leadingIcon?: React.ElementType;
}

export const Tab: React.FC<TabProps> = (props: TabProps) => {
  const {
    badge,
    children,
    onSelect,
    value,
    selected,
    // @ts-ignore internal use only. See React.cloneElement on Tabs component
    onChange,
    leadingIcon: LeadingIcon,
    ...buttonProps
  } = props;
  const handleClick = (event: any) => {
    if (onChange) {
      onChange(event, value);
    }

    if (onSelect) {
      onSelect(event);
    }
  };

  const tabClassnames = classNames(
    'border-b-2 flex font-medium px-1 py-4 text-sm whitespace-nowrap gap-x-1 items-center',
    {
      'border-indigo-500 text-indigo-600 cursor-default': selected,
      'border-transparent text-gray-500 cursor-pointer': !selected,
      'opacity-50 cursor-not-allowed': buttonProps.disabled,
      'hover:border-gray-300 hover:text-gray-700': !buttonProps.disabled && !selected,
    }
  );
  const content = (
    <>
      {LeadingIcon && <LeadingIcon className="h-4 w-4" data-testid="leading-icon" />}
      {children}
      {badge && <Badge label={badge.toString()} shape={SHAPE.BASIC} theme={THEME.PINK} size={SIZE.SMALL} />}
    </>
  );

  if (selected) {
    return (
      <div aria-selected className={tabClassnames} role="tab">
        {content}
      </div>
    );
  }

  return (
    <button
      aria-selected={false}
      className={tabClassnames}
      onClick={handleClick}
      role="tab"
      type="button"
      tabIndex={0}
      {...buttonProps}
    >
      {content}
    </button>
  );
};
