import { type AuthenticationResult, type EventMessage, EventType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { getGetApiUsersMeQueryKey } from '@shape-construction/api/api';
import type {
  LoginAttemptProviderContentSchemaTokenData,
  LoginAttemptSchema,
  UserSchema,
} from '@shape-construction/api/model';
import { loginRequest } from 'app/msalAuthConfig';
import queryClient from 'app/queries/query.client.builder';
import { useVerifyEmail } from 'app/queries/users/users';
import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useMicrosoftLogin = () => {
  const { instance, inProgress } = useMsal();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { mutate: verifyEmail } = useVerifyEmail();

  const [isMicrosoftLoginLoading, setIsMicrosoftLoginLoading] = useState(false);

  instance.enableAccountStorageEvents();
  instance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const authenticationResult = event.payload as AuthenticationResult;
      const account = authenticationResult.account;
      instance.setActiveAccount(account);
    }
  });

  const loginWithMicrosoft = (userPayload: UserSchema) => {
    queryClient.setQueryData(getGetApiUsersMeQueryKey(), userPayload);
    setIsMicrosoftLoginLoading(false);
  };

  const signupWithMicrosoft = useCallback(
    (token: string, tokenData: LoginAttemptProviderContentSchemaTokenData) => {
      setIsMicrosoftLoginLoading(false);
      navigate(
        { pathname: '/signup-provider', search },
        {
          state: {
            token,
            accountData: tokenData,
            provider: 'microsoft',
          },
        }
      );
    },
    [navigate, search]
  );

  const handleSuccess = useCallback(
    ({ accessToken }: AuthenticationResult) => {
      verifyEmail(
        {
          data: {
            token: accessToken,
            strategy: 'microsoft',
          },
        },
        {
          onSuccess: (requestResponse: LoginAttemptSchema) => {
            if (requestResponse.strategy === 'microsoft') {
              const { user: userPayload, tokenData } = requestResponse.microsoft;

              userPayload ? loginWithMicrosoft(userPayload) : signupWithMicrosoft(accessToken, tokenData);
            }
          },

          onError: () => {
            setIsMicrosoftLoginLoading(false);
          },
        }
      );
    },
    [signupWithMicrosoft, verifyEmail]
  );

  const handleLogin = () => instance.loginRedirect(loginRequest);

  return {
    instance,
    handleLogin,
    handleSuccess,
    isMicrosoftLoginLoading: inProgress !== 'none' || isMicrosoftLoginLoading,
    setIsMicrosoftLoginLoading,
  };
};
