import { Badge } from '@shape-construction/arch-ui';
import { THEME } from '@shape-construction/arch-ui/src/Badge/Badge.types';
import classNames from 'clsx';
import React, { type ReactNode } from 'react';

export type PersonItemProps = {
  size?: 'sm';
  avatar: ReactNode;
  primaryLabel: string;
  secondaryLabel: string;
  inlineLabel?: string;
  inlineBadge?: string | null;
};

const Heading: React.FC<React.HTMLAttributes<HTMLHeadingElement> & { small: boolean }> = ({
  small,
  children,
  ...props
}) => (small ? <h4 {...props}>{children}</h4> : <h3 {...props}>{children}</h3>);

export const PersonItem: React.FC<PersonItemProps> = ({
  size,
  avatar,
  primaryLabel,
  secondaryLabel,
  inlineLabel,
  inlineBadge,
}) => {
  const isSmall = size === 'sm';

  const articleClassNames = classNames('flex w-full', {
    'items-center space-x-2': isSmall,
    'gap-x-3': !isSmall,
  });
  const headerClassNames = classNames('overflow-hidden', {
    'flex flex-col': isSmall,
    'text-sm leading-5': !isSmall,
  });

  return (
    <article className={articleClassNames}>
      {avatar}
      <header className={headerClassNames}>
        <div className="flex">
          <Heading small={isSmall} className="whitespace-nowrap font-medium text-gray-900">
            {primaryLabel}
          </Heading>
          {inlineLabel && <span className="ml-1 text-sm font-normal leading-5 text-gray-500">{inlineLabel}</span>}
          {inlineBadge && (
            <div className="ml-2 min-w-0">
              <Badge label={inlineBadge} theme={THEME.BLUE} truncate />
            </div>
          )}
        </div>
        <p className="text-gray-500">{secondaryLabel}</p>
      </header>
    </article>
  );
};
