import { IconButton } from '@shape-construction/arch-ui';
import type { IconButtonProps } from '@shape-construction/arch-ui/src/Button/IconButton/IconButton';
import { ArrowRightIcon } from '@shape-construction/arch-ui/src/Icons/solid';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export interface ForwardNavigationButtonProps {
  color?: IconButtonProps['color'];
}

export const ForwardNavigationButton: React.FC<ForwardNavigationButtonProps> = ({ color = 'white' }) => {
  const navigate = useNavigate();

  const goForward = () => {
    navigate(1);
  };

  return (
    <IconButton
      color={color}
      variant="text"
      aria-label="forward button"
      onClick={goForward}
      icon={ArrowRightIcon}
      size="xs"
    />
  );
};
