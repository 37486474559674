import React, { useCallback, useState } from 'react';
import { getChildByTypeDeep } from 'react-nanny';
import { twMerge } from 'tailwind-merge';
import { Card, type CardProps } from '../Card/Card';
import { CollapsableCardActions } from './CollapsableCardActions';
import { CollapsableCardContent } from './CollapsableCardContent';
import { CollapsableCardHeader } from './CollapsableCardHeader';
import { CollapsableCardSubHeader } from './CollapsableCardSubHeader';

type Components = {
  Header: typeof CollapsableCardHeader;
  Subheader: typeof CollapsableCardSubHeader;
  Content: typeof CollapsableCardContent;
  Actions: typeof CollapsableCardActions;
};

export type CollapsableCardChildrenProps = {
  /** Is collapsable card open */
  isOpen: boolean;
};

export type CollapsableCardProps = Omit<Partial<CardProps>, 'children'> & {
  /** Renders the card as open the first time */
  openByDefault?: boolean;
  children: (props: CollapsableCardChildrenProps) => React.ReactNode;
};

export const CollapsableCard: React.FC<CollapsableCardProps> & Components = ({
  openByDefault = false,
  children,
  className,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(openByDefault);

  const toggleCollapse = useCallback(() => setIsOpen((open) => !open), []);

  const childrenNode = children({ isOpen });
  const header = getChildByTypeDeep(childrenNode, CollapsableCardHeader);
  const subheader = getChildByTypeDeep(childrenNode, CollapsableCardSubHeader);
  const content = getChildByTypeDeep(childrenNode, CollapsableCardContent);
  const actions = getChildByTypeDeep(childrenNode, CollapsableCardActions);

  return (
    <Card size="medium" {...props} className={twMerge('rounded-xl shadow-none', className)}>
      <Card.Body>
        <div className="px-4 py-4 text-sm">
          <div className="flex gap-6 min-h-[40px]">
            <button className="w-full block" onClick={toggleCollapse} type="button" aria-expanded={isOpen}>
              {header}
            </button>
            {actions}
          </div>

          {subheader}
        </div>

        {isOpen && content}
      </Card.Body>
    </Card>
  );
};

CollapsableCard.Content = CollapsableCardContent;
CollapsableCard.Header = CollapsableCardHeader;
CollapsableCard.Subheader = CollapsableCardSubHeader;
CollapsableCard.Actions = CollapsableCardActions;
