import classNames from 'clsx';
import React from 'react';

type NavbarButtonProps = React.ComponentPropsWithRef<'button'>;

export const navBarButtonClasses = {
  size: 'rounded-full h-8 w-8',
  color: 'bg-gray-800 p-1 text-gray-400',
  hover: 'hover:bg-gray-700 hover:text-white',
  focus: 'focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-800',
};

export const NavbarButton = React.forwardRef<HTMLButtonElement, NavbarButtonProps>(({ children, ...props }, ref) => (
  <button
    {...props}
    type="button"
    ref={ref}
    className={classNames('relative flex', props.className, Object.values(navBarButtonClasses))}
  >
    {children}
  </button>
));

NavbarButton.displayName = 'NavbarButton';
