import snakeCase from 'lodash.snakecase';

export const formatItemsToSnakeCase = (items?: any[]) => items?.map(formatObjectToSnakeCase);

export const formatObjectToSnakeCase = (obj?: any) => {
  if (!obj) return obj;
  const result: any = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      result[`${snakeCase(key)}`] = formatItemsToSnakeCase(value);
    } else {
      result[`${snakeCase(key)}`] = value;
    }
  });
  return result;
};

export const replaceNullsFromArray = (items?: any[]) => items?.map(replaceNullsFromObject);

export const replaceNullsFromObject = (obj?: any) => {
  if (!obj) return obj;
  if (typeof obj !== 'object') return obj;
  const result: any = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      result[key] = replaceNullsFromArray(value);
    } else if (value === null) {
      result[key] = '';
    } else {
      result[key] = value;
    }
  });
  return result;
};

export const makeOption = (item: string) => ({
  label: item,
  value: item,
});

/**
 * This method has the purpose of retrieving an object with the form values which are "true" in the dirtyValues object.
 * This assumes the usage of react-hook-form library.
 *
 * Example:
 *
 * const values = {
 *  firstField: 'firstField',
 *  secondField: [
 *    { firstSubField: 'qwe', secondSubField: 'fgh', id: 'id1' },
 *    { firstSubField: 'asd', secondSubField: 'vbn', id: 'id2' },
 *    { firstSubField: 'zxc', secondSubField: 'uio', id: 'id3' },
 *    { firstSubField: 'rty', secondSubField: 'hjk', id: 'id4' },
 *  ],
 *  thirdField: 'thirdField',
 * };
 *
 * const secondField = [];
 * secondField[0] = { firstSubField: true, id: 'id1' };
 * secondField[3] = { secondSubField: true, id: 'id4' };
 *
 * const dirtyValues = {
 *   secondField,
 *   thirdField: true,
 * };
 *
 *
 * The result should be
 * {
 *   secondField: [
 *    { firstSubField: 'qwe', id: 'id1' },
 *    { secondSubField: 'hjk', id: 'id4' },
 *   ],
 *   thirdField: 'thirdField',
 * }
 */

type Value = any | any[];
type DirtyValue = any | any[];

export const getDirtyValues = (values: Record<string, Value>, dirtyValuesBoolean: Record<string, DirtyValue>) => {
  const result: Record<string, Value> = {};

  for (const key in dirtyValuesBoolean) {
    if (Object.prototype.hasOwnProperty.call(dirtyValuesBoolean, key)) {
      const dirtyValue = dirtyValuesBoolean[key];
      const currentValue = values[key] as [];

      if (Array.isArray(dirtyValue)) {
        const arrayTemp: Value[] = [];

        dirtyValue.forEach((subField, index) => {
          arrayTemp.push(getDirtyValues(currentValue[index], subField));
        });

        result[key] = arrayTemp;
      } else {
        result[key] = values[key];
      }

      if (values.id) {
        result.id = values.id;
      }
    }
  }

  return result;
};

type CompareObj = any | any[];

export const formValuesDifference = (
  values1: Record<string, CompareObj>,
  values2: Record<string, CompareObj> | undefined
) =>
  onCallbackObjectDifference(values1, values2, (obj1, obj2, key) => {
    if (obj2?.[key] && obj1[key] === obj2[key]) {
      return undefined;
    }

    return obj1[key];
  });

export const onCallbackObjectDifference = (
  obj1: Record<string, CompareObj>,
  obj2: Record<string, CompareObj> | undefined,
  callback: (
    obj1: Record<string, CompareObj>,
    obj2: Record<string, CompareObj> | undefined,
    key: string
  ) => any | undefined
) => {
  const result: Record<string, Value> = {};

  for (const key in obj1) {
    if (Object.prototype.hasOwnProperty.call(obj1, key)) {
      const currentValue = obj1[key];
      const dirtyValue = obj2 ? (obj2[key] as []) : undefined;

      if (Array.isArray(currentValue)) {
        const arrayTemp: Value[] = [];

        currentValue.forEach((subField, index) => {
          arrayTemp.push(
            onCallbackObjectDifference(subField, Array.isArray(dirtyValue) ? dirtyValue[index] : undefined, callback)
          );
        });

        result[key] = arrayTemp;
      } else {
        const res = callback(obj1, obj2, key);

        if (res) {
          result[key] = res;
        }
      }

      if (obj1.id) {
        result.id = obj1.id;
      }
    }
  }

  return result;
};

export const transformFormValueObjectIntoPathname = (formValues: any, dirtyFieldsToMaintain: any, pathPrefix = '') => {
  let result = [] as any[];

  for (const key in formValues) {
    if (Object.prototype.hasOwnProperty.call(formValues, key)) {
      if (key !== 'id') {
        const currentFormValue = formValues[key];
        const dirtyFieldsToMaintainValue = dirtyFieldsToMaintain[key];
        const hasDirtyFieldsToMaintainValue = !!dirtyFieldsToMaintainValue;

        if (hasDirtyFieldsToMaintainValue) {
          if (Array.isArray(currentFormValue)) {
            // eslint-disable-next-line no-loop-func
            currentFormValue.forEach((field: any, currentFormValueIndex: number) => {
              dirtyFieldsToMaintainValue.forEach((dirtyField: any) => {
                if (dirtyField.id === field.id) {
                  result = [
                    ...result,
                    ...transformFormValueObjectIntoPathname(
                      field,
                      dirtyField,
                      `${pathPrefix}${key}.${currentFormValueIndex}.`
                    ),
                  ];
                }
              });
            });
          } else {
            result.push({ pathname: `${pathPrefix}${key}`, value: dirtyFieldsToMaintainValue });
          }
        }
      }
    }
  }

  return result;
};

type PathnameString = `${string}${'' | `.${number}`}`;

export const transformPathnameIntoObjectWithEndValue = (pathname: PathnameString, obj: any, endValue: any) => {
  const pathnameNodes = pathname.split('.');

  return transformPathnameNodesToObjectWithEndValue(pathnameNodes, obj, endValue);
};

const isIndex = (num: string): boolean => Number.isInteger(Number(num));

const transformPathnameNodesToObjectWithEndValue = (nodes: string[], obj: any, endValue: any) => {
  let result: any = {};
  const isEmptyNodes = nodes.length === 0;
  const isLastNode = nodes.length === 1;

  if (!isEmptyNodes) {
    if (isLastNode) {
      if (obj?.id) {
        result.id = obj?.id;
      }

      result[nodes[0]] = endValue;
    } else {
      const [currentNode, nextNode, ...nodesTail] = nodes;

      if (obj?.id) {
        result.id = obj?.id;
      }

      if (isIndex(nextNode)) {
        result[currentNode] = [
          transformPathnameNodesToObjectWithEndValue(nodesTail, obj?.[currentNode]?.[nextNode], endValue),
        ];
      } else {
        result[currentNode] = transformPathnameNodesToObjectWithEndValue(
          [nextNode, ...nodesTail],
          obj?.[currentNode],
          endValue
        );
      }
    }
  } else if (obj?.id) {
    result = { id: obj?.id, ...endValue };
  }

  return result;
};

export const getArrayPathFromArrayItemPath = (arrayItemPath: string) => {
  const pathparts = arrayItemPath.split('.');
  pathparts.pop();

  return pathparts.join('.');
};
