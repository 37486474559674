import { useEffect, useState } from 'react';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'ua-p... Remove this comment to see the full error message
import { UAParser } from 'ua-parser-js';

export const useUserAgent = (uaString?: string) => {
  const [results, setResults] = useState({
    android: false,
    ios: false,
    mobile: false,
    tablet: false,
    windows: false,
    mac: false,
    linux: false,
    computer: false,
    firefox: false,
    chrome: false,
    edge: false,
    safari: false,
    touchDevice: false,
  });

  useEffect(() => {
    const userAgent = new UAParser();

    if (uaString) {
      userAgent.setUA(uaString);
    }

    const uaResults = {
      android: userAgent.getOS().name === 'Android',
      ios: userAgent.getOS().name === 'iOS',
      mobile: userAgent.getDevice().type === 'mobile',
      tablet: userAgent.getDevice().type === 'tablet',
      windows: userAgent.getOS().name === 'Windows',
      mac: userAgent.getOS().name === 'Mac OS',
      linux: userAgent.getOS().name === 'Linux',
      firefox: userAgent.getBrowser().name === 'Firefox',
      chrome: userAgent.getBrowser().name === 'Chrome',
      edge: userAgent.getBrowser().name === 'Edge',
      safari: userAgent.getBrowser().name?.includes('Safari') || false,
      touchDevice: navigator.maxTouchPoints > 1,
    };

    setResults({
      ...uaResults,
      computer: uaResults.windows || uaResults.mac || uaResults.linux || userAgent.getDevice().type === undefined,
    });
  }, [uaString]);

  return results;
};
