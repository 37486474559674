import type { TourPage } from 'app/config/productTour.config';
import { makeJSONStorage } from 'app/lib/json-storage';
import { atomWithStorage } from 'jotai/utils';

export type TipState = {
  /** Whether the tip has been dismissed */
  dismissed: boolean;
};

export type ProductTourRegistry = {
  [key in TourPage]?: Record<string, TipState | undefined>;
};

export const productTourTipRegistryAtom = atomWithStorage<ProductTourRegistry>(
  'product_tour_tips',
  {},
  makeJSONStorage()
);
productTourTipRegistryAtom.debugLabel = 'productTourTipRegistryAtom';
