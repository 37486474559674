import { useSortable } from '@dnd-kit/sortable';
import { createContext, useContext } from 'react';

export const DraggableItemIdCtx = createContext<string | null>(null);

export const useDraggableItem = () => {
  const id = useContext(DraggableItemIdCtx);
  if (id === null) throw new Error('Cant call useDraggableItem outside Draggable.Items');
  const sortable = useSortable({ id });

  return { id, sortable };
};
