import classNames from 'clsx';
import React, { type ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';

const adornmentClasses = {
  default: 'self-stretch flex items-center justify-center text-sm leading-5',
  detached: 'px-3 bg-gray-50 border-gray-300 text-gray-800 opacity-60 font-normal',
  inline: 'text-gray-400 font-medium',
};

export type InputAdornmentProps = ComponentProps<'div'> & {
  variant?: 'detached' | 'inline';
  position?: 'start' | 'end';
};

export const InputAdornment: React.FC<InputAdornmentProps> = ({ variant = 'inline', position = 'start', ...props }) => {
  return (
    <div
      {...props}
      className={twMerge(
        classNames(
          adornmentClasses.default,
          adornmentClasses[variant],
          {
            'border-r': variant === 'detached' && position === 'start',
            'border-l': variant === 'detached' && position === 'end',
            'pl-3': variant === 'inline' && position === 'start',
            'pr-3': variant === 'inline' && position === 'end',
          },
          props.className
        )
      )}
    />
  );
};
InputAdornment.displayName = 'Input.Adornment';
