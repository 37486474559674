import { Listbox, type ListboxOptionProps } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import React, { type ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';
import { type PrimitiveProps, renderChildren } from '../utils/render';

export type SelectOptionTextProps = ComponentProps<'span'>;
export function SelectOptionText({ children, className }: SelectOptionTextProps) {
  return (
    <span className={twMerge('w-full text-gray-800 text-sm leading-5 truncate', className as string)}>{children}</span>
  );
}

export type SelectOptionProps<TValue> = PrimitiveProps<ListboxOptionProps<'li', TValue>>;
export function SelectOption<TValue>({ children, className, ...props }: SelectOptionProps<TValue>) {
  return (
    <Listbox.Option
      className={twMerge(
        'bg-white cursor-pointer w-full min-h-13 md:min-h-9 px-3 py-4 md:py-2 gap-2.5 flex justify-start items-center ',
        'hover:bg-indigo-50 ui-selected:bg-indigo-100 focus-visible:outline-none ui-disabled:opacity-60 ui-disabled:cursor-not-allowed',
        className as string
      )}
      {...props}
    >
      {(state) => (
        <div className="w-full flex flex-row overflow-hidden items-center gap-4">
          <div className="truncate flex-1 min-w-full">{renderChildren(children, state)}</div>
          <div className="w-6">
            {state.selected && <CheckIcon aria-hidden="true" className="w-5 h-5 text-indigo-500" />}
          </div>
        </div>
      )}
    </Listbox.Option>
  );
}

SelectOption.displayName = 'Select.Option';
