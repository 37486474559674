import { useCookieConsent } from 'app/analytics/hooks/useCookieConsent';
import { trackerTypes } from 'app/analytics/trackingConfig';
import { environment } from 'app/config/environment';
import { useEffect, useRef, useState } from 'react';

export const useUseberry = () => {
  const { consentData } = useCookieConsent();
  const [isInitialized, setIsInitialized] = useState(false);
  const singletonRef = useRef(false);

  useEffect(() => {
    const shouldInitialize = !singletonRef.current && environment.USEBERRY && consentData?.[trackerTypes.useberry];

    if (shouldInitialize) {
      const script = document.createElement('script');
      script.type = 'text/plain';
      script.src = 'https://api.useberry.com/integrations/liveUrl/scripts/useberryScript.js';
      script.setAttribute('data-cookieconsent', trackerTypes.useberry);
      document.body.appendChild(script);
      setIsInitialized(true);
      singletonRef.current = true;
    }
  }, [consentData]);

  return { isInitialized };
};
