import type { BooleanFlagConfig, FlagConfig, VariantFlagConfig } from '../types';

type ResovleConfig<Cfg extends Record<string, FlagConfig<any>>> = keyof Cfg extends never
  ? Record<'', FlagConfig<any>>
  : Cfg;

export const config = <Cfg extends Record<string, FlagConfig<any>>>(cfg: Cfg): ResovleConfig<Cfg> => cfg;

const flagDefaultConfig: Partial<FlagConfig<any>> = {
  target: 'user',
  allowSessionFallback: false,
};

export const booleanFlag = (flagConfig: BooleanFlagConfig): BooleanFlagConfig => ({
  default: false,
  ...flagDefaultConfig,
  ...flagConfig,
});

export const variantFlag = <Type extends string>(
  flagConfig: VariantFlagConfig<Type | ''>
): VariantFlagConfig<Type | ''> => ({
  default: '',
  ...flagDefaultConfig,
  ...flagConfig,
});
