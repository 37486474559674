import { ErrorBoundary } from '@sentry/react';
import { Bars3Icon } from '@shape-construction/arch-ui/src/Icons/solid';
import { useSidebar } from '@shape-construction/arch-ui/src/Sidebar';
import { breakpoints } from '@shape-construction/arch-ui/src/utils/breakpoints';
import { useMediaQuery } from '@shape-construction/hooks';
import { useQuery } from '@tanstack/react-query';
import { HelpCenterWidget } from 'app/components/HelpCenter/HelpCenterWidget';
import { ProjectsSelector } from 'app/components/ProjectsSelector/ProjectsSelector';
import { TrialBar } from 'app/components/SubscriptionPlanFeatures/Trial/TrialBar';
import { useLayoutContext } from 'app/contexts/layout/layoutContext';
import { useDistanceToBottom } from 'app/hooks/useDistanceToBottom';
import { getProjectQueryOptions } from 'app/queries/projects/projects';
import { useCurrentUser } from 'app/queries/users/users';
import classNames from 'clsx';
import { Suspense } from 'react';
import { Link, useMatch } from 'react-router-dom';
import { usePostLoginRedirect } from '../../../hooks/usePostLoginRedirect';
import { QuickNewButton } from '../../QuickNewButton/QuickNewButton';
import ShapeLogo from '../ShapeLogo/ShapeLogo';
import { BackNavigationButton } from './components/BackNavigationButton';
import { ForwardNavigationButton } from './components/ForwardNavigationButton';
import { Notifications } from './components/Notifications';
import { ProfileMenu } from './components/ProfileMenu';
import { ProjectSetupProgressNavbar } from './components/ProjectSetupProgressNavbar/ProjectSetupProgressNavbar';
import { ReloadButton } from './components/ReloadButton';
import { SearchField } from './components/SearchField';

export const Navbar = () => {
  const { defaultProject } = useCurrentUser();
  const {
    layoutConfig: { showSideDrawerMenu, showBackButton, showTitle, showProjectList, title },
  } = useLayoutContext();
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));
  const projectRoute = useMatch('/projects/:projectId/*');
  const currentProjectId = projectRoute?.params?.projectId ?? defaultProject;
  const isProjectRoute = Boolean(currentProjectId);
  const { data: currentProject } = useQuery(getProjectQueryOptions(currentProjectId!));
  const { ref: parentRef, distanceToBottom } = useDistanceToBottom<HTMLDivElement>();
  const isQuickNewButtonVisible = currentProjectId && isLargeScreen;
  const { postLoginPath } = usePostLoginRedirect();
  const landingPageLink = currentProject ? `/projects/${currentProject?.id}/timeline` : postLoginPath;

  const { toggleSidebar } = useSidebar();

  const renderDrawerMenu = () => (
    <div className="block md:hidden">
      <Bars3Icon data-testid="res-drawer-btn" onClick={() => toggleSidebar()} className="cursor-pointer h-6 w-6" />
    </div>
  );

  return (
    <div
      data-cy="navbar"
      data-testid="navbar"
      data-mode="dark"
      className="sticky shadow-none whitespace-nowrap z-navigation"
      ref={parentRef}
    >
      <TrialBar />
      <div className="flex justify-between bg-gray-800 text-white px-3 md:px-8 min-h-[56px] md:min-h-[64px]">
        <div className="flex items-center w-full gap-x-2 md:gap-x-4 min-w-0">
          {showSideDrawerMenu && renderDrawerMenu()}
          {showBackButton && currentProjectId && <BackNavigationButton />}
          <div
            className={classNames('shrink-0 w-auto md:mr-2', {
              block: !showSideDrawerMenu,
              'hidden md:block': showSideDrawerMenu,
            })}
          >
            <Link to={landingPageLink}>
              <ShapeLogo whiteVersion withLettering />
            </Link>
          </div>
          <div className="hidden md:flex">
            {currentProjectId && (
              <>
                <BackNavigationButton />
                <ForwardNavigationButton />
              </>
            )}
            <ReloadButton />
          </div>
          {showProjectList && (
            <div className="relative flex w-full overflow-hidden md:hidden">
              <ProjectsSelector currentProject={currentProject} isExpanded className="text-white hover:bg-gray-900" />
            </div>
          )}
          {showTitle && title && (
            <h4 className="relative inline-block overflow-hidden text-ellipsis whitespace-nowrap text-left">{title}</h4>
          )}
          <SearchField />
        </div>

        <div className="flex items-center">
          {currentProjectId && currentProject && isProjectRoute && (
            <div className="hidden w-40 mr-8 lg:block">
              <ErrorBoundary fallback={undefined}>
                <Suspense fallback={null}>
                  <ProjectSetupProgressNavbar projectId={currentProjectId} />
                </Suspense>
              </ErrorBoundary>
            </div>
          )}
          {isQuickNewButtonVisible && <QuickNewButton projectId={currentProjectId} />}
          <HelpCenterWidget />
          <Notifications distanceToBottomOfScreen={distanceToBottom} />
          <ProfileMenu />
        </div>
      </div>
    </div>
  );
};
