import { Popover as PopoverPrimitive } from '@headlessui/react';
import classNames from 'clsx';
import React, { type ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';
import { Divider } from '../Divider/Divider';
import { renderChildren } from '../utils/render';

export const ActionsDrawerRoot = PopoverPrimitive;
ActionsDrawerRoot.displayName = 'ActionsDrawer';

export const ActionsDrawerTrigger = PopoverPrimitive.Button;
ActionsDrawerTrigger.displayName = 'ActionsDrawer.Trigger';

export const ActionsDrawerPanel = ({ children, ...props }: ComponentProps<typeof PopoverPrimitive.Panel>) => {
  return (
    <PopoverPrimitive.Panel {...props}>
      {(state) => {
        if (state.open) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'unset';

        return (
          <>
            <PopoverPrimitive.Overlay
              data-testid="actions-drawer-overlay"
              className="z-popover fixed inset-0 bg-gray-800 bg-opacity-75 backdrop-filter overflow-none"
            />
            <div
              data-cy="actions-drawer"
              className="z-popover fixed left-0 bottom-0 w-full rounded-t-lg bg-white px-3 pt-3 pb-6 shadow-lg sm:pb-3"
            >
              {renderChildren(children, state)}
            </div>
          </>
        );
      }}
    </PopoverPrimitive.Panel>
  );
};

export const ActionsDrawerHeader = ({ children, ...props }: ComponentProps<'div'>) => {
  return (
    <div {...props}>
      {children}
      <Divider orientation="horizontal" />
    </div>
  );
};
ActionsDrawerHeader.displayName = 'ActionsDrawer.Header';

export const ActionsDrawerTitle = ({ className, children, ...props }: ComponentProps<'h2'>) => {
  return (
    <h2 className={twMerge('mt-1 mb-3 text-base text-center font-medium leading-6', className)} {...props}>
      {children}
    </h2>
  );
};
ActionsDrawerTitle.displayName = 'ActionsDrawer.Title';

export const ActionsDrawerContent = ({ className, ...props }: ComponentProps<'div'>) => {
  return (
    <div
      className={twMerge(
        classNames('flex flex-wrap mx-auto max-w-sm py-4 px-2 gap-x-16 gap-y-8 justify-center'),
        className
      )}
      {...props}
    />
  );
};
ActionsDrawerContent.displayName = 'ActionsDrawer.Content';

export const ActionsDrawerItem = PopoverPrimitive.Button;
ActionsDrawerItem.displayName = 'ActionsDrawer.Item';
