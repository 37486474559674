import * as React from 'react';
import type { SVGProps } from 'react';
const DragIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/drag">
      <g id="Icon">
        <path
          d="M15 3.59998C15.9941 3.59998 16.8 4.40586 16.8 5.39998C16.8 6.39409 15.9941 7.19998 15 7.19998C14.0058 7.19998 13.2 6.39409 13.2 5.39998C13.2 4.40586 14.0058 3.59998 15 3.59998Z"
          fill="currentColor"
        />
        <path
          d="M15 10.2C15.9941 10.2 16.8 11.0059 16.8 12C16.8 12.9941 15.9941 13.8 15 13.8C14.0058 13.8 13.2 12.9941 13.2 12C13.2 11.0059 14.0058 10.2 15 10.2Z"
          fill="currentColor"
        />
        <path
          d="M16.8 18.6C16.8 17.6059 15.9941 16.8 15 16.8C14.0058 16.8 13.2 17.6059 13.2 18.6C13.2 19.5941 14.0058 20.4 15 20.4C15.9941 20.4 16.8 19.5941 16.8 18.6Z"
          fill="currentColor"
        />
        <path
          d="M8.99995 3.59998C9.99406 3.59998 10.8 4.40586 10.8 5.39998C10.8 6.39409 9.99406 7.19998 8.99995 7.19998C8.00584 7.19998 7.19995 6.39409 7.19995 5.39998C7.19995 4.40586 8.00584 3.59998 8.99995 3.59998Z"
          fill="currentColor"
        />
        <path
          d="M8.99995 10.2C9.99406 10.2 10.8 11.0059 10.8 12C10.8 12.9941 9.99406 13.8 8.99995 13.8C8.00584 13.8 7.19995 12.9941 7.19995 12C7.19995 11.0059 8.00584 10.2 8.99995 10.2Z"
          fill="currentColor"
        />
        <path
          d="M10.8 18.6C10.8 17.6059 9.99406 16.8 8.99995 16.8C8.00584 16.8 7.19995 17.6059 7.19995 18.6C7.19995 19.5941 8.00584 20.4 8.99995 20.4C9.99406 20.4 10.8 19.5941 10.8 18.6Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
export default DragIcon;
