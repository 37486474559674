import { useMessage, useMessageGetter } from '@messageformat/react';
import { ActionsDrawer, Button, FileUpload, IconButton, Menu } from '@shape-construction/arch-ui';
import {
  ChevronDownIcon,
  FolderIcon as OutlineFolderIcon,
  PlusIcon,
} from '@shape-construction/arch-ui/src/Icons/outline';
import {
  ArrowUpIcon,
  CameraIcon,
  DocumentTextIcon,
  PhotoIcon,
  FolderIcon as SolidFolderIcon,
} from '@shape-construction/arch-ui/src/Icons/solid';
import { breakpoints } from '@shape-construction/arch-ui/src/utils/breakpoints';
import { isTouchDevice } from '@shape-construction/arch-ui/src/utils/devices';
import { useMediaQuery } from '@shape-construction/hooks';
import { ProjectGalleryPicker } from 'app/components/MediaPicker/ProjectGalleryPicker';
import React, { type PropsWithChildren, forwardRef, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

type Params = {
  projectId: string;
};

type BaseOption = {
  accept: string;
  enabled: boolean;
  onSelectFiles: (files: File[]) => void;
  multiple?: boolean;
};
type CameraOption = Omit<BaseOption, 'accept'>;
type GalleryOption = BaseOption;
type DocumentsOption = BaseOption;
type ProjectGalleryOption = Omit<BaseOption, 'onSelectFiles'> & {
  kind?: 'image' | 'file';
  excluded?: string[];
  onSelectFiles: (documentIds: string[]) => void;
};

export type MediaPickerOptions = {
  camera?: CameraOption;
  gallery?: GalleryOption;
  documents?: DocumentsOption;
  projectGallery?: ProjectGalleryOption;
};

export type MediaPickerProps = PropsWithChildren<{
  options: MediaPickerOptions;
}>;

export const MediaPickerTrigger = forwardRef<React.ElementRef<'button'>>((_, ref) => {
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));
  const triggerTitle = useMessage('mediaGallery.upload.trigger.title');

  if (isLargeScreen)
    return (
      <Button ref={ref} variant="outlined" color="primary" size="sm">
        {triggerTitle}
        <ChevronDownIcon className="w-5 h-5" />
      </Button>
    );
  return (
    <Button ref={ref} leadingIcon={PlusIcon} variant="outlined" color="primary" size="sm">
      {triggerTitle}
    </Button>
  );
});

export const MediaPicker = ({ children = <MediaPickerTrigger />, options }: MediaPickerProps) => {
  const messageGetter = useMessageGetter('mediaGallery');
  const { projectId } = useParams() as Params;
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));
  const [openGalleryPicker, setOpenGalleryPicker] = useState(false);
  const documentOptionRef = useRef<React.ElementRef<typeof FileUpload.Root>>(null);
  const galleryOptionRef = useRef<React.ElementRef<typeof FileUpload.Root>>(null);
  const cameraOptionRef = useRef<React.ElementRef<typeof FileUpload.Root>>(null);

  const galleryPickerModal = (
    <ProjectGalleryPicker
      open={openGalleryPicker}
      projectId={projectId}
      excluded={options.projectGallery?.excluded}
      projectDocumentsParams={{ kind: options.projectGallery?.kind }}
      onClose={() => setOpenGalleryPicker(false)}
      multiple={options.projectGallery?.multiple}
      onSubmit={(documentIds) => options.projectGallery?.onSelectFiles(documentIds)}
    />
  );

  const isOptionEnabled = (key: keyof MediaPickerOptions) => {
    return options[key]?.enabled;
  };

  if (isLargeScreen) {
    return (
      <div>
        <Menu.Root>
          <Menu.Trigger as="div">{children}</Menu.Trigger>
          <Menu.Items unmount={false} className="md:w-62">
            {isOptionEnabled('documents') && (
              <button type="button" className="w-full" onClick={() => documentOptionRef.current?.click()}>
                <Menu.Item as="div" icon={ArrowUpIcon}>
                  <FileUpload.Root
                    ref={documentOptionRef}
                    accept={options.documents?.accept}
                    onChange={options.documents?.onSelectFiles}
                    multiple={options.documents?.multiple}
                    className="flex flex-row gap-2 items-center justify-start"
                  >
                    <FileUpload.Label>{messageGetter('upload.options.documents.title')}</FileUpload.Label>
                  </FileUpload.Root>
                </Menu.Item>
              </button>
            )}
            {isOptionEnabled('gallery') && (
              <button type="button" className="w-full" onClick={() => galleryOptionRef.current?.click()}>
                <Menu.Item as="div" icon={PhotoIcon}>
                  <FileUpload.Root
                    ref={galleryOptionRef}
                    accept={options.gallery?.accept}
                    onChange={options.gallery?.onSelectFiles}
                    multiple={options.gallery?.multiple}
                    className="flex flex-row gap-2 items-center justify-start"
                  >
                    <FileUpload.Label>{messageGetter('upload.options.gallery.title')}</FileUpload.Label>
                  </FileUpload.Root>
                </Menu.Item>
              </button>
            )}

            {isOptionEnabled('camera') && isTouchDevice() && (
              <button type="button" className="w-full" onClick={() => cameraOptionRef.current?.click()}>
                <Menu.Item as="div" icon={CameraIcon}>
                  <FileUpload.Root
                    ref={cameraOptionRef}
                    accept="image/*"
                    capture
                    onChange={options.camera?.onSelectFiles}
                    multiple={options.camera?.multiple}
                    className="flex flex-row gap-2 items-center justify-start"
                  >
                    <FileUpload.Label>{messageGetter('upload.options.camera.title')}</FileUpload.Label>
                  </FileUpload.Root>
                </Menu.Item>
              </button>
            )}
            {isOptionEnabled('projectGallery') && (
              <button type="button" className="w-full" onClick={() => setOpenGalleryPicker(true)}>
                <Menu.Item as="div" icon={SolidFolderIcon}>
                  {messageGetter('upload.options.projectGallery.title')}
                </Menu.Item>
              </button>
            )}
          </Menu.Items>
        </Menu.Root>
        {galleryPickerModal}
      </div>
    );
  }

  return (
    <>
      <ActionsDrawer.Root>
        <ActionsDrawer.Trigger as="div">{children}</ActionsDrawer.Trigger>
        <ActionsDrawer.Panel unmount={false}>
          <ActionsDrawer.Content role="menu" className="flex flex-col justify-center py-4 gap-4">
            <div className="flex flex-col justify-center items-center gap-2">
              <h2 className="text-gray-800 text-base font-medium leading-normal">Upload</h2>
              <div className="px-4 py-2 flex flex-row flex-wrap items-center">
                {isOptionEnabled('gallery') && (
                  <ActionsDrawer.Item as="div" role="menuitem" className="w-[94px]">
                    <FileUpload.Root
                      accept={options.gallery?.accept}
                      onChange={options.gallery?.onSelectFiles}
                      multiple={options.gallery?.multiple}
                      className="flex flex-col items-center gap-2"
                    >
                      <FileUpload.Trigger asChild>
                        <IconButton color="danger" variant="contained" icon={PhotoIcon} size="xl" />
                      </FileUpload.Trigger>
                      <FileUpload.Label className="text-gray-800 text-xs font-medium leading-none">
                        {messageGetter('upload.options.gallery.short_title')}
                      </FileUpload.Label>
                    </FileUpload.Root>
                  </ActionsDrawer.Item>
                )}
                {isOptionEnabled('camera') && isTouchDevice() && (
                  <ActionsDrawer.Item as="div" className="w-[94px]" role="menuitem">
                    <FileUpload.Root
                      accept="image/*"
                      capture
                      onChange={options.camera?.onSelectFiles}
                      multiple={options.camera?.multiple}
                      className="flex flex-col items-center gap-2"
                    >
                      <FileUpload.Trigger asChild>
                        <IconButton color="primary" variant="contained" icon={CameraIcon} size="xl" />
                      </FileUpload.Trigger>
                      <FileUpload.Label className="text-gray-800 text-xs font-medium leading-none">
                        {messageGetter('upload.options.camera.short_title')}
                      </FileUpload.Label>
                    </FileUpload.Root>
                  </ActionsDrawer.Item>
                )}
                {isOptionEnabled('documents') && (
                  <ActionsDrawer.Item as="div" role="menuitem" className="w-[94px]">
                    <FileUpload.Root
                      accept={options.documents?.accept}
                      onChange={options.documents?.onSelectFiles}
                      multiple={options.documents?.multiple}
                      className="flex flex-col items-center gap-2"
                    >
                      <FileUpload.Trigger asChild>
                        <IconButton color="success" variant="contained" icon={DocumentTextIcon} size="xl" />
                      </FileUpload.Trigger>
                      <FileUpload.Label className="text-gray-800 text-xs font-medium leading-none">
                        {messageGetter('upload.options.documents.short_title')}
                      </FileUpload.Label>
                    </FileUpload.Root>
                  </ActionsDrawer.Item>
                )}
              </div>
            </div>
            {isOptionEnabled('projectGallery') && (
              <div className="flex flex-col justify-center items-center gap-2">
                <h2 className="text-gray-800 text-base font-medium leading-normal">Attach</h2>
                <div className="px-4 py-2 flex flex-row flex-wrap items-center">
                  <ActionsDrawer.Item as="div" role="menuitem" className="w-[94px] flex flex-col items-center gap-2">
                    <IconButton
                      color="secondary"
                      variant="contained"
                      icon={OutlineFolderIcon}
                      size="xl"
                      onClick={() => setOpenGalleryPicker(true)}
                      aria-label={messageGetter('upload.options.projectGallery.short_title')}
                    />
                    <p className="text-gray-800 text-xs font-medium leading-none">
                      {messageGetter('upload.options.projectGallery.short_title')}
                    </p>
                  </ActionsDrawer.Item>
                </div>
              </div>
            )}
          </ActionsDrawer.Content>
        </ActionsDrawer.Panel>
      </ActionsDrawer.Root>

      {galleryPickerModal}
    </>
  );
};
