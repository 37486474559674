import * as React from 'react';
import type { SVGProps } from 'react';
const RvtIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={44} viewBox="0 0 40 44" fill="none" aria-hidden="true" {...props}>
    <g id="file-icon/rvt">
      <g id="Vector" filter="url(#filter0_dd_3816_38113)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6777 41C27.0141 41 13.7443 41 7.3267 41C5.85395 41 4.66669 39.82 4.66669 38.3473C4.66669 30.3468 4.66669 10.7752 4.66669 3.6498C4.66669 2.17704 5.86059 1 7.33335 1H26.2222Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6777 41C27.0141 41 13.7443 41 7.3267 41C5.85395 41 4.66669 39.82 4.66669 38.3473C4.66669 30.3468 4.66669 10.7752 4.66669 3.6498C4.66669 2.17704 5.86059 1 7.33335 1H26.2222Z"
          fill="url(#paint0_radial_3816_38113)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L26.2224 7.44444C26.2224 8.9172 27.4163 10.1111 28.889 10.1111L35.3333 10.1111L26.2222 1Z"
          fill="#F3F4F6"
        />
      </g>
      <g id="Vector_2">
        <path
          d="M23.9333 25.4819C23.9333 23.8499 26.0222 23.5235 26.0222 19.9333C26.0222 16.954 24.4738 15.1142 21.6486 14.2541C20.0166 13.7575 17.8719 13.6666 15.5778 13.6666C14.5333 13.6666 12.4445 13.6666 12.4445 13.6666V23.5235L15.0556 26.1999H19.2333L24.4555 31.9443L29.1555 34.5554C29.1555 34.5554 23.9333 27.0485 23.9333 25.4819ZM20.8 19.9333C20.8 21.0869 19.8647 22.0222 18.7111 22.0222H18.1889C17.6118 22.0222 17.1444 21.5548 17.1444 20.9777V18.8888C17.1444 18.3118 17.6118 17.8444 18.1889 17.8444H19.2333C20.0986 17.8444 20.8 18.5457 20.8 19.4111V19.9333Z"
          fill="#1976D2"
        />
        <path
          d="M20.7955 19.9724C20.7741 21.1077 19.8508 22.0221 18.7108 22.0221C18.7108 22.0221 18.5802 22.5444 17.6011 23.4583C17.0611 23.9622 15.5775 24.6333 15.5775 24.6333C15.5775 24.6333 21.372 25.016 22.9914 21.7981C22.7204 20.866 21.9501 20.1401 20.7955 19.9724Z"
          fill="#1565C0"
        />
        <path
          d="M19.7551 16.2777H17.6663C16.8009 16.2777 16.0996 16.9791 16.0996 17.8444V19.4111C16.0996 20.7359 16.9822 21.8185 17.9352 21.9877C17.4814 21.8738 17.144 21.4665 17.144 20.9777V18.8888C17.144 18.3118 17.6114 17.8444 18.1885 17.8444H19.2329C20.0982 17.8444 20.7996 18.5457 20.7996 19.4111V19.9333C20.7996 19.9469 20.796 19.9594 20.7955 19.9724C21.9501 20.1401 22.7198 20.8654 22.9908 21.7976C23.254 21.2759 23.4107 20.6623 23.4107 19.9333C23.4107 17.9144 21.774 16.2777 19.7551 16.2777Z"
          fill="#1950A6"
        />
        <path
          d="M17.6667 16.2777C16.8013 16.2777 16.1 16.9791 16.1 17.8444V19.4111C16.1 20.7359 16.9826 21.8185 17.9356 21.9877C17.4818 21.8738 17.1444 21.4665 17.1444 20.9777V18.8888C17.1444 18.3118 17.6118 17.8444 18.1889 17.8444H19.2333C20.0986 17.8444 20.8 18.5457 20.8 19.4111V19.9333C20.8 21.0869 19.8647 22.0222 18.7111 22.0222C18.7111 22.0222 21.8444 22.0222 21.8444 18.8888V18.3687C21.8444 17.2511 20.9666 16.3075 19.8495 16.2788C19.8182 16.2783 19.7869 16.2777 19.7555 16.2777H17.6667ZM12.4445 13.6666V33.511L14.5333 34.5554L15.5778 24.6333C15.5778 24.6333 12.4445 23.0666 12.4445 13.6666Z"
          fill="#0D47A1"
        />
        <path
          d="M14.5337 34.5555L15.5781 24.6333C15.5781 24.6333 18.7114 24.6333 20.2781 25.6777C20.959 26.1321 22.8516 28.3432 24.5864 30.3125C27.0017 33.0541 29.1558 34.5555 29.1558 34.5555C29.1558 34.5555 25.5656 34.2944 24.1357 34.0938C23.6788 34.0296 23.2568 33.8171 22.9894 33.4411C22.2066 32.3402 20.8003 29.5944 18.7114 27.5055C18.5887 27.3828 18.4503 27.247 18.1892 27.247C17.9234 27.247 17.667 27.4543 17.667 27.7719V33.1345C17.667 33.3591 17.5234 33.5591 17.3098 33.6301L14.5337 34.5555Z"
          fill="#3AA2DB"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_dd_3816_38113"
        x={2.66669}
        y={0}
        width={34.6667}
        height={44}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3816_38113" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_3816_38113" result="effect2_dropShadow_3816_38113" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3816_38113" result="shape" />
      </filter>
      <radialGradient
        id="paint0_radial_3816_38113"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20 0.999999) rotate(90) scale(40 40.1825)"
      >
        <stop offset={0.297418} stopOpacity={0} />
        <stop offset={1} stopOpacity={0.03} />
      </radialGradient>
    </defs>
  </svg>
);
export default RvtIcon;
