import { useMessage, useMessageGetter } from '@messageformat/react';
import type { LocationSchema, ProjectSchema } from '@shape-construction/api/model';
import {
  Button,
  Drawer,
  InputAdornment,
  SearchField,
  Select,
  type SelectRootProps,
  Tree,
} from '@shape-construction/arch-ui';
import { SkeletonText } from '@shape-construction/arch-ui/src/Skeleton';
import { createItem } from '@shape-construction/arch-ui/src/Tree/tree-utils';
import { NoSearchResults } from 'app/components/Search/NoSearchResults';
import { rootId } from 'app/components/Utils/locations';
import { useModal } from 'app/hooks/useModal';
import { useProjectLocationsTree } from 'app/pages/projects/[projectId]/settings/locations/hooks/useProjectLocationsTree';
import React, { forwardRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

type Params = { projectId: ProjectSchema['id'] };
type LocationsSelectProps = SelectRootProps<LocationSchema['id'][], true>;

const LocationSelectLoading = () => (
  <div className="flex flex-col gap-4 px-2">
    <SkeletonText size="md" animation="pulse" />
    <SkeletonText size="md" animation="pulse" />
    <SkeletonText size="md" animation="pulse" />
  </div>
);

const initialValue: string[] = [];

export const LocationsSelect = forwardRef<React.ElementRef<typeof Select.Root>, LocationsSelectProps>(
  ({ value = initialValue, onChange, ...props }, ref) => {
    const messageGetter = useMessageGetter('projectGallery.filters.locations');
    const { projectId } = useParams() as Params;
    const { open, openModal, closeModal } = useModal(false);
    const [locationTerm, setLocationTerm] = useState('');
    const [checked, setChecked] = useState(value);
    const { locationsTree, isLoading } = useProjectLocationsTree(
      projectId,
      {
        include_parents: true,
        search: locationTerm,
      },
      {
        enabled: open,
      }
    );

    const locationsList =
      locationTerm.length > 0
        ? locationsTree
        : [
            createItem({
              id: '',
              text: messageGetter('options.noLocation'),
              parent: rootId,
              data: null,
            }),
            ...locationsTree,
          ];

    const renderLocations = () => {
      if (isLoading) return <LocationSelectLoading />;
      if (locationsTree.length === 0) return <NoSearchResults />;

      return (
        <Tree
          rootId="root"
          data={locationsList}
          enableDrag={false}
          checkedOptions={checked}
          onCheck={(values) => setChecked(values as LocationSchema['id'][])}
        />
      );
    };

    useEffect(() => {
      if (value) setChecked(value);
    }, [open, value]);

    useEffect(() => {
      if (!open) setLocationTerm('');
    }, [open]);

    return (
      <Select.Root ref={ref} value={checked} onChange={setChecked} multiple {...props}>
        <Select.Trigger
          as="div"
          variant="bordered"
          size="sm"
          onClick={openModal}
          startAdornment={<InputAdornment>{messageGetter('label')}</InputAdornment>}
        >
          <Select.MultipleValue value={value} condensed />
        </Select.Trigger>
        <Drawer open={open} onClose={closeModal}>
          <Drawer.Header onClose={closeModal}>
            <Drawer.Title>{messageGetter('title')}</Drawer.Title>
          </Drawer.Header>
          <Drawer.Content>
            <div className="px-4 pt-2">
              <SearchField
                placeholder={messageGetter('placeholder')}
                onChange={(event) => setLocationTerm(event.target.value)}
              />
            </div>
            <div className="h-full py-4 px-2">{renderLocations()}</div>
          </Drawer.Content>
          <Drawer.Footer>
            <div className="flex gap-x-6 text-sm font-medium leading-5">
              <Button color="secondary" variant="outlined" size="sm" onClick={closeModal}>
                {useMessage('actions.cancel')}
              </Button>
              <Button
                color="primary"
                variant="contained"
                size="sm"
                onClick={() => {
                  onChange?.(checked);
                  closeModal();
                }}
              >
                {useMessage('actions.save')}
              </Button>
            </div>
          </Drawer.Footer>
        </Drawer>
      </Select.Root>
    );
  }
);
