import { useMessageGetter } from '@messageformat/react';
import type { ProjectSchema } from '@shape-construction/api/model';
import { showErrorToast } from '@shape-construction/arch-ui/src/Toast/toasts';
import type { DocumentBody, DocumentPayload } from 'app/components/Gallery/types';
import PayloadValidation, { MAX_FILE_SIZE_IN_BYTES } from 'app/components/Gallery/utils/payloadValidation';
import { MIN_FILE_SIZE_IN_BYTES } from 'app/components/Utils/FileInput/validation';
import UtilsStats from 'app/components/Utils/UtilsStats';
import { useUploadProjectDocument } from 'app/queries/projectDocuments/projectDocuments';

export const useProjectDocumentsUploadWorkflow = (projectId: ProjectSchema['id']) => {
  const errorMessages = useMessageGetter('issue.detail.media.tabs.errors');

  const { mutateAsync: uploadProjectDocumentAsync } = useUploadProjectDocument(projectId);

  const handleUploadDocuments = (items: DocumentPayload[]) => {
    const { validFiles, validationErrors } = new PayloadValidation().validateInput(items);

    validationErrors.forEach(({ filename, error }) => {
      switch (error) {
        case 'file_too_big':
          showErrorToast({
            message: errorMessages('file_too_big', {
              filename,
              max: MAX_FILE_SIZE_IN_BYTES / (1024 * 1024),
            }),
          });
          break;

        case 'file_size_zero':
          showErrorToast({
            message: errorMessages('file_size_zero', {
              filename,
              min: MIN_FILE_SIZE_IN_BYTES,
            }),
          });
          break;
      }
    });

    const promises = validFiles.map((payload) =>
      uploadProjectDocumentAsync({
        ...payload,
        temporaryId: UtilsStats.uuidv4(),
      })
    );

    return Promise.all(promises);
  };

  const uploadDocuments = async (files: File[], body: DocumentBody) => {
    if (files.length === 0) return [];

    const items: DocumentPayload[] = files.map((file) => ({ file, body }));

    return handleUploadDocuments(items).catch(() => []);
  };

  return {
    uploadDocuments,
  };
};
