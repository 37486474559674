import classNames from 'clsx';
import type { DividerProps } from './Divider';

const dividerOrientationClassesMap: Record<NonNullable<DividerProps['orientation']>, string> = {
  vertical:
    'h-full flex-col ' +
    'before:border-l before:border-l-gray-200  before:left-[50%] before:translate-x-0 ' +
    'after:border-l after:border-l-gray-200  after:left-[50%] after:translate-l-0',
  horizontal:
    'w-full ' +
    'before:border-t before:border-t-gray-200  before:top-[50%] before:translate-y-[50%] ' +
    'after:border-t after:border-t-gray-200  after:top-[50%] after:translate-y-[50%]',
};

const dividerOrientationVariantClassesMap: Record<
  NonNullable<DividerProps['orientation']>,
  Record<NonNullable<DividerProps['variant']>, string>
> = {
  vertical: {
    start: 'before:h-6 after:h-full',
    middle: 'before:h-full after:h-full',
    end: 'before:h-full after:h-6',
  },
  horizontal: {
    start: 'before:w-6 after:w-full',
    middle: 'before:w-full after:w-full',
    end: 'before:w-full after:w-6',
  },
};

const dividerLineClassesMap: Record<NonNullable<DividerProps['line']>, string> = {
  solid: 'before:border-solid after:border-solid',
  dashed: 'before:border-dashed after:border-dashed',
};

export const getDividerClasses = ({ orientation, variant = 'start', line = 'solid' }: DividerProps) =>
  classNames(
    'flex whitespace-nowrap  text-center w-full text-gray-400 text-sm leading-5 font-normal',
    'before:content-[" "] before:relative after:content-[" "] after:relative',
    dividerOrientationClassesMap[orientation],
    dividerOrientationVariantClassesMap[orientation][variant],
    dividerLineClassesMap[line]
  );
