import * as Sentry from '@sentry/react';
import type { DirectUploadTypeSchema } from '@shape-construction/api/model';
import type { AxiosProgressEvent, AxiosRequestConfig } from 'axios';
import { directUpload } from './activestorage';
import { DirectUploadError } from './direct-upload-error';

export type DirectUploadProgressEvent = AxiosProgressEvent;

export const DirectUpload = async (file: File, type: DirectUploadTypeSchema, options?: AxiosRequestConfig) => {
  try {
    const blob = await directUpload(file, type, options);
    return blob.signedId;
  } catch (error) {
    Sentry.captureException(new DirectUploadError(error as Error));
    throw error;
  }
};
