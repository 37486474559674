import { useChatClient } from 'app/channels/get-stream/useChatClient';
import { useProject } from 'app/queries/projects/projects';
import { useCallback, useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';
import type { ChannelFilters, EventHandler } from 'stream-chat';

export type Params = {
  projectId: string;
};

export const getProjectChannelsFilter = (projectId: string, projectStreamTeam: string): ChannelFilters => {
  return {
    $or: [
      /**
       * To simplify this filter, we should start passing the shape projectId when creating custom groups
       * and start using only the "shape_project_id" field to filter the channels.
       * */
      {
        // Filtering teams (auto generated channels), we need to use the shape projectId
        type: 'team',
        joined: true,
        shape_project_id: projectId,
      },
      {
        // Filtering custom groups, we need to use the stream projectId
        type: 'group',
        joined: true,
        team: projectStreamTeam,
      },
      {
        type: 'messaging',
        joined: true,
        last_message_at: { $exists: true },
      },
    ],
  };
};

export const useProjectChannelsCount = (projectId?: string) => {
  const { client } = useChatClient();
  const { data: project } = useProject(projectId!);
  const [notifications, setNotifications] = useState(0);
  const projectStreamTeam = project?.channels.streamChatTeam;
  const isChannelRoute = useMatch({ path: '/projects/:projectId/channels/:channelId', end: false });
  const currentChannelCid = isChannelRoute?.params.channelId;

  const retrieveNotificationsCount = useCallback(async () => {
    if (!client) return 0;

    return client.queryChannels(getProjectChannelsFilter(projectId!, projectStreamTeam!)).then((response) => {
      return response.reduce((state, channel) => state + channel.countUnread(), 0);
    });
  }, [client, projectId, projectStreamTeam]);

  // Responsible to setup initial notifications count
  useEffect(() => {
    if (!client?.user || !projectId) return;
    retrieveNotificationsCount().then((count) => setNotifications(count));
  }, [client, projectId, retrieveNotificationsCount]);

  // Responsible to update notifications count when a new message is received or read
  useEffect(() => {
    const updateNotifications: EventHandler = (event) => {
      if (event.type === 'message.new' || event.type === 'message.read') {
        retrieveNotificationsCount().then((count) => setNotifications(count));
      }
    };

    client?.on(updateNotifications);
    return () => client?.off(updateNotifications);
  }, [client, currentChannelCid, retrieveNotificationsCount]);

  return notifications;
};
