/**
 * The factory pattern is used to encompass multiple function expressions
 * and export them in a single module.
 * @module UtilsArray
 * @classdesc A collection of helper functions for manipulating arrays.
 */
const UtilsArray = () => {
  /**
   * Moves an element from index A to index B and returns a new array.
   * Indices can be positive and negative, within the boundaries of the collection.
   * Example below.
   * <pre><code>
   *     0      1      2      3      4
   * ['A10', 'B20', 'C30', 'D40', 'E50']
   *    -5     -4     -3     -2     -1
   * </code></pre>
   * Item 'E50' has index 4, which is a maximum index for this array.
   * Alternatively, index -1 can be used to target this element.
   * Item 'A10' has index 0.
   * This element can also be selected using index -5, which is a minimum index for this array.
   * @static
   * @function moveElement
   * @param {Array} arr Array of items.
   * @param {number} from Index of an item to remove.
   * @param {number} to Index to insert the removed item at
   * @return {Array} New array after an item was moved. The returned array should have the same length.
   * New empty Array is returned if input was empty or had incorrect type.
   */
  const moveElement = (arr: any, from: any, to: any) => {
    if (!Array.isArray(arr) || arr.length === 0) {
      return [];
    }
    if (from >= arr.length || from < -arr.length || to >= arr.length || to < -arr.length || from === to) {
      return [...arr];
    }

    const startInx = from < 0 ? arr.length + from : from;
    const endInx = to < 0 ? arr.length + to : to;

    if (startInx > endInx) {
      return [...arr.slice(0, endInx), arr[startInx], ...arr.slice(endInx, startInx), ...arr.slice(startInx + 1)];
    }
    return [...arr.slice(0, startInx), ...arr.slice(startInx + 1, endInx + 1), arr[startInx], ...arr.slice(endInx + 1)];
  };

  return { moveElement };
};

export default UtilsArray();
