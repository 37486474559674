import React from 'react';
import { cn } from '../../utils/classes';

export interface TableRowProps extends React.ComponentProps<'tr'> {
  /**
   * alternating striped rows
   */
  striped?: boolean;
}

export const TableRow = React.forwardRef<HTMLTableRowElement, TableRowProps>(
  ({ striped, children, className, ...props }, ref) => {
    const classes = cn({ 'odd:bg-white even:bg-gray-50': striped }, className);

    return (
      <tr className={classes} {...props} ref={ref}>
        {children}
      </tr>
    );
  }
);
