/** When defining a feature flag using environment variables, follow these
 *  guidelines:
 *   - the environment variable should start with "REACT_APP_FEATURE_"
 *   - remove this prefix for the feature name
 *
 *   ex: for a feature called "green badge":
 *
 *   const features = {
 *     GREEN_BADGE: process.env.REACT_APP_FEATURE_GREEN_BADGE === 'true',
 *   };
 *
 *   const showBadge = isFeatureEnabled('GREEN_BADGE');
 *
 * @deprecated in favor of feature flag service. No new flags should be added here.
 */
const features = {
  SHIFT_REPORT_CHAT_WEBSOCKETS: process.env.REACT_APP_FEATURE_SHIFT_REPORT_CHAT_WEBSOCKETS === 'true',
};

type FeatureFlag = keyof typeof features;

/** @deprecated in favor of feature flag service. Use flipt + useFeatureFlag hook instead */
export const isFeatureEnabled = (feature: FeatureFlag) => Boolean(features[feature]);
