import { Input, InputAdornment } from '@material-ui/core';
import { useMessage } from '@messageformat/react';
import { IconButton } from '@shape-construction/arch-ui';
import { XMarkIcon } from '@shape-construction/arch-ui/src/Icons/solid';
import { useDebounceCallback } from '@shape-construction/hooks';
import { SEARCH_STATE } from 'app/constants/SearchState';
import { useLayoutContext } from 'app/contexts/layout/layoutContext';
import React, { type ComponentProps, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useStyles } from './SearchField.style';

const SearchField = () => {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams({ q: '' });
  const fieldRef = useRef<HTMLInputElement>();
  const searchQuery = searchParams.get('q') as string;
  const placeholder = useMessage('search.issues.placeholder');
  const {
    layoutConfig: { searchState },
  } = useLayoutContext();

  // track the state of the search field X close button
  const displayClearButton = searchQuery !== '';

  const handleFieldChange: ComponentProps<typeof Input>['onChange'] = useDebounceCallback((event) => {
    searchParams.set('q', event.target.value);
    setSearchParams(searchParams, { replace: true });
  }, 250);

  const handleClearSearch = () => {
    if (!fieldRef.current) return;

    searchParams.set('q', '');
    setSearchParams(searchParams);
    fieldRef.current.value = '';
    fieldRef.current.focus();
  };

  if (searchState !== SEARCH_STATE.SEARCHING) return null;

  return (
    <div className="max-w-screen-sm flex-1 mr-1">
      <Input
        inputRef={fieldRef}
        className={classes.root}
        autoFocus
        placeholder={placeholder}
        disableUnderline
        data-cy="search-field"
        onChange={handleFieldChange}
        defaultValue={searchQuery}
        endAdornment={
          displayClearButton && (
            <InputAdornment position="end" className="mr-0.5">
              <IconButton
                color="white"
                icon={XMarkIcon}
                size="xxs"
                variant="text"
                data-testid="clear-icon"
                aria-label="cancel search"
                onClick={handleClearSearch}
              />
            </InputAdornment>
          )
        }
      />
    </div>
  );
};

export { SearchField };
