import type { DocumentPayload } from '../types';

const MAX_FILE_SIZE_IN_BYTES = 100 * 1024 * 1024;

type PayloadValidationError = {
  filename: string;
  error: string;
};

type ResultType = {
  allValid: boolean;
  validFiles: DocumentPayload[];
  validationErrors: PayloadValidationError[];
};

type PayloadValidationProps = {
  maxSize?: number;
};

type ValidationFilters = {
  isZeroSize: (payload: DocumentPayload) => boolean;
  exceedsMaxSize: (payload: DocumentPayload) => boolean;
};

class PayloadValidation {
  maxSize: number;

  constructor({ maxSize = MAX_FILE_SIZE_IN_BYTES }: PayloadValidationProps = {}) {
    this.maxSize = maxSize;
  }

  validateInput(items: DocumentPayload[]): ResultType {
    const validationFilters: ValidationFilters = {
      isZeroSize: (payload: DocumentPayload) => payload?.file.size === 0,
      exceedsMaxSize: (payload: DocumentPayload) => payload?.file.size > this.maxSize,
    };

    let result: ResultType = {
      allValid: true,
      validFiles: Array.from(items),
      validationErrors: [],
    };
    result = this.filterResults(result, validationFilters);

    return result;
  }

  private filterResults(current: ResultType, filters: ValidationFilters): ResultType {
    const validFiles: DocumentPayload[] = [];
    const validationErrors: PayloadValidationError[] = [];

    current.validFiles.forEach((payload) => {
      const isZeroSize = filters.isZeroSize(payload);
      const exceedsMaxSize = filters.exceedsMaxSize(payload);

      if (isZeroSize) {
        validationErrors.push({ filename: payload?.file.name, error: 'file_size_zero' });
      } else if (exceedsMaxSize) {
        validationErrors.push({ filename: payload?.file.name, error: 'file_too_big' });
      } else {
        validFiles.push(payload);
      }
    });

    return {
      allValid: validFiles.length === current.validFiles.length,
      validFiles,
      validationErrors: [...current.validationErrors, ...validationErrors],
    };
  }
}

export default PayloadValidation;
export { MAX_FILE_SIZE_IN_BYTES, type PayloadValidationError };
