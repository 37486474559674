import { useMessageGetter } from '@messageformat/react';
import { Alert } from '@shape-construction/arch-ui';
import { DiamondIcon } from '@shape-construction/arch-ui/src/Icons/solid';
import { now, parseDate } from '@shape-construction/utils/DateTime';
import { useQuery } from '@tanstack/react-query';
import { getProjectQueryOptions } from 'app/queries/projects/projects';
import { useTeamsSubscriptionPlan } from 'app/queries/teamsSubscriptionPlan/teamsSubscriptionPlan';
import type React from 'react';
import { Link, useMatch } from 'react-router-dom';

export const TrialBar = () => {
  const messages = useMessageGetter('trial.bar');

  const projectRoute = useMatch('/projects/:projectId/*');
  const projectId = projectRoute?.params?.projectId;
  const isProjectRoute = Boolean(projectId);
  const { data: project } = useQuery(getProjectQueryOptions(projectId!));
  const teamId = project?.currentTeamId;
  const { data, isLoading } = useTeamsSubscriptionPlan(projectId!, teamId!, {
    query: { enabled: isProjectRoute && Boolean(projectId && teamId) },
  });

  if (!projectId || !teamId) return null;
  if (isLoading || !data?.trialEndAt) return null;

  const displayUpgradeLink = data?.availableActions.edit;
  const daysLeft = parseDate(data.trialEndAt).diff(now(), 'days');

  return (
    <Alert
      color="success"
      rounded={false}
      justifyContent="center"
      customIcon={<DiamondIcon className="text-green-400" />}
    >
      <Alert.Message>
        {messages('badge', { daysLeft })}{' '}
        {displayUpgradeLink && (
          <Link to={`/projects/${projectId}/settings/teams/${teamId}/subscription`} className="underline font-bold">
            {messages('link')}
          </Link>
        )}
        {!displayUpgradeLink && messages('contactUpgrade')}
      </Alert.Message>
    </Alert>
  );
};
