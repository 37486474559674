import { Combobox, type ComboboxProps } from '@headlessui/react';
import React, { type ElementType, forwardRef } from 'react';
import type { PrimitiveProps } from '../utils/render';

export type OwnProps = {
  label?: string;
  children: React.ReactNode;
};

export type SearchRootProps<
  TValue,
  TNullable extends boolean | undefined,
  TMultiple extends boolean | undefined,
  TTag extends ElementType,
> = PrimitiveProps<Omit<ComboboxProps<TValue, TNullable, TMultiple, TTag>, 'as'> & OwnProps>;

export function SearchRootBase<
  TValue,
  TNullable extends boolean | undefined,
  TMultiple extends boolean | undefined,
  TTag extends ElementType,
>(
  { label, children, ...props }: SearchRootProps<TValue, TNullable, TMultiple, TTag>,
  ref: React.Ref<React.ElementRef<typeof Combobox>>
) {
  return (
    <Combobox ref={ref} as="div" {...props}>
      {label && <Combobox.Label className="text-sm font-medium text-gray-700">{label}</Combobox.Label>}
      <div className="relative mt-2">{children}</div>
    </Combobox>
  );
}

export const SearchRoot = forwardRef(SearchRootBase) as <
  TValue,
  TNullable extends boolean | undefined,
  TMultiple extends boolean | undefined,
  TTag extends ElementType,
>(
  props: SearchRootProps<TValue, TNullable, TMultiple, TTag> & {
    ref?: React.Ref<React.ElementRef<'div'>>;
  }
) => ReturnType<typeof SearchRootBase>;
