import { useMessageGetter } from '@messageformat/react';
import type { ProjectSchema } from '@shape-construction/api/model';
import { FileExtensionIcon, Modal, cn } from '@shape-construction/arch-ui';
import { InformationCircleIcon } from '@shape-construction/arch-ui/src/Icons/outline';
import { PlusIcon, TrashIcon } from '@shape-construction/arch-ui/src/Icons/solid';
import { usePrevious } from '@shape-construction/hooks';
import React, { useEffect, useRef, useState } from 'react';
import { MediaPicker, type MediaPickerOptions } from '../MediaPicker/MediaPicker';
import { DocumentMetadata } from './components/DocumentMetadata/DocumentMetadata';
import { DocumentThumbnail } from './components/DocumentThumbnail/DocumentThumbnail';
import { UploadSave } from './components/UploadSave/UploadSave';
import type { DocumentBody, DocumentPreview } from './types';
import { getFileExtension, isFileImageType } from './utils/UtilsGallery';

type UploadPreviewProps = {
  projectId: ProjectSchema['id'];
  documentsPreview: DocumentPreview[];
  mediaPickerOptions: MediaPickerOptions;
  onUploadDocuments: (files: File[], body: DocumentBody) => void;
  onRemoveDocument: (index: number) => void;
  onClosePreview: () => void;
};

export const UploadPreview = ({
  projectId,
  documentsPreview,
  mediaPickerOptions,
  onUploadDocuments,
  onRemoveDocument,
  onClosePreview,
}: UploadPreviewProps) => {
  const messages = useMessageGetter('projectGallery');
  const thumbnailRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(0);
  const selectedDocument = documentsPreview.at(selectedDocumentIndex);
  const initialBody: DocumentBody = {
    locationId: null,
    caption: null,
  };
  const [documentBody, setDocumentBody] = useState<DocumentBody>(initialBody);

  const previousDocumentPreviewLength = usePrevious(documentsPreview)?.length;
  const currentDocumentPreviewLength = documentsPreview?.length;
  useEffect(() => {
    if (previousDocumentPreviewLength && previousDocumentPreviewLength < currentDocumentPreviewLength) {
      thumbnailRefs.current.at(-1)?.scrollIntoView({ inline: 'end', behavior: 'smooth' });
      setSelectedDocumentIndex(currentDocumentPreviewLength - 1);
    }
  }, [previousDocumentPreviewLength, currentDocumentPreviewLength]);

  const onSaveDocumentBody = (field: DocumentBody) => {
    setDocumentBody({ ...field });
  };

  const onUpload = () => {
    const filesToUpload = documentsPreview.map((file) => file!.fileToUpload);
    return onUploadDocuments(filesToUpload, documentBody);
  };

  const renderPreview = () => {
    if (!selectedDocument) return null;

    const PreviewDocument = isFileImageType(selectedDocument.fileToUpload)
      ? renderImage(selectedDocument)
      : renderFile(selectedDocument);

    return (
      <div className="h-full max-h-[66vh]">
        <div className="h-full flex mx-auto place-items-center justify-center" data-testid="document-preview">
          {PreviewDocument}
        </div>
      </div>
    );
  };

  const renderThumbnails = () => (
    <div className="flex flex-nowrap justify-center align-center gap-2">
      <div className="flex gap-2 overflow-x-auto no-" data-testid="document-thumbnail">
        {documentsPreview.map((document, index) => {
          const isSelected = document === selectedDocument;
          const buttonProps: React.ComponentProps<'button'> = {
            'aria-selected': isSelected,
            'aria-label': isSelected
              ? messages('uploadPreview.thumbnail.removeDocument')
              : messages('uploadPreview.thumbnail.document'),
            onClick: () => {
              if (isSelected) {
                onRemoveDocument(index);
                setSelectedDocumentIndex(index === 0 ? 0 : index - 1);
              } else {
                thumbnailRefs.current[index]?.scrollIntoView({ inline: 'center', behavior: 'smooth' });
                setSelectedDocumentIndex(index);
              }
            },
          };

          return (
            <button
              type="button"
              ref={(ref) => {
                thumbnailRefs.current[index] = ref;
              }}
              key={document?.fileToUpload.name}
              className={cn(
                'group relative flex flex-shrink-0 items-center justify-center h-14 w-14 border bg-neutral rounded-md overflow-hidden',
                'aria-selected:border-2 aria-selected:border-selected'
              )}
              {...buttonProps}
            >
              <DocumentThumbnail document={document} />
              {isSelected && (
                <>
                  <div className="h-full w-full absolute bg-overlay-subtle bg-black opacity-50" />
                  <TrashIcon className="absolute text-white h-5 w-5" />
                </>
              )}
            </button>
          );
        })}
      </div>
      <MediaPicker options={mediaPickerOptions}>
        <button
          type="button"
          aria-label={messages('uploadPreview.picker.trigger')}
          className={cn(
            'flex flex-shrink-0 items-center justify-center h-14 w-14 border rounded-md overflow-hidden',
            'bg-neutral-subtle hover:bg-neutral-subtle active:bg-neutral-pressed'
          )}
        >
          <PlusIcon className="h-6 w-6 text-neutral" />
        </button>
      </MediaPicker>
    </div>
  );

  const renderImage = (document: NonNullable<DocumentPreview>) => {
    return <img className="h-full" src={document.preview} alt={document.fileToUpload.name} />;
  };

  const renderFile = (document: NonNullable<DocumentPreview>) => {
    return (
      <div className="flex flex-col">
        <FileExtensionIcon
          extension={getFileExtension(document.fileToUpload)}
          className="mx-auto"
          width="80"
          height="80"
        />
        <div className="mx-auto mt-4 text-sm leading-5 font-medium">{document.fileToUpload.name}</div>
      </div>
    );
  };

  if (documentsPreview.length === 0) return null;

  return (
    <Modal open onClose={onClosePreview}>
      <Modal.Header onClose={onClosePreview}>
        <Modal.Title> {messages('upload.new')}</Modal.Title>
      </Modal.Header>

      <Modal.Content className="relative py-4 md:h-[50vh] flex flex-col gap-2">
        <div className="flex-1 overflow-hidden">{renderPreview()}</div>
        {renderThumbnails()}
      </Modal.Content>

      <Modal.Footer>
        <div className="flex flex-col mx-auto mt-2 gap-y-4 w-full">
          <DocumentMetadata projectId={projectId} handleSave={onSaveDocumentBody} />
          <div className="w-full flex flex-row justify-between">
            <div className="flex items-center gap-2">
              <InformationCircleIcon className="icon-neutral w-4 h-4" />
              <span className="text-xs leading-4 font-normal text-neutral-subtle">{messages('upload.alert')}</span>
            </div>

            <UploadSave onHandleSave={onUpload} />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
