import classNames from 'clsx';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import type { CardSize } from '../Card.types';

const footerPaddingClasses: Record<NonNullable<CardSize>, string> = {
  small: 'px-4 pt-3 pb-3',
  medium: 'px-6 pt-4 pb-4',
  large: 'px-8 pt-5 pb-5',
};

export type CardFooterProps = Omit<React.HtmlHTMLAttributes<HTMLDivElement>, 'className'> & {
  border?: boolean;
  size?: CardSize;
};

export const CardFooter: React.FC<CardFooterProps> = ({
  children,
  border = false,
  size = 'small',

  ...props
}) => {
  const footerClasses = twMerge(classNames(footerPaddingClasses[size]));

  return (
    <div
      {...props}
      className={classNames('flex', footerClasses, {
        'border-t border-gray-200': border,
      })}
    >
      {children}
    </div>
  );
};
