import * as React from 'react';
import type { SVGProps } from 'react';
const PsdIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={44} viewBox="0 0 40 44" fill="none" aria-hidden="true" {...props}>
    <g id="file-icon/psd">
      <g id="Vector" filter="url(#filter0_dd_3816_38088)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6776 41C27.014 41 13.7442 41 7.32664 41C5.85388 41 4.66663 39.82 4.66663 38.3473C4.66663 30.3468 4.66663 10.7752 4.66663 3.6498C4.66663 2.17704 5.86053 1 7.33329 1H26.2222Z"
          fill="#6366F1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6776 41C27.014 41 13.7442 41 7.32664 41C5.85388 41 4.66663 39.82 4.66663 38.3473C4.66663 30.3468 4.66663 10.7752 4.66663 3.6498C4.66663 2.17704 5.86053 1 7.33329 1H26.2222Z"
          fill="url(#paint0_radial_3816_38088)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L26.2222 7.44444C26.2222 8.9172 27.4161 10.1111 28.8888 10.1111L35.3333 10.1111L26.2222 1Z"
          fill="white"
          fillOpacity={0.3}
        />
      </g>
      <g id="Vector_2">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.9744 16.5845V26.9592C12.9744 27.05 13.0147 27.0903 13.0954 27.0903H15.0513C15.1219 27.0802 15.1723 27.0197 15.1622 26.9492V23.7127C15.3538 23.7127 15.495 23.7127 15.5958 23.7228C15.6966 23.7329 15.8579 23.7329 16.0999 23.7329C16.9267 23.7329 17.6828 23.6421 18.3684 23.3901C19.0238 23.1481 19.5884 22.7246 19.9917 22.16C20.395 21.5954 20.5966 20.8796 20.5966 20.0125C20.5966 19.5588 20.516 19.1252 20.3647 18.7018C20.2034 18.2682 19.9514 17.875 19.6287 17.5524C19.2456 17.1793 18.7818 16.897 18.2777 16.7256C17.7131 16.5139 17.0275 16.4131 16.2007 16.4131C15.8982 16.4131 15.5958 16.4131 15.2832 16.4232C15.1323 16.428 14.9861 16.4306 14.8446 16.433C14.6931 16.4356 14.5469 16.4381 14.4061 16.4433C14.274 16.4482 14.1467 16.4507 14.023 16.4532C13.8917 16.4558 13.7644 16.4583 13.6398 16.4635C13.4079 16.4736 13.2466 16.4736 13.0752 16.4736C13.0046 16.4736 12.9744 16.5139 12.9744 16.5845ZM15.1723 21.8071V18.4195C15.2429 18.4094 15.384 18.3993 15.5857 18.4094C15.7773 18.3993 16.0092 18.3993 16.2713 18.3993C16.6847 18.3892 17.088 18.4497 17.461 18.601C17.7534 18.7119 17.9954 18.9135 18.1668 19.1757C18.3281 19.4479 18.4088 19.7604 18.3987 20.073C18.3987 20.5166 18.2979 20.8594 18.0861 21.1115C17.8643 21.3635 17.582 21.5551 17.2594 21.6458C16.8863 21.7668 16.4931 21.8273 16.0999 21.8273H15.5655C15.4532 21.8273 15.3322 21.8186 15.2026 21.8093C15.1925 21.8086 15.1825 21.8079 15.1723 21.8071Z"
          fill="white"
        />
        <path
          d="M25.9198 20.8493C26.2626 20.9198 26.5853 21.0307 26.8878 21.1921C26.9281 21.2122 26.9785 21.2122 27.0692 21.1921C27.0995 21.1719 27.1196 21.1316 27.1196 21.0912V19.4478C27.1196 19.4075 27.1096 19.3672 27.0995 19.3268C27.0793 19.2865 27.039 19.2462 26.9987 19.2361C26.7264 19.1252 26.434 19.0445 26.1316 19.0042C25.7182 18.9437 25.2947 18.9135 24.8814 18.9135C24.1655 18.9135 23.5707 19.0244 23.0968 19.2663C22.6632 19.468 22.2902 19.7906 22.0381 20.1939C21.8163 20.567 21.6953 20.9803 21.6953 21.4139C21.6853 21.7668 21.7659 22.1196 21.9272 22.4423C22.1188 22.7851 22.3809 23.0775 22.6935 23.2993C23.1371 23.6017 23.611 23.8437 24.1252 24.0252C24.6192 24.2168 24.9519 24.368 25.1133 24.4991C25.2746 24.6301 25.3552 24.7612 25.3552 24.9024C25.3552 25.0839 25.2443 25.2553 25.083 25.3258C24.9015 25.4166 24.6293 25.467 24.2462 25.467C23.8429 25.467 23.4396 25.4166 23.0565 25.3157C22.6128 25.2149 22.1995 25.0536 21.8062 24.8217C21.776 24.8015 21.7457 24.7915 21.7155 24.8116C21.6853 24.8318 21.6752 24.8721 21.6752 24.9024V26.6567C21.6651 26.7374 21.7155 26.8079 21.7861 26.8483C22.1087 26.9995 22.4616 27.1104 22.8145 27.1709C23.2682 27.2616 23.7219 27.302 24.1857 27.302C24.9116 27.302 25.5166 27.1911 26.0106 26.9793C26.4643 26.7979 26.8575 26.4853 27.1398 26.082C27.402 25.6888 27.5431 25.2149 27.533 24.7411C27.5431 24.3781 27.4624 24.0252 27.3011 23.7026C27.1096 23.3598 26.8373 23.0775 26.5046 22.8657C26.0207 22.5633 25.4964 22.3112 24.9519 22.1297C24.71 22.0289 24.468 21.918 24.2361 21.797C24.105 21.7264 23.984 21.6357 23.8933 21.5248C23.8328 21.4441 23.7925 21.3534 23.7925 21.2626C23.7925 21.1719 23.8227 21.0711 23.8731 20.9904C23.9437 20.8896 24.0546 20.819 24.1857 20.7888C24.3772 20.7384 24.589 20.7081 24.7906 20.7182C25.1738 20.7182 25.5468 20.7686 25.9198 20.8493Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_dd_3816_38088"
        x={2.66663}
        y={0}
        width={34.6666}
        height={44}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3816_38088" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_3816_38088" result="effect2_dropShadow_3816_38088" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3816_38088" result="shape" />
      </filter>
      <radialGradient
        id="paint0_radial_3816_38088"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20 0.999999) rotate(90) scale(40 40.1825)"
      >
        <stop offset={0.297418} stopOpacity={0} />
        <stop offset={1} stopOpacity={0.08} />
      </radialGradient>
    </defs>
  </svg>
);
export default PsdIcon;
