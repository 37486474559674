import React from 'react';
import 'core-js/stable';
import { initialiseAxiosClient } from '@shape-construction/api/client';
import ReduxApp from 'app/ReduxApp';
import { axiosInstance } from 'app/axios';
import { setupInterceptors } from 'app/axios-interceptor';
import { createRoot } from 'react-dom/client';
import './index.css';

initialiseAxiosClient(axiosInstance);
setupInterceptors(axiosInstance);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<ReduxApp />);
