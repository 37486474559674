import { useMessageGetter } from '@messageformat/react';
import type { ProjectSchema } from '@shape-construction/api/model';
import { FileUpload, Menu } from '@shape-construction/arch-ui';
import { ShapeProjectGalleryIcon } from '@shape-construction/arch-ui/src/Icons/outline';
import { MenuHeading } from '@shape-construction/arch-ui/src/Menu/Menu';
import { breakpoints } from '@shape-construction/arch-ui/src/utils/breakpoints';
import { useMediaQuery } from '@shape-construction/hooks';
import { MAX_FILE_SIZE_IN_BYTES } from 'app/constants/FileUpload';
import { useFileUploadValidator } from 'app/hooks/useFileUploadValidator';
import React from 'react';
import { useProject } from '../../queries/projects/projects';
import { MediaPicker, type MediaPickerOptions } from '../MediaPicker/MediaPicker';

export type GallerySectionProps = {
  projectId: ProjectSchema['id'];
  onUpload: (files: File[]) => void;
};

export const GallerySection = ({ projectId, onUpload }: GallerySectionProps) => {
  const messages = useMessageGetter('quickNewButton');
  const fileUploadErrorMessages = useMessageGetter('errors.fileUpload');
  const { data: project } = useProject(projectId);
  const canUploadDocument = project?.availableActions?.uploadDocument;
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));

  const { validateFiles, handleValidationErrors } = useFileUploadValidator({
    maxSizeInBytes: MAX_FILE_SIZE_IN_BYTES,
    errorMessages: {
      fileSizeMin: (filename, min) => fileUploadErrorMessages('fileSizeMin', { filename, min }),
      fileSizeMax: (filename, max) => fileUploadErrorMessages('fileSizeMax', { filename, max }),
      fileTypeInvalid: (filename) => fileUploadErrorMessages('fileTypeInvalid', { filename }),
    },
  });

  const onHandleFileChange = (files: File[]) => {
    const results = validateFiles(files);
    handleValidationErrors(results);

    const validDocuments = results.filter((result) => result.isValid).map((result) => result.file);
    validDocuments.forEach((file) => onUpload([file]));
  };

  const options: MediaPickerOptions = {
    gallery: {
      accept: 'image/png,image/jpg,image/jpeg',
      enabled: !isLargeScreen,
      multiple: true,
      onSelectFiles: onHandleFileChange,
    },
    camera: {
      enabled: true,
      multiple: true,
      onSelectFiles: onHandleFileChange,
    },
    documents: {
      accept: '*',
      enabled: true,
      multiple: true,
      onSelectFiles: onHandleFileChange,
    },
  };

  const renderUploadItem = () => (
    <FileUpload.Root
      accept={options.documents?.accept}
      multiple={options.documents?.multiple}
      onChange={options.documents?.onSelectFiles}
    >
      <FileUpload.Trigger asChild>
        <div className="flex flex-row gap-3">
          <div className="justify-start items-start gap-2.5 flex">
            <ShapeProjectGalleryIcon className="opacity-50 w-5 h-5" />
          </div>
          <div className="flex flex-col gap-1 justify-start items-start">
            <div className="text-sm font-medium leading-tight">{messages('gallery.options.upload.title')}</div>
          </div>
        </div>
      </FileUpload.Trigger>
    </FileUpload.Root>
  );

  const renderMobileUploadItem = () => (
    <MediaPicker options={options}>
      <div className="flex flex-row gap-3">
        <div className="justify-start items-start gap-2.5 flex">
          <ShapeProjectGalleryIcon className="opacity-50 w-5 h-5" />
        </div>
        <div className="flex flex-col gap-1 justify-start items-start">
          <div className="text-sm font-medium leading-tight">{messages('gallery.options.upload.title')}</div>
        </div>
      </div>
    </MediaPicker>
  );

  if (!project || !canUploadDocument) return null;

  return (
    <>
      <MenuHeading>{messages('gallery.heading')}</MenuHeading>
      <Menu.Item aria-label={messages('gallery.options.upload.title')}>
        {isLargeScreen ? renderUploadItem() : renderMobileUploadItem()}
      </Menu.Item>
    </>
  );
};
