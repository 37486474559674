import type { Article, ArticleAttachment, PageParams, PageResponse } from 'zendesk/api/help-center/types';
import { BaseApi } from 'zendesk/base-api';

export type GetArticleAttachmentsParams = PageParams;
export type GetArticleAttachmentsResponse = Omit<PageResponse, 'sort_by' | 'sort_order'> & {
  article_attachments: ArticleAttachment[];
};

export class ArticleAttachments extends BaseApi {
  getArticleAttachments(articleId: Article['id'], params?: GetArticleAttachmentsParams) {
    return this.request<GetArticleAttachmentsResponse>({
      url: `/articles/${articleId}/attachments`,
      method: 'get',
      params,
    });
  }
}
