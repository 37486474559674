import dayjs, { type Dayjs } from 'dayjs';

import dayjsCustomParseFormat from 'dayjs/plugin/customParseFormat';
import dayjsDuration from 'dayjs/plugin/duration';
import dayjsTimezone from 'dayjs/plugin/timezone';
import dayjsUtc from 'dayjs/plugin/utc';

dayjs.extend(dayjsUtc);
dayjs.extend(dayjsTimezone);
dayjs.extend(dayjsDuration);
dayjs.extend(dayjsCustomParseFormat);

export type DateType = Date | Dayjs | string;

export const DATE_FORMAT = 'DD-MMM-YYYY';
const DATE_FORMAT_ISO = 'YYYY-MM-DD';
const TIME_FORMAT = 'HH:mm';
export const CHANNELS_TIME_FORMAT = 'hh:mm a';
export const DATE_TIME_FORMAT = 'DD-MMM-YYYY HH:mm';
export const DATE_TIME_FORMAT_ISO = 'YYYY-MM-DDTHH:mm';

/**
 * Receives a DayJS date and converts to UTC (+00:00) and formats with Z without miliseconds.
 * @example formatToIsoUtc(dayjs()) // 2021-09-29T14:00:00Z
 */
const formatToIsoUtc = (date: Dayjs) => date.utc().format('YYYY-MM-DDTHH:mm:ss[Z]');

/**
 * Set the default timezone
 * @param {string} timezone - The timezone you want to set as default.
 */
export const setDefaultTimezone = (timezone: string) => dayjs.tz.setDefault(timezone);

/**
 * Now is a function that returns the current moment.
 */
export const now = () => dayjs();

/**
 * Fix the time in a Moment DateTime at the end of a business day (17:00).
 *
 * @param {Dayjs} currentDateTime the current Moment DateTime.
 * @returns {Dayjs} Moment DateTime with a fixed time of 17:00.
 */
export const setEndOfBusiness = (currentDateTime: Dayjs) =>
  currentDateTime.set('hour', 17).set('minute', 0).set('second', 0).set('millisecond', 0);

/**
 * Fix the time in a Moment DateTime at the start of a business day (09:00).
 *
 * @param {Dayjs} currentDateTime the current Moment DateTime.
 * @returns {Dayjs} Moment DateTime with a fixed time of 09:00.
 */
export const setStartOfBusiness = (currentDateTime: Dayjs) =>
  currentDateTime.set('hour', 9).set('minute', 0).set('second', 0).set('millisecond', 0);

/**
 * ParseDate takes a date and returns a moment object.
 * @param {DateType} date - The date to be parsed.
 */
export const parseDate = (date: DateType) => dayjs(date);

/**
 * isDateValid takes a date and returns a boolean stating if date is valid or not.
 * @param {DateType} date - The date to be validated.
 */
export const isDateValid = (date: DateType | null | undefined) => dayjs(date).isValid();

/**
 * It takes a date and a format and returns a formatted date.
 * @param {DateType} date - The date you want to parse.
 * @param {string} format - The format of the date you want to parse.
 */
export const parseDateWithFormat = (date: DateType, format: string) =>
  isDateValid(date) ? parseDate(date).format(format) : '';

/**
 * Given a date and a timezone, return a moment object in that timezone.
 * @param {DateType} date - The date to be parsed.
 * @param {string} timezone - The timezone you want to parse the date in.
 */
export const parseDateInTimezone = (date: DateType, timezone: string) => dayjs.tz(date, timezone);

/**
 * Given a date and a timezone, return the date converted in that timezone.
 * @param {DateType} date - The date to be parsed.
 * @param {string} timezone - The timezone you want to convert the date in.
 */
export const parseDateToTimezone = (date: DateType, timezone: string) => parseDate(date).tz(timezone);

/**
 * Given a date, return the start of the day.
 *
 * The function takes a date as an argument and returns the start of the day
 * @param {DateType} date - The date to get the start of the day for.
 */
export const getStartOfDay = (date: DateType) => parseDate(date).startOf('day');

/**
 * Get the end of the day for a given date.
 * @param {DateType} date - The date to get the end of the day for.
 */
export const getEndOfDay = (date: DateType) => parseDate(date).endOf('day');

/**
 * Given two dates, return the difference between them.
 * @param {DateType} firstDate - The first date to compare.
 * @param {DateType} secondDate - The date to compare against.
 */
export const getDifferenceBetweenTwoDates = (firstDate: DateType, secondDate: DateType) =>
  parseDate(firstDate).diff(parseDate(secondDate));

/**
 * It takes two dates as strings, parses them into moment objects, and returns the duration between
 * them
 * @param {DateType} firstDate - The first date to compare.
 * @param {DateType} secondDate - The date you want to compare to the firstDate.
 */
export const getDurationBetweenTwoDates = (firstDate: DateType, secondDate: DateType) =>
  isDateValid(firstDate) && isDateValid(secondDate)
    ? dayjs.duration(parseDate(secondDate).diff(parseDate(firstDate)))
    : null;

export const formatDate = (date: DateType, timezone: string, format = DATE_FORMAT) =>
  isDateValid(date) ? parseDateToTimezone(date, timezone).format(format) : '';

export const formatTime = (date: DateType, timezone: string) =>
  isDateValid(date) ? parseDateToTimezone(date, timezone).format(TIME_FORMAT) : '';

export const formatDateISO = (date: DateType, timezone: string) =>
  isDateValid(date) ? parseDateToTimezone(date, timezone).format('YYYY-MM-DD') : '';

export const formatTimeISO = (date: DateType, timezone: string) =>
  isDateValid(date) ? parseDateToTimezone(date, timezone).format(TIME_FORMAT) : '';

export const formatCurrentDateISO = (timezone: string) => formatDateISO(now().toISOString(), timezone);

export const formatCurrentTimeISO = (timezone: string) => formatTimeISO(now().toISOString(), timezone);

export const formatDateAndTime = (date: DateType, timezone: string, format = DATE_TIME_FORMAT) =>
  isDateValid(date) ? parseDateToTimezone(date, timezone).format(format) : '';

export const formatDateAndTimeToUtc = (date: DateType, timezone: string) =>
  isDateValid(date) ? formatToIsoUtc(parseDateInTimezone(date, timezone)) : '';

export const convertDateTimezoneAndKeepLocalTime = (date: DateType, timezone: string) =>
  parseDate(date).tz(timezone, true);

export const formatDateTimezoneAndKeepLocalTimeToUtc = (date: DateType, timezone: string) =>
  isDateValid(date) ? formatToIsoUtc(convertDateTimezoneAndKeepLocalTime(date, timezone)) : '';

export const formatISODateToWeekDayMonthString = (date: string) =>
  isDateValid(date) ? dayjs(date).format('ddd, D-MMM') : '';

export const getTodayISODate = (timezone: string) => {
  return now().tz(timezone).format(DATE_FORMAT_ISO);
};

/**
 * If the date is within the last 60 seconds, return 'just now',
 * If it's within the last hour, return the number of minutes ago,
 * If it's within the last 24 hours, return the number of hours ago,
 * If it's within the same year, return the day and month,
 * If it's not within the same year, return the day, month and year
 * @param {DateType} date - the date you want to format
 * @returns A string representing the time difference between the current time and the time passed in.
 */
export const formatTimeAgo = (date: DateType) => {
  if (!isDateValid(date)) return '';

  const currentDateTime = now();

  const sameYear = currentDateTime.isSame(date, 'year');
  const sameDay = currentDateTime.isSame(date, 'day');
  const secondsDifference = currentDateTime.diff(date, 'seconds');
  if (secondsDifference < 60) return 'just now';
  if (secondsDifference > 86400 && sameYear) {
    return parseDateWithFormat(date, 'D/M');
  }
  if (!sameYear) {
    return parseDateWithFormat(date, 'D/M/YY');
  }
  if (secondsDifference > 3600 && secondsDifference < 86400 && sameDay) {
    return `${currentDateTime.diff(date, 'hours')}h`;
  }
  if (secondsDifference > 3600 && !sameDay) {
    return parseDateWithFormat(date, 'D/M');
  }
  return `${currentDateTime.diff(date, 'minutes')}m`;
};

export const getOffsetFromTimezone = (timezone: string) => parseDateToTimezone(now(), timezone).format('Z');

export const formatISO = (date: DateType) => parseDateWithFormat(date, DATE_TIME_FORMAT_ISO);

export const getDateTime = (type: any, timezone: string, defaultDate = now()) => {
  switch (type) {
    case 'nextHour':
      return formatISO(parseDateInTimezone(defaultDate, timezone).add(1, 'hour').startOf('hour'));
    case 'startHour':
      return formatISO(parseDate(defaultDate).startOf('hour'));
    default:
      return formatISO(parseDate(defaultDate));
  }
};

export const formatDue = (date: DateType | null | undefined, timezone: string, today: DateType = now()) => {
  if (!date || !isDateValid(date)) return ' - ';

  const todayLocal = parseDateToTimezone(today, timezone);
  const tomorrowLocal = parseDateToTimezone(today, timezone).add(1, 'days');
  const dueDateLocal = parseDateToTimezone(date, timezone);

  if (todayLocal.isSame(dueDateLocal, 'day')) {
    return 'Today';
  }
  if (tomorrowLocal.isSame(dueDateLocal, 'day')) {
    return 'Tomorrow';
  }
  return dueDateLocal.format('DD-MMM-YYYY');
};

/**
 * It takes a date, token and format and returns a formatted date.
 *
 * @param date - The date to be parsed.
 * @param parseToken - The token to parse the date. More info here: https://day.js.org/docs/en/parse/string-format#list-of-all-available-parsing-tokens
 * @param format - The format of the date you want to parse.
 * @returns string
 */
export const parseCustomWithFormat = (date: DateType, parseToken: string, format: string) => {
  const parsed = dayjs(date, parseToken, true);
  return parsed.isValid() ? parsed.format(format) : '';
};

/**
 * This function takes a date and returns a new date with the minutes, seconds and milliseconds set to 0
 * @param {DateType} [date] - The date to round. If not provided, the current date is used.
 */
export const roundDownDateToNearestHour = (date: DateType) =>
  parseDate(date).set('minute', 0).set('seconds', 0).set('milliseconds', 0);

/**
 *  This function takes a date and creates a timestamp for channels
 *  it returns  in CHANNELS_TIME_FORMAT for dates on the same date,
 *  "Yesterday" for dates yesterday and DATE_SHORT_MONTH_FORMAT for dates older than yesterday
 * * @param date - The date to be parsed.
 */

export const formatToMessagingTimestamp = (date: DateType): string | undefined => {
  if (!date) {
    return undefined;
  }

  if (now().isSame(date, 'day')) {
    return parseDate(date).format(CHANNELS_TIME_FORMAT);
  }

  if (now().subtract(1, 'day').isSame(date, 'day')) {
    return 'Yesterday';
  }

  return parseDate(date).format(DATE_FORMAT);
};
