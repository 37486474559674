import { useMessageGetter } from '@messageformat/react';
import { SearchField } from '@shape-construction/arch-ui';
import React, { type ComponentProps } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { AuthorSelect } from './AuthorSelect/AuthorSelect';
import { DateSelect } from './DateSelect/DateSelect';
import { FormatSelect } from './FormatSelect/FormatSelect';
import type { GalleryFilterFormValues } from './GalleryFilterForm';
import { LocationsSelect } from './LocationsSelect/LocationsSelect';
import { SortOrderSelect } from './SortOrderSelect';
/**
 * SearchField have an internal debounce for the onChange event,
 * we cannot use the component directly in the Controller as the rest of
 * the component because it was being stuck when the user types something
 */
export const GalleryFilterFormSearch = (props: ComponentProps<typeof SearchField>) => {
  const messages = useMessageGetter('projectGallery');
  const { control } = useFormContext<GalleryFilterFormValues>();

  return (
    <Controller
      name="search"
      control={control}
      render={({ field }) => (
        <SearchField
          {...props}
          {...field}
          type="search"
          placeholder={messages('filters.document.searchPlaceholder')}
          className="border border-gray-300"
        />
      )}
    />
  );
};

export const GalleryFilterFormDate = (props: ComponentProps<typeof DateSelect>) => {
  const { control } = useFormContext<GalleryFilterFormValues>();
  return <Controller name="date" control={control} render={({ field }) => <DateSelect {...props} {...field} />} />;
};

export const GalleryFilterFormFormat = (props: ComponentProps<typeof FormatSelect>) => {
  const { control } = useFormContext<GalleryFilterFormValues>();
  return <Controller name="format" control={control} render={({ field }) => <FormatSelect {...props} {...field} />} />;
};

export const GalleryFilterFormLocations = (props: ComponentProps<typeof LocationsSelect>) => {
  const { control } = useFormContext<GalleryFilterFormValues>();
  return (
    <Controller name="locations" control={control} render={({ field }) => <LocationsSelect {...props} {...field} />} />
  );
};

export const GalleryFilterFormAuthors = (props: ComponentProps<typeof AuthorSelect>) => {
  const { control } = useFormContext<GalleryFilterFormValues>();
  return <Controller name="authors" control={control} render={({ field }) => <AuthorSelect {...props} {...field} />} />;
};

export const GallerySortOrderSelect = (props: ComponentProps<typeof SortOrderSelect>) => {
  const { control } = useFormContext<GalleryFilterFormValues>();
  return (
    <Controller name="order" control={control} render={({ field }) => <SortOrderSelect {...props} {...field} />} />
  );
};
