import { useMediaQuery } from '@shape-construction/hooks';
import React from 'react';
import { Button, type ButtonProps } from '../Button/Button';
import type { ButtonVariant } from '../Button/button-config';
import { breakpoints } from '../utils/breakpoints';

type PrimaryActionProps = Omit<ButtonProps, 'color' | 'variant' | 'size'> & {
  variant?: ButtonVariant;
};
export const PrimaryAction: React.FC<PrimaryActionProps> = ({ variant = 'contained', ...buttonProps }) => {
  const buttonSize = useMediaQuery(breakpoints.down('md')) ? 'md' : 'sm';

  return <Button {...buttonProps} size={buttonSize} variant={variant} color="primary" fullWidth />;
};
