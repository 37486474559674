import type { ImpactType } from './constants/Impact';

export const colors = {
  PRIMARY_MAIN: '#6366F1',
  PRIMARY_LIGHT: '#A5B4FC',
  SECONDARY_MAIN: '#9CA3AF',
  SECONDARY_LIGHT: '#E5E7EB',
  ERROR_MAIN: '#DC2626',
  WARNING_MAIN: '#FBBF24',
  WARNING_LIGHT: '#FEF3C7',
  SUCCESS_MAIN: '#10B981',
  INFO_MAIN: '#3B82F6',
  INFO_LIGHT: '#93C5FD',
  INFO_LIGHTER: '#EFF6FF',
  TEXT: '#111827',
  TEXT_SECONDARY: '#6B7280',
  BACKGROUND_MAIN: '#F3F4F6',
  BACKGROUND_LIGHT: '#F9FAFB',
};

export const textImpactColors: Record<ImpactType, string> = {
  notEntered: 'text-gray-300',
  noEffect: 'text-gray-400',
  completedDelay: 'text-gray-500',
  potentialDelay: 'text-yellow-500',
  liveDelay: 'text-red-600',
};
