import classNames from 'clsx';
import type { ButtonColor, ButtonSize, ButtonVariant } from './button-config';

const sizeClassesMap: Record<NonNullable<ButtonSize>, string> = {
  xxs: 'h-4 w-4',
  xs: 'h-4 w-4',
  sm: 'h-4 w-4',
  md: 'h-5 w-5',
  lg: 'h-5 w-5',
  xl: 'h-6 w-6',
};

const iconColorVariantClassesMap: Record<NonNullable<ButtonColor>, Record<NonNullable<ButtonVariant>, string>> = {
  primary: {
    contained: 'text-white',
    overlay: 'text-white',
    outlined: 'text-indigo-500',
    text: 'text-indigo-500',
  },
  secondary: {
    contained: 'text-gray-600',
    overlay: 'text-gray-600',
    outlined: 'text-gray-600',
    text: 'text-gray-600',
  },
  success: {
    contained: 'text-white',
    overlay: 'text-white',
    outlined: 'text-green-600',
    text: 'text-green-600',
  },
  danger: {
    contained: 'text-white',
    overlay: 'text-white',
    outlined: 'text-red-600',
    text: 'text-red-600',
  },
  warning: {
    contained: 'text-white',
    overlay: 'text-white',
    outlined: 'text-yellow-600',
    text: 'text-yellow-600',
  },
  white: {
    contained: 'text-gray-600',
    overlay: 'text-white',
    outlined: 'text-white',
    text: 'text-white',
  },
  dark: {
    contained: 'text-white',
    overlay: 'text-white',
    outlined: 'text-gray-900',
    text: 'text-gray-900',
  },
  discovery: {
    contained: 'text-white',
    overlay: 'text-white',
    outlined: 'text-purple-600',
    text: 'text-purple-600',
  },
};

type GetIconClassesProps = {
  color: ButtonColor;
  variant: ButtonVariant;
  size: ButtonSize;
};

export const getIconClasses = ({ color, variant, size }: GetIconClassesProps) => {
  const iconClasses = classNames('h-full', iconColorVariantClassesMap[color][variant], sizeClassesMap[size]);

  return { iconClasses };
};
