import { CSS } from '@dnd-kit/utilities';
import { useComposedRefs } from '@radix-ui/react-compose-refs';
import { Slot } from '@radix-ui/react-slot';
import React, { type CSSProperties, type ComponentProps } from 'react';
import { useDraggableItem } from './context';

type DraggableListItemProps =
  | ({ asChild?: false } & Omit<ComponentProps<'div'>, 'ref'>)
  | { asChild: true; children: React.ReactElement };

export const DraggableListItem = React.forwardRef<HTMLElement, DraggableListItemProps>(({ asChild, ...props }, ref) => {
  const {
    id,
    sortable: { transform, transition, setNodeRef, isDragging },
  } = useDraggableItem();
  const combinedRef = useComposedRefs<HTMLElement | null>(ref, setNodeRef);

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform && { ...transform, scaleX: 1, scaleY: 1 }),
    transition: transition,
    ...(isDragging ? { zIndex: 1, position: 'relative' } : {}),
  };

  const Child = asChild ? Slot : 'div';
  return <Child ref={combinedRef} id={`draggable-item-${id}`} style={style} {...props} />;
});

DraggableListItem.displayName = 'DraggableList.Item';
