import { type Consumer, createConsumer } from '@rails/actioncable';
import { getToken } from 'app/authorization';
import { environment } from 'app/config/environment';
import { isFeatureEnabled } from 'app/config/featureFlags';
import React, { createContext, useMemo } from 'react';

export const ActionCableContext = createContext<Consumer | null>(null);

type ActionCableProviderProps = {
  children: React.ReactNode;
};

export const ActionCableProvider = ({ children }: ActionCableProviderProps) => {
  const token = getToken();
  const URL = `${environment.ACTION_CABLE_URL}/cable?authorization=${token}`;
  const consumer = useMemo(() => createConsumer(URL), [URL]);

  if (!isFeatureEnabled('SHIFT_REPORT_CHAT_WEBSOCKETS')) return <>{children}</>;

  return <ActionCableContext.Provider value={consumer}>{children}</ActionCableContext.Provider>;
};
