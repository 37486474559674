import {
  getApiProjectsProjectIdIssuesIssueIdFeedPublic,
  getApiProjectsProjectIdIssuesIssueIdFeedTeam,
  getGetApiProjectsProjectIdIssuesIssueIdFeedPublicInfiniteQueryOptions,
  getGetApiProjectsProjectIdIssuesIssueIdFeedTeamInfiniteQueryOptions,
} from '@shape-construction/api/api';
import { environment } from 'app/config/environment';

export type Channel = 'public' | 'team';

const getProjectFeedInfiniteQueryOptions = {
  public: getGetApiProjectsProjectIdIssuesIssueIdFeedPublicInfiniteQueryOptions,
  team: getGetApiProjectsProjectIdIssuesIssueIdFeedTeamInfiniteQueryOptions,
};
const getFeedInfiniteFn = {
  public: getApiProjectsProjectIdIssuesIssueIdFeedPublic,
  team: getApiProjectsProjectIdIssuesIssueIdFeedTeam,
};

export const getInfinityIssueFeedQueryOptions = (
  ...args: [
    Channel,
    ...Parameters<(typeof getProjectFeedInfiniteQueryOptions)[keyof typeof getProjectFeedInfiniteQueryOptions]>,
  ]
) => {
  const [channel, projectId, issueId, params, options] = args;
  const queryFn = getFeedInfiniteFn[channel];
  const qetQueryOptions = getProjectFeedInfiniteQueryOptions[channel];

  return qetQueryOptions(projectId, issueId, params, {
    query: {
      ...options?.query,
      queryFn: ({ pageParam, signal }) =>
        queryFn(
          projectId,
          issueId,
          {
            ...params,
            // @ts-expect-error
            ...pageParam,
            page_size: Number(environment.FEED_EVENTS_BATCH_NUMBER!),
          },
          options?.request,
          signal
        ),
      getNextPageParam: (lastPage) => {
        const { hasNextPage, lastEntryCursor } = lastPage.meta;

        return hasNextPage ? { after: lastEntryCursor } : undefined;
      },
      getPreviousPageParam: (firstPage) => {
        const { hasPreviousPage, firstEntryCursor } = firstPage.meta;

        return hasPreviousPage ? { after: firstEntryCursor } : undefined;
      },
      initialPageParam: { after: undefined as undefined | string },
    },
  });
};
