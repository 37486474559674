import classNames from 'clsx';
import * as React from 'react';
import { twMerge } from 'tailwind-merge';

type Animation = 'pulse';

export interface SkeletonBaseProps {
  animation?: Animation;
  className?: string;
}

export const SkeletonBase: React.FC<SkeletonBaseProps> = ({ animation, className }) => (
  <div
    className={twMerge(
      classNames('bg-gray-200', {
        'animate-pulse': animation === 'pulse',
      }),
      className
    )}
  />
);
