export const MEGABYTES_TO_BYTES = 1024 * 1024;
export const MAX_IMAGE_SIZE_IN_BYTES = 10 * MEGABYTES_TO_BYTES; // 10 Megabytes
export const MAX_AVATAR_SIZE_IN_BYTES = 3 * MEGABYTES_TO_BYTES; // 3 Megabytes
export const MIN_FILE_SIZE_IN_BYTES = 0;
export const supportedFileExt = ['.jpg', '.png', '.jpeg'];

/**
 * @deprecated
 * This method is deprecated and will be removed in the future.
 * Please use the `useFileUploader` hook instead.
 */
export const ImageFileInputValidation = ({ maxSize = MAX_IMAGE_SIZE_IN_BYTES } = {}) => {
  const validateInputImages = (files: FileList | File[] | null): [boolean, File[], File[]] => {
    const isImageFileInvalid = (file: File) =>
      !supportedFileExt.some((extension) => file.name.toLowerCase().endsWith(extension)) ||
      file.size === 0 ||
      file.size >= maxSize;

    if (!files) return [false, [], []];

    const invalidFiles = Array.from(files).filter(isImageFileInvalid);

    return [!invalidFiles.length, Array.from(files), invalidFiles];
  };

  return { validateInputImages };
};
