import { type Configuration, PublicClientApplication } from '@azure/msal-browser';
import { environment } from './config/environment';

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
  auth: {
    /* The Azure Portal/App Registration application id */
    clientId: environment.MICROSOFT_CLIENT_ID,
    /*
     * "/<tenantId>" | "/common" | "/organizations"
     * See https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/authority.md
     */
    authority: 'https://login.microsoftonline.com/organizations',
    /* Must register on Azure Portal/App Registration. Defaults to window.location.origin */
    redirectUri: '/auth',
  },
  cache: {
    /*
     *  "sessionStorage" | "localStorage"
     *  Can be "localStorage" since we only need this to fetch the token to send to the API
     */
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const loginRequest = {
  scopes: ['User.Read'],
  prompt: 'select_account',
};

export const getNewMsalInstance = () => {
  return new PublicClientApplication(msalConfig);
};
