import Sidebar from '@shape-construction/arch-ui/src/Sidebar';
import { breakpoints } from '@shape-construction/arch-ui/src/utils/breakpoints';
import { useMediaQuery } from '@shape-construction/hooks';
import { NetworkStatusNotification } from 'app/components/NetworkStatusNotification/NetworkStatusNotification';
import AppSidebar from 'app/components/UI/AppSidebar';
import { BottomNavigation } from 'app/components/UI/BottomNavigation/BottomNavigation';
import { InstallBar } from 'app/components/UI/InstallBar/InstallBar';
import { Navbar } from 'app/components/UI/Navbar/Navbar';
import { ServiceWorkerLoader } from 'app/components/UI/ServiceWorkerLoader/ServiceWorkerLoader';
import { useLayoutContext } from 'app/contexts/layout/layoutContext';
import { EnableNotificationsBanner } from 'app/pages/notifications/EnableNotificationsBanner';
import { useNotifications } from 'app/pages/notifications/hooks/useNotifications';
import { useCurrentUser } from 'app/queries/users/users';
import classNames from 'clsx';
import React from 'react';
import { useLocation } from 'react-router-dom';

export interface LayoutProps {
  children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const {
    drawerExpanded,
    setDrawerExpanded,
    layoutConfig: { hide, showSideDrawerMenu, showSideDrawer },
  } = useLayoutContext();

  const { userConsent, showEnableNotificationsBanner } = useNotifications();
  const user = useCurrentUser();
  const searchParams = new URLSearchParams(useLocation().search);
  const issueId = searchParams.get('issueId');
  const isMediumScreen = useMediaQuery(breakpoints.up('md'));
  const isUserAuthenticated = !!user;
  const canRenderInstallBar = !isUserAuthenticated || !showEnableNotificationsBanner || userConsent !== 'default';

  const Header = (
    <>
      {canRenderInstallBar && <InstallBar isUserAuthenticated={isUserAuthenticated} />}
      {!canRenderInstallBar && <EnableNotificationsBanner />}
      {isUserAuthenticated && !hide && <Navbar />}
      <div
        className={classNames({
          'absolute top-0 w-full z-navigation': !isUserAuthenticated,
        })}
      >
        <NetworkStatusNotification />
      </div>
    </>
  );

  const Body = (
    <>
      <ServiceWorkerLoader showUpdate={isUserAuthenticated} />
      <main
        className={classNames({
          'flex h-full flex-1 flex-col relative overflow-hidden': isUserAuthenticated,
          'md:flex-row': showSideDrawerMenu || showSideDrawer,
        })}
      >
        {showSideDrawer && <AppSidebar.Root />}
        {children}
      </main>
    </>
  );
  const Footer = <BottomNavigation className="block md:hidden" />;

  return (
    <Sidebar.Provider defaultOpen={drawerExpanded} onOpenChange={setDrawerExpanded}>
      <div className="relative flex flex-col grow min-w-0 min-h-screen h-full sm:h-screen">
        {/* remove navbar flickering on mobile devices when clicking issue details from timeline */}
        {issueId && !isMediumScreen ? null : Header}
        {Body}
        {Footer}
      </div>
    </Sidebar.Provider>
  );
};
