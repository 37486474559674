import { useMediaQuery } from '@shape-construction/hooks';
import classNames from 'clsx';
import React from 'react';
import type { ModalBaseProps } from '../../ModalBase';
import { breakpoints } from '../../utils/breakpoints';

export type DrawerPersistentProps = Pick<ModalBaseProps, 'open'> & {
  children: React.ReactNode;
};

export const DrawerPersistent: React.FC<DrawerPersistentProps> = ({ open, children }) => {
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));
  const className = classNames('static p-0 h-full flex flex-col bg-white shadow', {
    'w-[448px]': isLargeScreen,
    'w-full fixed inset-0 z-modal': !isLargeScreen,
  });

  if (!open) {
    return null;
  }

  return (
    <dialog open className={className}>
      {children}
    </dialog>
  );
};
