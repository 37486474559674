import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import React from 'react';

export type PopoverRootProps = TooltipPrimitive.TooltipProps;

export const PopoverRoot: React.FC<PopoverRootProps> = ({ children, ...props }) => (
  <TooltipPrimitive.Provider delayDuration={0}>
    <TooltipPrimitive.Root {...props}>{children}</TooltipPrimitive.Root>
  </TooltipPrimitive.Provider>
);

PopoverRoot.displayName = 'Popover.Root';
