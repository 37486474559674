import { useMessageGetter } from '@messageformat/react';
import type { QuerySortOrderParameter } from '@shape-construction/api/model';
import { InputAdornment, Select, type SelectRootProps } from '@shape-construction/arch-ui';
import React, { forwardRef, useMemo } from 'react';

type Item = {
  name: string;
  value: QuerySortOrderParameter;
};

export type SortOrderSelectProps = SelectRootProps<Item['value'], false>;

export const SortOrderSelect = forwardRef<React.ElementRef<typeof Select.Root>, SortOrderSelectProps>((props, ref) => {
  const messageGetter = useMessageGetter('projectGallery.sort');
  const options: Item[] = useMemo(
    () => [
      { name: messageGetter('newest'), value: 'desc' },
      { name: messageGetter('oldest'), value: 'asc' },
    ],
    [messageGetter]
  );
  const selected = useMemo(
    () => options.find((option) => option.value === props.value) || options[0],
    [props.value, options]
  );

  return (
    <Select.Root ref={ref} {...props} value={selected.value}>
      <Select.Trigger size="sm" startAdornment={<InputAdornment>{messageGetter('label')}:</InputAdornment>}>
        <Select.Value value={selected.name} />
      </Select.Trigger>
      <Select.ResponsivePanel>
        <Select.Options>
          {options.map((option) => (
            <Select.Option key={option.name} value={option.value}>
              {option.name}
            </Select.Option>
          ))}
        </Select.Options>
      </Select.ResponsivePanel>
    </Select.Root>
  );
});
SortOrderSelect.displayName = 'SortOrderSelect';
