import { environment } from 'app/config/environment';
import Dexie, { type Table } from 'dexie';
import type { IssueImageUpload } from './issueImages';
import type { Issue } from './issues';

class LocalDataDatabase extends Dexie {
  public issueImageUploads!: Table<IssueImageUpload, string>;
  public issues!: Table<Issue, string>;

  public constructor() {
    super(environment.LOCAL_PERSISTER_KEY);
    this.version(1).stores({
      issueImageUploads: 'id,issueId',
    });
    this.version(2).stores({
      issues: 'id,projectId',
    });
  }
}

const db = new LocalDataDatabase();

export const clearLocalDatabase = async () => {
  await Promise.all(db.tables.map((table) => table.clear()));
};

export default db;
