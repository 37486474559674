import * as React from 'react';
import type { SVGProps } from 'react';
const GenericIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={44} viewBox="0 0 40 44" fill="none" aria-hidden="true" {...props}>
    <g id="file-icon/generic">
      <g id="Vector" filter="url(#filter0_dd_3816_38122)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6776 41C27.014 41 13.7442 41 7.32664 41C5.85388 41 4.66663 39.82 4.66663 38.3473C4.66663 30.3468 4.66663 10.7752 4.66663 3.6498C4.66663 2.17704 5.86053 1 7.33329 1H26.2222Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L35.3333 10.1111V38.3333C35.3333 39.8061 34.1504 41 32.6776 41C27.014 41 13.7442 41 7.32664 41C5.85388 41 4.66663 39.82 4.66663 38.3473C4.66663 30.3468 4.66663 10.7752 4.66663 3.6498C4.66663 2.17704 5.86053 1 7.33329 1H26.2222Z"
          fill="url(#paint0_radial_3816_38122)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2222 1L26.2228 7.44444C26.2228 8.9172 27.4167 10.1111 28.8894 10.1111L35.3333 10.1111L26.2222 1Z"
          fill="#F3F4F6"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_dd_3816_38122"
        x={2.66663}
        y={0}
        width={34.6666}
        height={44}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3816_38122" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_3816_38122" result="effect2_dropShadow_3816_38122" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3816_38122" result="shape" />
      </filter>
      <radialGradient
        id="paint0_radial_3816_38122"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20 0.999999) rotate(90) scale(40 40.1825)"
      >
        <stop offset={0.297418} stopOpacity={0} />
        <stop offset={1} stopOpacity={0.03} />
      </radialGradient>
    </defs>
  </svg>
);
export default GenericIcon;
