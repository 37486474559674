import classNames from 'clsx';
import { twMerge } from 'tailwind-merge';
import type { ButtonColor, ButtonShape, ButtonSize, ButtonVariant } from './button-config';

const baseSizeClassesMap: Record<NonNullable<ButtonSize>, string> = {
  xxs: 'text-xs leading-4 font-medium',
  xs: 'text-xs leading-4 font-medium',
  sm: 'text-sm leading-4 font-medium',
  md: 'text-sm leading-5 font-medium',
  lg: 'text-base leading-5 font-medium',
  xl: 'text-base leading-6 font-medium',
};

const shapeSizeClassesMap: Record<ButtonShape, Record<NonNullable<ButtonSize>, string>> = {
  standard: {
    xxs: 'rounded px-2 py-1.5',
    xs: 'rounded px-2.5 py-2',
    sm: 'rounded-md px-3 py-2.5',
    md: 'rounded-md px-4 py-2.5',
    lg: 'rounded-md px-4 py-2.5',
    xl: 'rounded-md px-6 py-3',
  },
  rounded: {
    xxs: 'rounded-full p-1',
    xs: 'rounded-full p-2',
    sm: 'rounded-full p-2',
    md: 'rounded-full p-2.5',
    lg: 'rounded-full p-3',
    xl: 'rounded-full p-3',
  },
  square: {
    xxs: 'rounded w-7 h-7 justify-center',
    xs: 'rounded w-8 h-8 justify-center',
    sm: 'rounded-md w-9 h-9 justify-center',
    md: 'rounded-md w-10 h-10 justify-center',
    lg: 'rounded-md w-11 h-11 justify-center',
    xl: 'rounded-md w-12 h-12 justify-center',
  },
};

export const innerSizeClassesMap: Record<NonNullable<ButtonSize>, string> = {
  xxs: 'gap-1 h-4',
  xs: 'gap-2 h-4',
  sm: 'gap-2 h-4',
  md: 'gap-2 h-5',
  lg: 'gap-3 h-5',
  xl: 'gap-3 h-6',
};

const colorVariantClassesMap: Record<NonNullable<ButtonColor>, Record<NonNullable<ButtonVariant>, string>> = {
  primary: {
    contained: 'text-white bg-indigo-500',
    overlay: 'text-white bg-indigo-500/60',
    outlined: 'bg-white outline outline-1 -outline-offset-1 text-indigo-600 outline-indigo-300',
    text: 'text-indigo-600',
  },
  secondary: {
    contained: 'text-gray-700 bg-gray-200',
    overlay: 'text-gray-700 bg-gray-200/60',
    outlined: 'bg-white outline outline-1 -outline-offset-1 text-gray-700 outline-gray-300',
    text: 'text-gray-700',
  },
  success: {
    contained: 'text-white bg-green-500',
    overlay: 'text-white bg-green-500/60',
    outlined: 'bg-white outline outline-1 -outline-offset-1 text-green-700 outline-green-300',
    text: 'text-green-700',
  },
  danger: {
    contained: 'text-white bg-red-600',
    overlay: 'text-white bg-red-600/60',
    outlined: 'bg-white outline outline-1 -outline-offset-1 text-red-700 outline-red-300',
    text: 'text-red-700',
  },
  warning: {
    contained: 'text-white bg-yellow-500',
    overlay: 'text-white bg-yellow-500/60',
    outlined: 'bg-white outline outline-1 -outline-offset-1 text-yellow-700 outline-yellow-400',
    text: 'text-yellow-700',
  },
  white: {
    contained: 'text-gray-600 bg-white',
    overlay: 'text-gray-600 bg-white/60',
    outlined: 'outline outline-1 -outline-offset-1 text-white outline-white',
    text: 'text-white',
  },
  dark: {
    contained: 'text-white bg-gray-900',
    overlay: 'text-white bg-gray-900/60',
    outlined: 'outline outline-1 -outline-offset-1 text-gray-900 outline-gray-900',
    text: 'text-gray-900',
  },
  discovery: {
    contained: 'text-white bg-purple-500',
    overlay: 'text-white bg-purple-500/60',
    outlined: 'bg-white outline outline-1 -outline-offset-1 text-purple-700 outline-purple-400',
    text: 'text-purple-700',
  },
};

const hoverColorVariantClassesMap: Record<NonNullable<ButtonColor>, Record<NonNullable<ButtonVariant>, string>> = {
  primary: {
    contained: 'hover:bg-indigo-600',
    overlay: 'hover:bg-indigo-500',
    outlined: 'hover:bg-indigo-50',
    text: 'hover:bg-indigo-50',
  },
  secondary: {
    contained: 'hover:bg-gray-300',
    overlay: 'hover:bg-gray-200',
    outlined: 'hover:bg-gray-50',
    text: 'hover:bg-gray-50',
  },
  success: {
    contained: 'hover:bg-green-600 ',
    overlay: 'hover:bg-green-500',
    outlined: 'hover:bg-green-50',
    text: 'hover:bg-green-50',
  },
  danger: {
    contained: 'hover:bg-red-700',
    overlay: 'hover:bg-red-600',
    outlined: 'hover:bg-red-50',
    text: 'hover:bg-red-50',
  },
  warning: {
    contained: 'hover:bg-yellow-600',
    overlay: 'hover:bg-yellow-500',
    outlined: 'hover:bg-yellow-50',
    text: 'hover:bg-yellow-50',
  },
  white: {
    contained: 'hover:bg-gray-200',
    overlay: 'hover:bg-white',
    outlined: 'hover:bg-white/20',
    text: 'hover:bg-white/20',
  },
  dark: {
    contained: 'hover:bg-gray-700',
    overlay: 'hover:bg-gray-900',
    outlined: 'hover:bg-gray-900/20',
    text: 'hover:bg-gray-900/20',
  },
  discovery: {
    contained: 'hover:bg-purple-600',
    overlay: 'hover:bg-purple-500',
    outlined: 'hover:bg-purple-50',
    text: 'hover:bg-purple-50',
  },
};

const focusColorVariantClassesMap: Record<NonNullable<ButtonColor>, Record<NonNullable<ButtonVariant>, string>> = {
  primary: {
    contained: 'focus:ring-indigo-500 focus-visible:outline-0',
    overlay: 'focus:ring-indigo-500 focus-visible:outline-0',
    outlined: 'focus:ring-indigo-500 focus-visible:outline-0',
    text: 'focus:ring-indigo-500 focus-visible:outline-0',
  },
  secondary: {
    contained: 'focus:ring-indigo-500 focus-visible:outline-0',
    overlay: 'focus:ring-indigo-500 focus-visible:outline-0',
    outlined: 'focus:ring-indigo-500 focus-visible:outline-0',
    text: 'focus:ring-indigo-500 focus-visible:outline-0',
  },
  success: {
    contained: 'focus:ring-green-600 focus-visible:outline-0',
    overlay: 'focus:ring-green-500 focus-visible:outline-0',
    outlined: 'focus:ring-green-600 focus-visible:outline-0',
    text: 'focus:ring-green-600 focus-visible:outline-0',
  },
  danger: {
    contained: 'focus:ring-red-700 focus-visible:outline-0',
    overlay: 'focus:ring-red-600 focus-visible:outline-0',
    outlined: 'focus:ring-red-700 focus-visible:outline-0',
    text: 'focus:ring-red-700 focus-visible:outline-0',
  },
  warning: {
    contained: 'focus:ring-yellow-500 focus-visible:outline-0',
    overlay: 'focus:ring-yellow-500 focus-visible:outline-0',
    outlined: 'focus:ring-yellow-500 focus-visible:outline-0',
    text: 'focus:ring-yellow-500 focus-visible:outline-0',
  },
  white: {
    contained: 'focus:ring-indigo-500 focus-visible:outline-0',
    overlay: 'focus:ring-indigo-500 focus-visible:outline-0',
    outlined: 'focus:ring-indigo-500 focus-visible:outline-0',
    text: 'focus:ring-indigo-500 focus-visible:outline-0',
  },
  dark: {
    contained: 'focus:ring-indigo-500 focus-visible:outline-0',
    overlay: 'focus:ring-indigo-500 focus-visible:outline-0',
    outlined: 'focus:ring-indigo-500 focus-visible:outline-0',
    text: 'focus:ring-indigo-500 focus-visible:outline-0',
  },
  discovery: {
    contained: 'focus:ring-indigo-500 focus-visible:outline-0',
    overlay: 'focus:ring-indigo-500 focus-visible:outline-0',
    outlined: 'focus:ring-indigo-500 focus-visible:outline-0',
    text: 'focus:ring-indigo-500 focus-visible:outline-0',
  },
};

type GetButtonClassesProps = {
  color: ButtonColor;
  disabled: boolean;
  fullWidth: boolean;
  shape: ButtonShape;
  size: ButtonSize;
  variant: ButtonVariant;
};

export const getButtonClasses = ({ color, disabled, fullWidth, shape, size, variant }: GetButtonClassesProps) => {
  const baseClasses = 'inline-flex flex-row items-center';
  const disabledClasses = 'cursor-not-allowed opacity-50';
  const fullWidthClasses = 'w-full justify-center';
  const sizeClasses = classNames(baseSizeClassesMap[size], shapeSizeClassesMap[shape][size]);
  const colorClasses = colorVariantClassesMap[color][variant];
  const hoverClasses = hoverColorVariantClassesMap[color][variant];

  const focusClasses = classNames(
    'focus:ring-2 focus:ring-offset-2 focus:ring-offset-white',
    focusColorVariantClassesMap[color][variant]
  );

  const buttonClasses = twMerge(
    classNames(baseClasses, colorClasses, focusClasses, hoverClasses, sizeClasses, {
      [disabledClasses]: disabled,
      [fullWidthClasses]: fullWidth,
    })
  );

  return {
    buttonClasses,
  };
};
